import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import AuthPage from './pages/auth';
import CoinPage from './pages/coin';
import BinanceSimulator from './pages/binanceSimulator';
import UpbitSimulator from './pages/upbitSimulator';

import { checkSignInAction } from './modules/auth';

const App = () => {
	const { auth } = useSelector(({ authReducer }) => ({
		auth: authReducer.auth.data
	}));
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(checkSignInAction());
	}, [dispatch]);

	if (auth && auth.hasOwnProperty('username')) {
		return (
			<Router>
				<Route exact path="/" render={() => <CoinPage />} />
				<Route exact path="/simulator" render={() => <BinanceSimulator />} />
				<Route exact path="/upbitSimulator" render={() => <UpbitSimulator />} />
			</Router>
		);
	} else {
		return (
			<Router>
				<Route exact path="/" render={() => <AuthPage />} />
				<Route exact path="/simulator" render={() => <BinanceSimulator />} />
				<Route exact path="/upbitSimulator" render={() => <UpbitSimulator />} />
			</Router>
		);
	}
};

export default App;
