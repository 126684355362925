import React, { useMemo, useState, Fragment } from 'react';
import { Form, Input, Button, Space, Card, Switch, Select, InputNumber, Descriptions, message } from 'antd';
import { format } from 'd3-format';

import * as coinAPI from '../../api/coin';
import { getBinanceStep } from '../../hooks/useLibrary';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { span: 24 },
	style: { textAlign: 'center' }
};

export default ({ symbols, futuresSymbols, coinmSymbols, binanceTicker, handleSetBinanceSetting, binanceSetting, handleGetBinanceWallet, binanceWallet, handleGetBinanceFuturesWallet, handleSendFuturesPositionsToTelegramBot, handleSendFuturesTriggersToTelegramBot, binanceFuturesWallet, handleGetBinanceCoinmWallet, binanceCoinmWallet, handleBinanceLeverage, handleBinanceMarginType, handleBinancePositionSide, baseUrl, auth }) => {
	const [profits, setProfits] = useState([]);
	const [futuresProfits, setFuturesProfits] = useState([]);
	const [coinmProfits, setCoinmProfits] = useState([]);

	const handleGetBinanceProfit = () => {
		if (binanceSetting) {
			coinAPI.getBinanceProfit({ auth, baseUrl, profitPage: 1, ...binanceSetting }).then((response) => {
				if (response) {
					const sumProfits = [];
					const sumProfit = {
						date: '',
						profit: 0
					};
					for (const profit of response) {
						if (sumProfit.date === profit.date.substring(0, 2)) {
							sumProfit.profit += profit.profit;
						} else {
							if (sumProfit.profit) {
								sumProfits.push({
									date: `${Number(sumProfit.date)}월`,
									profit: sumProfit.profit
								});
							}
							sumProfit.date = profit.date.substring(0, 2);
							sumProfit.profit = profit.profit;
						}
					}
					if (sumProfit.profit) {
						sumProfits.push({
							date: `${Number(sumProfit.date)}월`,
							profit: sumProfit.profit
						});
					}

					setProfits([...sumProfits, ...response]);
				}
			});
		} else message.warning('바낸 API 키를 입력해주세요.');
	};

	const handleGetBinanceFuturesProfit = () => {
		if (binanceSetting) {
			coinAPI.getBinanceFuturesProfit({ auth, baseUrl, profitPage: 1, ...binanceSetting }).then((response) => {
				if (response) {
					const sumProfits = [];
					const sumProfit = {
						date: '',
						profit: 0
					};
					for (const profit of response) {
						if (sumProfit.date === profit.date.substring(0, 2)) {
							sumProfit.profit += profit.profit;
						} else {
							if (sumProfit.profit) {
								sumProfits.push({
									date: `${Number(sumProfit.date)}월`,
									profit: sumProfit.profit
								});
							}
							sumProfit.date = profit.date.substring(0, 2);
							sumProfit.profit = profit.profit;
						}
					}
					if (sumProfit.profit) {
						sumProfits.push({
							date: `${Number(sumProfit.date)}월`,
							profit: sumProfit.profit
						});
					}

					setFuturesProfits([...sumProfits, ...response]);
				}
			});
		} else message.warning('바낸 API 키를 입력해주세요.');
	};

	const handleGetBinanceCoinmProfit = () => {
		if (binanceSetting) {
			coinAPI.getBinanceCoinmProfit({ auth, baseUrl, profitPage: 1, ...binanceSetting }).then((response) => {
				if (response) setCoinmProfits(response);
			});
		} else message.warning('바낸 API 키를 입력해주세요.');
	};

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Card title="설정" size="small" style={{ width: '100%' }}>
					<Form
						{...layout}
						name="binance_setting"
						initialValues={{
							accessKey: (binanceSetting && binanceSetting.accessKey) || '',
							secretKey: (binanceSetting && binanceSetting.secretKey) || '',
							telegramBotToken: (binanceSetting && binanceSetting.telegramBotToken) || '',
							telegramChatId: (binanceSetting && binanceSetting.telegramChatId) || '',
							stopLiquidationLevel: (binanceSetting && binanceSetting.stopLiquidationLevel) || 0,
							defaultTargetRoe: (binanceSetting && binanceSetting.defaultTargetRoe) || 0.01,
							defaultBudget: (binanceSetting && binanceSetting.defaultBudget) || 1000,
							maxTotalBudget: (binanceSetting && binanceSetting.maxTotalBudget) || 3000,
							copyLeo: (binanceSetting && binanceSetting.copyLeo) || false
						}}
						onFinish={handleSetBinanceSetting}
					>
						<Form.Item name="accessKey" label="Access key" rules={[{ required: true, message: 'Required!' }]}>
							<Input placeholder="API key" type="text" />
						</Form.Item>
						<Form.Item name="secretKey" label="Secret key" rules={[{ required: true, message: 'Required!' }]}>
							<Input placeholder="Secret key" type="text" />
						</Form.Item>
						<Form.Item name="telegramBotToken" label="텔레그램 token">
							<Input placeholder="Token" type="text" />
						</Form.Item>
						<Form.Item name="telegramChatId" label="텔레그램 Chat ID">
							<Input placeholder="Chat ID" type="text" />
						</Form.Item>
						<Form.Item name="stopLiquidationLevel" label="청산방지기능 기준">
							<InputNumber style={{ width: 150 }} min={0} max={1000000} step={100} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
						</Form.Item>
						{/* <Form.Item
							label="기본 목표 수익률"
							name="defaultTargetRoe"
							rules={[
								{
									validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
								},
								{
									required: true,
									message: '기본 목표 수익률을 입력하세요.'
								}
							]}
						>
							<InputNumber style={{ width: 100 }} min={0} max={100} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
						</Form.Item>
						<Form.Item
							label="기본 예산"
							name="defaultBudget"
							rules={[
								{
									validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Number(value) < 500 ? Promise.reject(new Error('예산은 $500 이상 입력해주세요.')) : Promise.resolve())
								},
								{
									required: true,
									message: '기본 예산을 입력해주세요.'
								}
							]}
						>
							<Input style={{ width: 150 }} suffix="USDT" />
						</Form.Item>
						<Form.Item name="copyLeo" label="레오님의 가이드" valuePropName="checked">
							<Switch checkedChildren="ON" unCheckedChildren="OFF" />
						</Form.Item>
						<Form.Item
							label="최대 총예산"
							name="maxTotalBudget"
							rules={[
								{
									validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Number(value) < 500 ? Promise.reject(new Error('예산은 $500 이상 입력해주세요.')) : Promise.resolve())
								},
								{
									required: true,
									message: '최대 총예산을 입력해주세요.'
								}
							]}
						>
							<Input style={{ width: 150 }} suffix="USDT" />
						</Form.Item> */}
						<Form.Item {...tailLayout}>
							<Button htmlType="button" style={{ marginRight: '8px' }} onClick={() => handleGetBinanceWallet()}>
								지갑 조회
							</Button>
							<Button type="primary" htmlType="submit">
								저장
							</Button>
						</Form.Item>
						<Form.Item {...tailLayout}>
							<Button htmlType="button" style={{ background: '#ffc53d', borderColor: '#ffc53d' }} onClick={() => handleBinancePositionSide()}>
								Hedge 모드 사용
							</Button>
						</Form.Item>
					</Form>
				</Card>
				<Descriptions bordered size="small" column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }}>
					{binanceWallet &&
						binanceWallet.map((item, key) => {
							if (item.asset === 'USDT') {
								return (
									<Fragment key={item}>
										<Descriptions.Item label={item.asset} contentStyle={{ textAlign: 'right' }}>
											{format('$,.2f')(Number(item.free) + Number(item.locked))}
										</Descriptions.Item>
										<Descriptions.Item label="가용잔고" contentStyle={{ textAlign: 'right' }}>
											{format('$,.2f')(Number(item.free))}
										</Descriptions.Item>
										<Descriptions.Item label="미체결" contentStyle={{ textAlign: 'right' }}>
											{format('$,.2f')(Number(item.locked))}
										</Descriptions.Item>
									</Fragment>
								);
							} else {
								return (
									<Fragment key={item}>
										<Descriptions.Item label={item.asset} contentStyle={{ textAlign: 'right' }}>
											{format(',.2f')(Number(item.free) + Number(item.locked))}
										</Descriptions.Item>
										<Descriptions.Item label="가용잔고" contentStyle={{ textAlign: 'right' }}>
											{format(',.2f')(Number(item.free))}
										</Descriptions.Item>
										<Descriptions.Item label="미체결" contentStyle={{ textAlign: 'right' }}>
											{format(',.2f')(Number(item.locked))}
										</Descriptions.Item>
									</Fragment>
								);
							}
						})}
				</Descriptions>
				<Card title="현물 수익" size="small" style={{ width: '100%' }}>
					<Form {...layout} name="binance_profit">
						<Form.Item {...tailLayout}>
							<Button type="primary" onClick={() => handleGetBinanceProfit()}>
								수익 조회
							</Button>
						</Form.Item>
					</Form>
					<Descriptions bordered size="small" column={{ xs: 1, xl: 3 }}>
						{profits &&
							profits.map((item, i) => (
								<Descriptions.Item key={item.date} label={item.date} contentStyle={{ textAlign: 'right' }}>
									{`${format('$,.2f')(item.profit)}`}
								</Descriptions.Item>
							))}
					</Descriptions>
				</Card>
				<Card title="선물" size="small" style={{ width: '100%' }}>
					<Form {...layout} name="binance_futures_setting">
						<Form.Item {...tailLayout}>
							<Button htmlType="button" onClick={() => handleGetBinanceFuturesWallet()}>
								지갑 조회
							</Button>
						</Form.Item>
						<Form.Item {...tailLayout}>
							<Button type="primary" style={{ marginRight: '8px' }} htmlType="button" onClick={() => handleSendFuturesPositionsToTelegramBot()}>
								텔레그램: 포지션전송
							</Button>
							<Button htmlType="button" style={{ background: '#ffc53d', borderColor: '#ffc53d' }} onClick={() => handleSendFuturesTriggersToTelegramBot()}>
								텔레그램: 조건식전송
							</Button>
						</Form.Item>
					</Form>
				</Card>
				<Descriptions bordered size="small" column={{ xs: 1, xl: 4 }}>
					{binanceFuturesWallet && (
						<>
							<Descriptions.Item label="USDT" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }} span={2}>
								{format('$,.2f')(Number(binanceFuturesWallet.asset.walletBalance))}
							</Descriptions.Item>
							<Descriptions.Item label="미실현손익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: Number(binanceFuturesWallet.asset.unrealizedProfit) >= 0 ? '#03a66d' : '#cf304a' }} span={2}>
								{`${format('$,.2f')(Number(binanceFuturesWallet.asset.unrealizedProfit))} (${binanceFuturesWallet.positions.length} 포지션)`}
							</Descriptions.Item>
							{binanceFuturesWallet.positions.map((item, key) => {
								const { priceStep, pricePrecision, quantityStep, quantityPrecision } = getBinanceStep(futuresSymbols.find((x) => x.symbol === item.symbol));
								return (
									<Fragment key={item.symbol + item.positionSide}>
										<Descriptions.Item label={item.symbol.replace('USDT', '')} labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: item.positionSide === 'LONG' ? '#03a66d' : '#cf304a' }}>
											{format(`,.${quantityPrecision + 1}f`)(Number(item.positionAmt)) + (binanceTicker[item.symbol] ? `(${format('$,.2f')(binanceTicker[item.symbol] * Number(item.positionAmt))})` : '')}
										</Descriptions.Item>
										<Descriptions.Item label="진입가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
											{format(`$,.${pricePrecision + 1}f`)(Number(item.entryPrice)) + (binanceTicker[item.symbol] ? `(${format(',.2%')(binanceTicker[item.symbol] / Number(item.entryPrice) - 1)})` : '')}
										</Descriptions.Item>
										<Descriptions.Item label="미실현손익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: Number(item.unrealizedProfit) >= 0 ? '#03a66d' : '#cf304a' }} span={2}>
											{`${format('$,.2f')(Number(item.unrealizedProfit))} (${format(',.2%')(Number(item.unrealizedProfit) / Number(item.positionInitialMargin))})`}
										</Descriptions.Item>
										{/* <Descriptions.Item label="Leverage">{`${item.isolated ? 'ISOLATED' : 'CROSS'} ${item.leverage}x`}</Descriptions.Item> */}
									</Fragment>
								);
							})}
						</>
					)}
				</Descriptions>
				<Card title="선물 수익" size="small" style={{ width: '100%' }}>
					<Form {...layout} name="binance_futures_profit">
						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="button" onClick={() => handleGetBinanceFuturesProfit()}>
								수익 조회
							</Button>
						</Form.Item>
					</Form>
					<Descriptions bordered size="small" column={{ xs: 1, xl: 3 }}>
						{futuresProfits &&
							futuresProfits.map((item, i) => (
								<Descriptions.Item key={item.date} label={item.date} contentStyle={{ textAlign: 'right' }}>
									{`${format('$,.2f')(item.profit)}`}
								</Descriptions.Item>
							))}
					</Descriptions>
				</Card>
				<Card title="코인엠" size="small" style={{ width: '100%' }}>
					<Form {...layout} name="binance_coinm_setting">
						<Form.Item {...tailLayout}>
							<Button htmlType="button" style={{ marginRight: '8px' }} onClick={() => handleGetBinanceCoinmWallet()}>
								지갑 조회
							</Button>
						</Form.Item>
					</Form>
				</Card>
				<Descriptions bordered size="small" column={{ xs: 1, xl: 4 }}>
					{binanceCoinmWallet &&
						binanceCoinmWallet.assets.map((item, key) => (
							<Fragment key={item.asset}>
								<Descriptions.Item label={item.asset} labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }} span={2}>
									{item.walletBalance}
								</Descriptions.Item>
								<Descriptions.Item label="미실현손익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: Number(item.unrealizedProfit) >= 0 ? '#03a66d' : '#cf304a' }} span={2}>
									{item.unrealizedProfit}
								</Descriptions.Item>
								{binanceCoinmWallet.positions
									.filter((x) => x.symbol.replace('USD_PERP', '') === item.asset)
									.map((item2, key2) => {
										const { priceStep, pricePrecision, quantityStep, quantityPrecision } = getBinanceStep(coinmSymbols.find((x) => x.symbol === item2.symbol));
										return (
											<Fragment key={item2.symbol + item2.positionSide}>
												<Descriptions.Item label={item2.symbol.replace('USD_PERP', '')} labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: item2.positionSide === 'LONG' ? '#03a66d' : '#cf304a' }}>
													{`${item2.positionAmt} Cont`}
												</Descriptions.Item>
												<Descriptions.Item label="진입가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
													{format(`$,.${pricePrecision + 1}f`)(Number(item2.entryPrice))}
												</Descriptions.Item>
												<Descriptions.Item label="미실현손익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: Number(item2.unrealizedProfit) >= 0 ? '#03a66d' : '#cf304a' }} span={2}>
													{`${item2.unrealizedProfit} (${format(',.2%')(Number(item2.unrealizedProfit) / Number(item2.positionInitialMargin))})`}
												</Descriptions.Item>
											</Fragment>
										);
									})}
							</Fragment>
						))}
				</Descriptions>
				{/* <Card title="Binance coin-m profit" size="small" style={{ width: '100%' }}>
					<Form {...layout} name="binance_coinm_profit">
						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="button" onClick={() => handleGetBinanceCoinmProfit()}>
								Check My Coin-m Profit
							</Button>
						</Form.Item>
					</Form>
					<Descriptions bordered size="small" column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 3, xs: 3 }}>
						{coinmProfits &&
							coinmProfits.map((item, i) => (
								<Descriptions.Item key={item.date} label={item.date} contentStyle={{ textAlign: 'right' }}>
									{item.profits && item.profits.map((item2, key2) => (
										''
									))}
									{`${format('$,.2f')(item.profit)}`}
								</Descriptions.Item>
							))}
					</Descriptions>
				</Card> */}
				{/* <Card title="Binance futures setting" size="small" style={{ width: '100%' }}>
					<Form
						{...layout}
						name="leverage_form"
						initialValues={{
							symbol: 'BTCUSDT',
							leverage: 1
						}}
						onFinish={handleBinanceLeverage}
					>
						<Form.Item name="symbol" label="코인">
							<Select showSearch style={{ width: 200 }} placeholder="Select a person" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{useMemo(
									() =>
										futuresSymbols &&
										futuresSymbols.map((item, i) => (
											<Select.Option key={item.symbol} value={item.symbol}>
												{item.symbol.replace('USDT', '')}
											</Select.Option>
										)),
									[futuresSymbols]
								)}
							</Select>
						</Form.Item>
						<Form.Item name="leverage" label="Leverage">
							<InputNumber min={1} max={125} step={1} />
						</Form.Item>
						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
					<br />
					<Form
						{...layout}
						name="margin_type_form"
						initialValues={{
							symbol: 'BTCUSDT'
						}}
						onFinish={handleBinanceMarginType}
					>
						<Form.Item name="symbol" label="코인">
							<Select showSearch style={{ width: 200 }} placeholder="Select a person" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{useMemo(
									() =>
										futuresSymbols &&
										futuresSymbols.map((item, i) => (
											<Select.Option key={item.symbol} value={item.symbol}>
												{item.symbol.replace('USDT', '')}
											</Select.Option>
										)),
									[futuresSymbols]
								)}
							</Select>
						</Form.Item>
						<Form.Item name="marginType" label="Isolated" valuePropName="checked">
							<Switch checkedChildren="Isolate" unCheckedChildren="Cross" />
						</Form.Item>
						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
					<br />
					<Form
						{...layout}
						name="position_side_form"
						initialValues={{
							symbol: 'BTCUSDT'
						}}
						onFinish={handleBinancePositionSide}
					>
						<Form.Item name="positionSide" label="Hedge mode" valuePropName="checked">
							<Switch checkedChildren="ON" unCheckedChildren="Off" />
						</Form.Item>
						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Card> */}
			</Space>
		</>
	);
};
