import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { Form, Input, Button, Row, Col, Space, Card, InputNumber, Radio, Select, Switch, Divider, Descriptions, DatePicker } from 'antd';
import { format } from 'd3-format';
import { timeFormat, timeParse } from 'd3-time-format';
import { ChartCanvas, Chart } from 'react-stockcharts';
import { CandlestickSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { EdgeIndicator } from 'react-stockcharts/lib/coordinates';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { Label, Annotate, SvgPathAnnotation, LabelAnnotation, buyPath, sellPath } from 'react-stockcharts/lib/annotation';

import { getBinanceFuturesSymbolsAction } from '../modules/coin';
import { getBinanceStep } from '../hooks/useLibrary';
import useInterval from '../hooks/useInterval';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { span: 24 }
};

const v2LongReposition = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fontSize: 20,
	fill: '#03a66d',
	text: 'R'
};

const v2LongBuy = {
	y: ({ yScale, datum }) => yScale(datum.low),
	fill: '#03a66d',
	path: buyPath,
	tooltip: 'Buy'
};
const v2LongSell = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fill: '#03a66d',
	path: sellPath,
	tooltip: 'Sell'
};

const v2ShortReposition = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fontSize: 20,
	fill: '#cf304a',
	text: 'R'
};

const v2ShortBuy = {
	y: ({ yScale, datum }) => yScale(datum.low),
	fill: '#cf304a',
	path: buyPath,
	tooltip: 'Sell'
};

const v2ShortSell = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fill: '#cf304a',
	path: sellPath,
	tooltip: 'Sell'
};

const v3LongReposition = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fontSize: 20,
	fill: '#1890ff',
	text: 'R'
};

const v3LongBuy = {
	y: ({ yScale, datum }) => yScale(datum.low),
	fill: '#1890ff',
	path: buyPath,
	tooltip: 'Buy'
};

const v3LongSell = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fill: '#1890ff',
	path: sellPath,
	tooltip: 'Buy'
};

const v3ShortReposition = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fontSize: 20,
	fill: '#ff4d4f',
	text: 'R'
};

const v3ShortBuy = {
	y: ({ yScale, datum }) => yScale(datum.low),
	fill: '#ff4d4f',
	path: buyPath,
	tooltip: 'Sell'
};

const v3ShortSell = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fill: '#ff4d4f',
	path: sellPath,
	tooltip: 'Sell'
};

const currencyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', notation: 'compact', compactDisplay: 'short', maximumSignificantDigits: 4 }).format(Number(value));

const getBinanceCandle = async (startTime, symbol) => {
	return await axios
		.get(`https://fapi.binance.com/fapi/v1/markPriceKlines`, {
			params: { startTime, symbol, interval: '1m', limit: 1200 }
		})
		.then((response) => response.data)
		.catch((error) => {
			console.log(error);
			return false;
		});
};

const rePosition = async ({ position, oppositePosition, currentPrice, symbol }) => {
	let _position = {};
	let _orders = [];
	let strategyCount = 30;
	let tradeCount = position.version === 'v3' ? 10 : 5;
	let strategyMarginotPrice = currentPrice * (position.positionSide === 'LONG' ? (position.version === 'v3' ? 0.5 : 0.7) : position.version === 'v3' ? 1.8 : 1.6); // -30%, + 60%
	let tradeMarginotPrice = currentPrice * (position.positionSide === 'LONG' ? (position.version === 'v3' ? 0.2 : 0.5) : position.version === 'v3' ? 2.4 : 2); // -50%, + 100%

	if (oppositePosition.size > 0) {
		if (position.positionSide === 'LONG' && strategyMarginotPrice > oppositePosition.entryPrice) {
			strategyMarginotPrice = oppositePosition.entryPrice;
			tradeMarginotPrice = oppositePosition.entryPrice / 2;
		} else if (position.positionSide === 'SHORT' && strategyMarginotPrice < oppositePosition.entryPrice) {
			strategyMarginotPrice = oppositePosition.entryPrice;
			tradeMarginotPrice = currentPrice + (oppositePosition.entryPrice - currentPrice) * 2;
		}
	}

	// if (position.version === 'v3') {
	// 	const tempMarginotPrice = strategyMarginotPrice;
	// 	strategyMarginotPrice = tradeMarginotPrice;
	// 	tradeMarginotPrice = tempMarginotPrice;
	// }

	const strategyBase = position.budget / 2 / ((currentPrice + strategyMarginotPrice) / 2);
	const tradeBase = position.budget / 2 / ((currentPrice + tradeMarginotPrice) / 2);
	let strategyQuantity = Math.floor(strategyBase / strategyCount / symbol.quantityStep) * symbol.quantityStep;
	let tradeQuantity = Math.floor(tradeBase / tradeCount / symbol.quantityStep) * symbol.quantityStep;
	if (Number(strategyQuantity.toFixed(symbol.quantityPrecision)) === 0 || (position.positionSide === 'LONG' ? strategyMarginotPrice : currentPrice) * strategyQuantity < 5) {
		for (let i = strategyCount; i > 0 && (Number(strategyQuantity.toFixed(symbol.quantityPrecision)) === 0 || (position.positionSide === 'LONG' ? strategyMarginotPrice : currentPrice) * strategyQuantity < 5); i--) {
			strategyCount = i;
			strategyQuantity = Math.floor(strategyBase / strategyCount / symbol.quantityStep) * symbol.quantityStep;
		}
	}
	if (Number(tradeQuantity.toFixed(symbol.quantityPrecision)) === 0 || strategyQuantity.toFixed(symbol.quantityPrecision) === tradeQuantity.toFixed(symbol.quantityPrecision)) {
		for (let i = tradeCount; i > 0 && (Number(tradeQuantity.toFixed(symbol.quantityPrecision)) === 0 || strategyQuantity.toFixed(symbol.quantityPrecision) === tradeQuantity.toFixed(symbol.quantityPrecision)); i--) {
			tradeCount = i;
			tradeQuantity = Math.floor(tradeBase / tradeCount / symbol.quantityStep) * symbol.quantityStep;
		}
	}

	const immediateBuy = Math.round(strategyCount * 0.1) || 1;
	const orderCount = Math.round(strategyCount - immediateBuy) || 1;
	const step = (position.positionSide === 'LONG' ? currentPrice - strategyMarginotPrice : strategyMarginotPrice - currentPrice) / orderCount;
	const sftStep = (position.positionSide === 'LONG' ? currentPrice - tradeMarginotPrice : tradeMarginotPrice - currentPrice) / tradeCount;

	if (position.size > 0) {
		// console.log(`${position.version} ${position.positionSide} realized: ${position.realizedProfit} realizing: ${(position.positionSide === 'LONG' ? currentPrice - position.entryPrice : position.entryPrice - currentPrice) * position.size * 0.9996}`);
		const realizeProfit = position.realizedProfit + (position.positionSide === 'LONG' ? currentPrice - position.entryPrice : position.entryPrice - currentPrice) * position.size * 0.9996;
		_position = {
			...position,
			entryPrice: 0,
			size: 0,
			realizedProfit: realizeProfit,
			maxRealizedProfit: position.maxRealizedProfit < realizeProfit ? realizeProfit : position.maxRealizedProfit,
			minRealizedProfit: position.minRealizedProfit > realizeProfit ? realizeProfit : position.minRealizedProfit,
			repositionCount: position.repositionCount + 1,
			unrealizedProfit: 0
		};
	}

	if (step > 0) {
		for (let i = 0; i < tradeCount; i++) {
			_orders.push({
				side: position.positionSide === 'LONG' ? 'BUY' : 'SELL',
				positionSide: position.positionSide,
				type: 'SFT',
				version: position.version,
				price: Number(currentPrice + sftStep * i * (position.positionSide === 'LONG' ? -1 : 1).toFixed(symbol.pricePrecision)),
				quantity: Number(tradeQuantity.toFixed(symbol.quantityPrecision))
			});
		}
		for (let i = 0; i < orderCount; i++) {
			_orders.push({
				side: position.positionSide === 'LONG' ? 'BUY' : 'SELL',
				positionSide: position.positionSide,
				type: 'STANDARD',
				version: position.version,
				price: Number(currentPrice + step * i * (position.positionSide === 'LONG' ? -1 : 1).toFixed(symbol.pricePrecision)),
				quantity: Number(strategyQuantity.toFixed(symbol.quantityPrecision))
			});
		}

		_position = {
			...position,
			..._position,
			entryPrice: currentPrice,
			size: Number((strategyQuantity * immediateBuy).toFixed(symbol.quantityPrecision)),
			unrealizedProfit: 0
		};
	}

	_orders.sort((a, b) => (Math.abs(currentPrice - a.price) < Math.abs(currentPrice - b.price) ? -1 : 1));

	return { position: _position, orders: _orders };
};

export default () => {
	const { symbols } = useSelector(({ coinReducer }) => ({
		symbols: coinReducer.futuresSymbols.data
	}));
	const dispatch = useDispatch();

	const defaultViewData = {
		positions: {
			v2: {
				LONG: {
					entryPrice: 0,
					size: 0,
					realizedProfit: 0,
					maxRealizedProfit: 0,
					minRealizedProfit: 0,
					unrealizedProfit: 0,
					maxUnrealizedProfit: 0,
					minUnrealizedProfit: 0,
					budget: 1000,
					targetRoe: 0.008,
					positionSide: 'LONG',
					version: 'v2',
					openCount: 0,
					closeCount: 0,
					repositionCount: 0
				},
				SHORT: {
					entryPrice: 0,
					size: 0,
					realizedProfit: 0,
					maxRealizedProfit: 0,
					minRealizedProfit: 0,
					unrealizedProfit: 0,
					maxUnrealizedProfit: 0,
					minUnrealizedProfit: 0,
					budget: 500,
					targetRoe: 0.01,
					positionSide: 'SHORT',
					version: 'v2',
					openCount: 0,
					closeCount: 0,
					repositionCount: 0
				}
			},
			v3: {
				LONG: {
					entryPrice: 0,
					size: 0,
					tempRealizedProfit: 0,
					realizedProfit: 0,
					maxRealizedProfit: 0,
					minRealizedProfit: 0,
					unrealizedProfit: 0,
					maxUnrealizedProfit: 0,
					minUnrealizedProfit: 0,
					budget: 1000,
					targetRoe: 0.05,
					positionSide: 'LONG',
					version: 'v3',
					openCount: 0,
					closeCount: 0,
					repositionCount: 0
				},
				SHORT: {
					entryPrice: 0,
					size: 0,
					tempRealizedProfit: 0,
					realizedProfit: 0,
					maxRealizedProfit: 0,
					minRealizedProfit: 0,
					unrealizedProfit: 0,
					maxUnrealizedProfit: 0,
					minUnrealizedProfit: 0,
					budget: 500,
					targetRoe: 0.05,
					positionSide: 'SHORT',
					version: 'v3',
					openCount: 0,
					closeCount: 0,
					repositionCount: 0
				}
			}
		},
		orders: {
			v2: {
				LONG: [],
				SHORT: []
			},
			v3: {
				LONG: [],
				SHORT: []
			}
		},
		candleData: [],
		chartSourceData: [],
		chartData: { data: [], xScale: null, xAccessor: null, displayXAccessor: null, start: 0, end: 0, xExtents: [] },
		currentPrice: 0,
		openPrice: 0,
		closePrice: 0,
		highPrice: 0,
		lowPrice: 0
	};

	const [symbol, setSymbol] = useState({});
	const [startTime, setStartTime] = useState({
		now: moment().subtract(7, 'days').startOf('day'),
		from: moment().subtract(7, 'days').startOf('day')
	});
	const [viewData, setViewData] = useState(defaultViewData);
	const [isTimerRunning, setIsTimerRunning] = useState(false);

	useEffect(async () => {
		dispatch(getBinanceFuturesSymbolsAction());
	}, []);

	useInterval(
		async () => {
			// console.log(viewData.candleData.length);
			setIsTimerRunning(false);
			// console.log('11111111');
			// console.log(viewData);
			if (viewData?.candleData?.length) {
				let ticker = {
					...viewData.candleData[0],
					v2LongEntryPrice: viewData.positions.v2.LONG.entryPrice,
					v2ShortEntryPrice: viewData.positions.v2.SHORT.entryPrice,
					v3LongEntryPrice: viewData.positions.v3.LONG.entryPrice,
					v3ShortEntryPrice: viewData.positions.v3.SHORT.entryPrice,
					annotate: '',
					count: 0
				};
				let positions = {
					v2: {
						LONG: { ...viewData.positions.v2.LONG },
						SHORT: { ...viewData.positions.v2.SHORT }
					},
					v3: {
						LONG: { ...viewData.positions.v3.LONG },
						SHORT: { ...viewData.positions.v3.SHORT }
					}
				};
				let orders = {
					v2: {
						LONG: [...viewData.orders.v2.LONG],
						SHORT: [...viewData.orders.v2.SHORT]
					},
					v3: {
						LONG: [...viewData.orders.v3.LONG],
						SHORT: [...viewData.orders.v3.SHORT]
					}
				};

				if (positions.v2.LONG.size === 0 && positions.v2.SHORT.size === 0 && positions.v3.LONG.size === 0 && positions.v3.SHORT.size === 0) {
					// console.log('222222222');
					const v2Long = await rePosition({ position: { ...positions.v2.LONG }, oppositePosition: { ...positions.v2.SHORT }, currentPrice: ticker.close, symbol });
					const v2Short = await rePosition({ position: { ...positions.v2.SHORT }, oppositePosition: { ...positions.v2.LONG }, currentPrice: ticker.close, symbol });
					const v3Long = await rePosition({ position: { ...positions.v3.LONG }, oppositePosition: { ...positions.v3.SHORT }, currentPrice: ticker.close, symbol });
					const v3Short = await rePosition({ position: { ...positions.v3.SHORT }, oppositePosition: { ...positions.v3.LONG }, currentPrice: ticker.close, symbol });

					ticker = {
						...ticker,
						v2LongEntryPrice: v2Long.position.entryPrice,
						v2ShortEntryPrice: v2Short.position.entryPrice,
						v3LongEntryPrice: v3Long.position.entryPrice,
						v3ShortEntryPrice: v3Short.position.entryPrice,
						annotate: 'v2LongRepositionv2ShortRepositionv3LongRepositionv3ShortReposition'
					};
					positions = {
						v2: {
							LONG: {
								...positions.v2.LONG,
								...v2Long.position
							},
							SHORT: {
								...positions.v2.SHORT,
								...v2Short.position
							}
						},
						v3: {
							LONG: {
								...positions.v3.LONG,
								...v3Long.position
							},
							SHORT: {
								...positions.v3.SHORT,
								...v3Short.position
							}
						}
					};
					orders = {
						v2: {
							LONG: [...v2Long.orders],
							SHORT: [...v2Short.orders]
						},
						v3: {
							LONG: [...v3Long.orders],
							SHORT: [...v3Short.orders]
						}
					};

					const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => timeParse('%Y/%m/%d %H:%M:%S')(moment(d.timeStamp).local().format('YYYY/MM/DD HH:mm:ss')));
					const { data, xScale, xAccessor, displayXAccessor } = await xScaleProvider([...viewData.chartSourceData]);
					const start = data.length;
					const end = data.length >= 300 ? data.length - 300 : 0;
					const xExtents = [start, end];

					// console.log('3333333333');
					setViewData((prevState) => {
						return {
							...prevState,
							positions,
							orders,
							// candleData: [...prevState.candleData.splice(1)],
							chartSourceData: [...(prevState.chartSourceData.length > 600 ? prevState.chartSourceData.splice(1) : prevState.chartSourceData), ticker],
							chartData: { data, xScale, xAccessor, displayXAccessor, start, end, xExtents },
							currentPrice: ticker.close,
							openPrice: ticker.close,
							closePrice: ticker.close,
							highPrice: ticker.close,
							lowPrice: ticker.close
						};
					});
					setIsTimerRunning(true);
					// console.log('444444444');
				} else {
					// console.log('5555555555');
					for (let _candelData of viewData.candleData) {
						ticker = {
							...ticker,
							timeStamp: _candelData.timeStamp,
							high: ticker.high < _candelData.high ? _candelData.high : ticker.high,
							low: ticker.low > _candelData.low ? _candelData.low : ticker.low,
							close: _candelData.close,
							count: ticker.count + 1
						};

						const v2LongProcessOrders = orders?.v2?.LONG?.filter((x) => (x.side === 'BUY' && x.price >= ticker.close) || (x.side === 'SELL' && x.price <= ticker.close));
						const v2ShortProcessOrders = orders?.v2?.SHORT?.filter((x) => (x.side === 'BUY' && x.price >= ticker.close) || (x.side === 'SELL' && x.price <= ticker.close));
						const v3LongProcessOrders = orders?.v3?.LONG?.filter((x) => (x.side === 'BUY' && x.price >= ticker.close) || (x.side === 'SELL' && x.price <= ticker.close));
						const v3ShortProcessOrders = orders?.v3?.SHORT?.filter((x) => (x.side === 'BUY' && x.price >= ticker.close) || (x.side === 'SELL' && x.price <= ticker.close));

						if (v2LongProcessOrders.length) {
							const remainOrders = orders.v2.LONG.filter((x) => (x.side === 'BUY' && x.price < ticker.close) || (x.side === 'SELL' && x.price > ticker.close));

							for (const processOrder of v2LongProcessOrders) {
								if (processOrder.side === 'BUY') {
									positions.v2.LONG.entryPrice = (positions.v2.LONG.entryPrice * positions.v2.LONG.size + processOrder.price * processOrder.quantity) / (positions.v2.LONG.size + processOrder.quantity);
									positions.v2.LONG.size += processOrder.quantity;
									positions.v2.LONG.openCount = positions.v2.LONG.openCount + 1;
									ticker.annotate += 'v2LongBuy';
								} else {
									// console.log(`v2 LONG realized: ${positions.v2.LONG.realizedProfit} realizing: ${(processOrder.price - positions.v2.LONG.entryPrice) * processOrder.quantity * 0.9998}`);
									positions.v2.LONG.realizedProfit += (processOrder.price - positions.v2.LONG.entryPrice) * processOrder.quantity * 0.9998;
									positions.v2.LONG.maxRealizedProfit = positions.v2.LONG.maxRealizedProfit < positions.v2.LONG.realizedProfit ? positions.v2.LONG.realizedProfit : positions.v2.LONG.maxRealizedProfit;
									positions.v2.LONG.minRealizedProfit = positions.v2.LONG.minRealizedProfit > positions.v2.LONG.realizedProfit ? positions.v2.LONG.realizedProfit : positions.v2.LONG.minRealizedProfit;
									positions.v2.LONG.size -= processOrder.quantity;
									positions.v2.LONG.closeCount = positions.v2.LONG.closeCount + 1;
									ticker.annotate += 'v2LongSell';
								}

								if (processOrder.type === 'SFT') {
									const sellStep = processOrder.price * 0.005;
									const buyStep = processOrder.price * 0.008;
									let orderPrice = processOrder.price + (processOrder.side === 'BUY' ? sellStep : buyStep * -1);

									if (processOrder.side === 'BUY' && positions.v2.LONG.entryPrice + sellStep < processOrder.price * 1.0002) {
									} else {
										orderPrice = positions.v2.LONG.entryPrice + (processOrder.side === 'BUY' ? sellStep : buyStep * -1);
									}

									remainOrders.push({
										side: processOrder.side === 'BUY' ? 'SELL' : 'BUY',
										positionSide: processOrder.positionSide,
										type: 'SFT',
										version: processOrder.version,
										price: Number(orderPrice.toFixed(symbol.pricePrecision)),
										quantity: processOrder.quantity
									});
								}
							}

							orders.v2.LONG = [...remainOrders];
							orders.v2.LONG.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						if (ticker.close / positions.v2.LONG.entryPrice - 1 > positions.v2.LONG.targetRoe) {
							const v2Long = await rePosition({ position: { ...positions.v2.LONG }, oppositePosition: { ...positions.v2.SHORT }, currentPrice: ticker.close, symbol });

							ticker.v2LongEntryPrice = v2Long.position.entryPrice;
							ticker.annotate += 'v2LongReposition';
							positions.v2.LONG = {
								...positions.v2.LONG,
								...v2Long.position
							};

							orders.v2.LONG = [...v2Long.orders];
							orders.v2.LONG.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						if (v2ShortProcessOrders.length) {
							const remainOrders = orders.v2.SHORT.filter((x) => (x.side === 'BUY' && x.price < ticker.close) || (x.side === 'SELL' && x.price > ticker.close));

							for (const processOrder of v2ShortProcessOrders) {
								if (processOrder.side === 'SELL') {
									positions.v2.SHORT.entryPrice = (positions.v2.SHORT.entryPrice * positions.v2.SHORT.size + processOrder.price * processOrder.quantity) / (positions.v2.SHORT.size + processOrder.quantity);
									positions.v2.SHORT.size += processOrder.quantity;
									positions.v2.SHORT.openCount = positions.v2.SHORT.openCount + 1;
									ticker.annotate += 'v2ShortSell';
								} else {
									// console.log(`v2 SHORT realized: ${positions.v2.SHORT.realizedProfit} realizing: ${(positions.v2.SHORT.entryPrice - processOrder.price) * processOrder.quantity * 0.9998}`);
									positions.v2.SHORT.realizedProfit += (positions.v2.SHORT.entryPrice - processOrder.price) * processOrder.quantity * 0.9998;
									positions.v2.SHORT.maxRealizedProfit = positions.v2.SHORT.maxRealizedProfit < positions.v2.SHORT.realizedProfit ? positions.v2.SHORT.realizedProfit : positions.v2.SHORT.maxRealizedProfit;
									positions.v2.SHORT.minRealizedProfit = positions.v2.SHORT.minRealizedProfit > positions.v2.SHORT.realizedProfit ? positions.v2.SHORT.realizedProfit : positions.v2.SHORT.minRealizedProfit;
									positions.v2.SHORT.size -= processOrder.quantity;
									positions.v2.SHORT.closeCount = positions.v2.SHORT.closeCount + 1;
									ticker.annotate += 'v2ShortBuy';
								}

								if (processOrder.type === 'SFT') {
									const sellStep = processOrder.price * 0.008;
									const buyStep = processOrder.price * 0.005;
									let orderPrice = processOrder.price + (processOrder.side === 'BUY' ? sellStep : buyStep * -1);

									if (processOrder.side === 'SELL' && positions.v2.SHORT.entryPrice - buyStep > processOrder.price * 0.9998) {
									} else {
										orderPrice = positions.v2.SHORT.entryPrice + (processOrder.side === 'BUY' ? sellStep : buyStep * -1);
									}

									remainOrders.push({
										side: processOrder.side === 'BUY' ? 'SELL' : 'BUY',
										positionSide: processOrder.positionSide,
										type: 'SFT',
										version: processOrder.version,
										price: Number(orderPrice.toFixed(symbol.pricePrecision)),
										quantity: processOrder.quantity
									});
								}
							}

							orders.v2.SHORT = [...remainOrders];
							orders.v2.SHORT.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						if (positions.v2.SHORT.entryPrice / ticker.close - 1 > positions.v2.SHORT.targetRoe) {
							const v2Short = await rePosition({ position: { ...positions.v2.SHORT }, oppositePosition: { ...positions.v2.LONG }, currentPrice: ticker.close, symbol });

							ticker.v2ShortEntryPrice = v2Short.position.entryPrice;
							ticker.annotate += 'v2ShortReposition';
							positions.v2.SHORT = {
								...positions.v2.SHORT,
								...v2Short.position
							};

							orders.v2.SHORT = [...v2Short.orders];
							orders.v2.SHORT.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						if (v3LongProcessOrders.length) {
							const remainOrders = orders.v3.LONG.filter((x) => (x.side === 'BUY' && x.price < ticker.close) || (x.side === 'SELL' && x.price > ticker.close));

							for (const processOrder of v3LongProcessOrders) {
								if (processOrder.side === 'BUY') {
									positions.v3.LONG.entryPrice = (positions.v3.LONG.entryPrice * positions.v3.LONG.size + processOrder.price * processOrder.quantity) / (positions.v3.LONG.size + processOrder.quantity);
									positions.v3.LONG.size += processOrder.quantity;
									positions.v3.LONG.openCount = positions.v3.LONG.openCount + 1;
									ticker.annotate += 'v3LongBuy';
								} else {
									// console.log(`v3 LONG tempRealizedProfit: ${positions.v3.LONG.tempRealizedProfit} realizing: ${(processOrder.price - positions.v3.LONG.entryPrice) * processOrder.quantity * 0.9998}`);
									// console.log(`close: ${ticker.close}, size: ${positions.v3.LONG.size} tempRealizedProfit: ${positions.v3.LONG.tempRealizedProfit} entryPrice: ${positions.v3.LONG.entryPrice} targetRoe: ${positions.v3.LONG.targetRoe} (ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit: ${(ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit} positions.v3.LONG.entryPrice * positions.v3.LONG.size: ${positions.v3.LONG.entryPrice * positions.v3.LONG.size} ((ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit) / (positions.v3.LONG.entryPrice * positions.v3.LONG.size) - 1: ${((ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit) / (positions.v3.LONG.entryPrice * positions.v3.LONG.size) - 1}`);
									positions.v3.LONG.realizedProfit += (processOrder.price - positions.v3.LONG.entryPrice) * processOrder.quantity * 0.9998;
									positions.v3.LONG.tempRealizedProfit += (processOrder.price - positions.v3.LONG.entryPrice) * processOrder.quantity * 0.9998;
									positions.v3.LONG.maxRealizedProfit = positions.v3.LONG.maxRealizedProfit < positions.v3.LONG.realizedProfit ? positions.v3.LONG.realizedProfit : positions.v3.LONG.maxRealizedProfit;
									positions.v3.LONG.minRealizedProfit = positions.v3.LONG.minRealizedProfit > positions.v3.LONG.realizedProfit ? positions.v3.LONG.realizedProfit : positions.v3.LONG.minRealizedProfit;
									positions.v3.LONG.size -= processOrder.quantity;
									positions.v3.LONG.closeCount = positions.v3.LONG.closeCount + 1;
									ticker.annotate += 'v3LongSell';
								}

								if (processOrder.type === 'SFT') {
									const sellStep = processOrder.price * 0.005;
									const buyStep = processOrder.price * 0.008;
									let orderPrice = processOrder.price + (processOrder.side === 'BUY' ? sellStep : buyStep * -1);

									// if (processOrder.side === 'BUY' && positions.v3.LONG.entryPrice + sellStep < processOrder.price * 1.0002) {
									// } else {
									// 	orderPrice = positions.v3.LONG.entryPrice + (processOrder.side === 'BUY' ? sellStep : buyStep * -1);
									// }

									remainOrders.push({
										side: processOrder.side === 'BUY' ? 'SELL' : 'BUY',
										positionSide: processOrder.positionSide,
										type: 'SFT',
										version: processOrder.version,
										price: Number(orderPrice.toFixed(symbol.pricePrecision)),
										quantity: processOrder.quantity
									});

									if (processOrder.side === 'SELL' && positions.v3.LONG.entryPrice > orderPrice) {
										remainOrders.push({
											side: 'BUY',
											positionSide: processOrder.positionSide,
											type: 'STANDARD',
											version: processOrder.version,
											price: Number(orderPrice.toFixed(symbol.pricePrecision)),
											quantity: Number((processOrder.quantity * 0.02).toFixed(symbol.quantityPrecision))
										});
									}
								}
							}

							orders.v3.LONG = [...remainOrders];
							orders.v3.LONG.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						// LONG
						// 예상 손익률 = (현재가 - 진입가) / 진입가
						// 예상 손익 = (현재가 - 진입가) * 코인수 = (현재가 * 코인수) - (진입가 * 코인수)
						// 실제 손익률 = (((현재가 - 진입가) * 코인수) + 매매실현손익) / (진입가 * 코인수)
						// 실제 손익 = ((현재가 - 진입가) * 코인수) + 매매실현손익 = (현재가 * 코인수) - (진입가 * 코인수) + 매매실현손익

						// if (ticker.close / positions.v3.LONG.entryPrice - 1 > positions.v3.LONG.targetRoe) {
						if (((ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit) / (positions.v3.LONG.entryPrice * positions.v3.LONG.size) > positions.v3.LONG.targetRoe) {
							// console.log(`close: ${ticker.close}, size: ${positions.v3.LONG.size} tempRealizedProfit: ${positions.v3.LONG.tempRealizedProfit} entryPrice: ${positions.v3.LONG.entryPrice} targetRoe: ${positions.v3.LONG.targetRoe} (ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit: ${(ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit} positions.v3.LONG.entryPrice * positions.v3.LONG.size: ${positions.v3.LONG.entryPrice * positions.v3.LONG.size} ((ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit) / (positions.v3.LONG.entryPrice * positions.v3.LONG.size) - 1: ${((ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit) / (positions.v3.LONG.entryPrice * positions.v3.LONG.size) - 1}`);
							// console.log(`LONG expect profit rate: ${format(',.2%')(((ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size + positions.v3.LONG.tempRealizedProfit) / (positions.v3.LONG.entryPrice * positions.v3.LONG.size) - 1)} realizing: ${format('$,.2f')((ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size * 0.9996)}`);
							const v3Long = await rePosition({ position: { ...positions.v3.LONG }, oppositePosition: { ...positions.v3.SHORT }, currentPrice: ticker.close, symbol });

							ticker.v3LongEntryPrice = v3Long.position.entryPrice;
							ticker.annotate += 'v3LongReposition';
							positions.v3.LONG = {
								...positions.v3.LONG,
								...v3Long.position,
								tempRealizedProfit: 0
							};

							orders.v3.LONG = [...v3Long.orders];
							orders.v3.LONG.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						if (v3ShortProcessOrders.length) {
							const remainOrders = orders.v3.SHORT.filter((x) => (x.side === 'BUY' && x.price < ticker.close) || (x.side === 'SELL' && x.price > ticker.close));

							for (const processOrder of v3ShortProcessOrders) {
								if (processOrder.side === 'SELL') {
									positions.v3.SHORT.entryPrice = (positions.v3.SHORT.entryPrice * positions.v3.SHORT.size + processOrder.price * processOrder.quantity) / (positions.v3.SHORT.size + processOrder.quantity);
									positions.v3.SHORT.size += processOrder.quantity;
									positions.v3.SHORT.openCount = positions.v3.SHORT.openCount + 1;
									ticker.annotate += 'v3ShortSell';
								} else {
									// console.log(`v3 SHORT tempRealizedProfit: ${positions.v3.SHORT.tempRealizedProfit} realizing: ${(positions.v3.SHORT.entryPrice - processOrder.price) * processOrder.quantity * 0.9998}`);
									// console.log(`close: ${ticker.close}, size: ${positions.v3.SHORT.size} tempRealizedProfit: ${positions.v3.SHORT.tempRealizedProfit} entryPrice: ${positions.v3.SHORT.entryPrice} targetRoe: ${positions.v3.SHORT.targetRoe} (positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size + positions.v3.SHORT.tempRealizedProfit: ${(positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size + positions.v3.SHORT.tempRealizedProfit} positions.v3.SHORT.entryPrice * positions.v3.SHORT.size: ${positions.v3.SHORT.entryPrice * positions.v3.SHORT.size} ((positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size + positions.v3.SHORT.tempRealizedProfit) / (positions.v3.SHORT.entryPrice * positions.v3.SHORT.size) - 1: ${((positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size + positions.v3.SHORT.tempRealizedProfit) / (positions.v3.SHORT.entryPrice * positions.v3.SHORT.size) - 1}`);
									positions.v3.SHORT.realizedProfit += (positions.v3.SHORT.entryPrice - processOrder.price) * processOrder.quantity * 0.9998;
									positions.v3.SHORT.tempRealizedProfit += (positions.v3.SHORT.entryPrice - processOrder.price) * processOrder.quantity * 0.9998;
									positions.v3.SHORT.maxRealizedProfit = positions.v3.SHORT.maxRealizedProfit < positions.v3.SHORT.realizedProfit ? positions.v3.SHORT.realizedProfit : positions.v3.SHORT.maxRealizedProfit;
									positions.v3.SHORT.minRealizedProfit = positions.v3.SHORT.minRealizedProfit > positions.v3.SHORT.realizedProfit ? positions.v3.SHORT.realizedProfit : positions.v3.SHORT.minRealizedProfit;
									positions.v3.SHORT.size -= processOrder.quantity;
									positions.v3.SHORT.closeCount = positions.v3.SHORT.closeCount + 1;
									ticker.annotate += 'v3ShortBuy';
								}
								if (processOrder.type === 'SFT') {
									const sellStep = processOrder.price * 0.008;
									const buyStep = processOrder.price * 0.005;
									let orderPrice = processOrder.price + (processOrder.side === 'BUY' ? sellStep : buyStep * -1);

									// if (processOrder.side === 'SELL' && positions.v3.SHORT.entryPrice - buyStep > processOrder.price * 0.9998) {
									// } else {
									// 	orderPrice = positions.v3.SHORT.entryPrice + (processOrder.side === 'BUY' ? sellStep : buyStep * -1);
									// }

									remainOrders.push({
										side: processOrder.side === 'BUY' ? 'SELL' : 'BUY',
										positionSide: processOrder.positionSide,
										type: 'SFT',
										version: processOrder.version,
										price: Number(orderPrice.toFixed(symbol.pricePrecision)),
										quantity: processOrder.quantity
									});

									if (processOrder.side === 'BUY' && positions.v3.SHORT.entryPrice < orderPrice) {
										remainOrders.push({
											side: 'SELL',
											positionSide: processOrder.positionSide,
											type: 'STANDARD',
											version: processOrder.version,
											price: Number(orderPrice.toFixed(symbol.pricePrecision)),
											quantity: Number((processOrder.quantity * 0.02).toFixed(symbol.quantityPrecision))
										});
									}
								}
							}

							orders.v3.SHORT = [...remainOrders];
							orders.v3.SHORT.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						// SHORT
						// 예상 손익률 = (진입가 - 현재가) / 진입가
						// 예상 손익 = (진입가 - 현재가) * 코인수 = (진입가 * 코인수) - (현재가 * 코인수)
						// 실제 손익률 = (((진입가 - 현재가) * 코인수) + 매매실현손익) / (진입가 * 코인수)
						// 실제 손익 = ((진입가 - 현재가) * 코인수) + 매매실현손익 = (진입가 * 코인수) - (진입가 * 코인수) + 매매실현손익

						// if (positions.v3.SHORT.entryPrice / ticker.close - 1 > positions.v3.SHORT.targetRoe) {
						if (((positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size + positions.v3.SHORT.tempRealizedProfit) / (positions.v3.SHORT.entryPrice * positions.v3.SHORT.size) > positions.v3.SHORT.targetRoe) {
							// console.log(`expect SHORT profit rate: ${format(',.2%')((positions.v3.SHORT.entryPrice * positions.v3.SHORT.size) / (ticker.close * positions.v3.SHORT.size + positions.v3.SHORT.tempRealizedProfit) - 1)}`);
							// console.log(`SHORT expect profit rate: ${format(',.2%')((positions.v3.SHORT.entryPrice * positions.v3.SHORT.size) / (ticker.close * positions.v3.SHORT.size + positions.v3.SHORT.tempRealizedProfit) - 1)} realizing: ${format('$,.2f')((positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size * 0.9996)}`);
							const v3Short = await rePosition({ position: { ...positions.v3.SHORT }, oppositePosition: { ...positions.v3.LONG }, currentPrice: ticker.close, symbol });

							ticker.v3ShortEntryPrice = v3Short.position.entryPrice;
							ticker.annotate += 'v3ShortReposition';
							positions.v3.SHORT = {
								...positions.v3.SHORT,
								...v3Short.position,
								tempRealizedProfit: 0
							};

							orders.v3.SHORT = [...v3Short.orders];
							orders.v3.SHORT.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						if (ticker.count === 60) break;
					}

					// console.log('6666666666');

					const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => timeParse('%Y/%m/%d %H:%M:%S')(moment(d.timeStamp).local().format('YYYY/MM/DD HH:mm:ss')));
					const { data, xScale, xAccessor, displayXAccessor } = await xScaleProvider([...viewData.chartSourceData]);
					const start = data.length;
					const end = data.length >= 300 ? data.length - 300 : 0;
					const xExtents = [start, end];

					// console.log('7777777777');
					setViewData((prevState) => {
						// console.log(ticker.count, prevState.candleData.splice(ticker.count).length);
						return {
							...prevState,
							positions: {
								v2: {
									LONG: {
										...positions.v2.LONG,
										unrealizedProfit: (ticker.close - positions.v2.LONG.entryPrice) * positions.v2.LONG.size,
										maxUnrealizedProfit: prevState.positions.v2.LONG.maxUnrealizedProfit < (ticker.close - positions.v2.LONG.entryPrice) * positions.v2.LONG.size ? (ticker.close - positions.v2.LONG.entryPrice) * positions.v2.LONG.size : prevState.positions.v2.LONG.maxUnrealizedProfit,
										minUnrealizedProfit: prevState.positions.v2.LONG.minUnrealizedProfit > (ticker.close - positions.v2.LONG.entryPrice) * positions.v2.LONG.size ? (ticker.close - positions.v2.LONG.entryPrice) * positions.v2.LONG.size : prevState.positions.v2.LONG.minUnrealizedProfit,
										lowPrice: prevState.lowPrice > ticker.low ? ticker.low : prevState.lowPrice
									},
									SHORT: {
										...positions.v2.SHORT,
										unrealizedProfit: (positions.v2.SHORT.entryPrice - ticker.close) * positions.v2.SHORT.size,
										maxUnrealizedProfit: prevState.positions.v2.SHORT.maxUnrealizedProfit < (positions.v2.SHORT.entryPrice - ticker.close) * positions.v2.SHORT.size ? (positions.v2.SHORT.entryPrice - ticker.close) * positions.v2.SHORT.size : prevState.positions.v2.SHORT.maxUnrealizedProfit,
										minUnrealizedProfit: prevState.positions.v2.SHORT.minUnrealizedProfit > (positions.v2.SHORT.entryPrice - ticker.close) * positions.v2.SHORT.size ? (positions.v2.SHORT.entryPrice - ticker.close) * positions.v2.SHORT.size : prevState.positions.v2.SHORT.minUnrealizedProfit,
										lowPrice: prevState.lowPrice > ticker.low ? ticker.low : prevState.lowPrice
									}
								},
								v3: {
									LONG: {
										...positions.v3.LONG,
										unrealizedProfit: (ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size,
										maxUnrealizedProfit: prevState.positions.v3.LONG.maxUnrealizedProfit < (ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size ? (ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size : prevState.positions.v3.LONG.maxUnrealizedProfit,
										minUnrealizedProfit: prevState.positions.v3.LONG.minUnrealizedProfit > (ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size ? (ticker.close - positions.v3.LONG.entryPrice) * positions.v3.LONG.size : prevState.positions.v3.LONG.minUnrealizedProfit,
										lowPrice: prevState.lowPrice > ticker.low ? ticker.low : prevState.lowPrice
									},
									SHORT: {
										...positions.v3.SHORT,
										unrealizedProfit: (positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size,
										maxUnrealizedProfit: prevState.positions.v3.SHORT.maxUnrealizedProfit < (positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size ? (positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size : prevState.positions.v3.SHORT.maxUnrealizedProfit,
										minUnrealizedProfit: prevState.positions.v3.SHORT.minUnrealizedProfit > (positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size ? (positions.v3.SHORT.entryPrice - ticker.close) * positions.v3.SHORT.size : prevState.positions.v3.SHORT.minUnrealizedProfit,
										lowPrice: prevState.lowPrice > ticker.low ? ticker.low : prevState.lowPrice
									}
								}
							},
							orders,
							candleData: [...prevState.candleData.splice(ticker.count)],
							chartSourceData: [...(prevState.chartSourceData.length > 600 ? prevState.chartSourceData.splice(1) : prevState.chartSourceData), ticker],
							chartData: { data, xScale, xAccessor, displayXAccessor, start, end, xExtents },
							currentPrice: ticker.close,
							closePrice: ticker.close,
							highPrice: prevState.highPrice < ticker.high ? ticker.high : prevState.highPrice,
							lowPrice: prevState.lowPrice > ticker.low ? ticker.low : prevState.lowPrice
						};
					});

					// console.log('888888888');
					setIsTimerRunning(true);
				}
			} else {
				// console.log('999999999');
				if (symbol.s && startTime.now.diff(moment(), 'hours') < 0) {
					setIsTimerRunning(false);
					// console.log('90909090909090');
					const candles = await getBinanceCandle(startTime.now.valueOf(), symbol.s);
					// console.log('91919191919191');
					const forChart = [];

					for (const candle of candles) {
						forChart.push({
							timeStamp: candle[0],
							open: Number(candle[1]),
							high: Number(candle[2]),
							low: Number(candle[3]),
							close: Number(candle[4]),
							v2LongEntryPrice: 0,
							v2ShortEntryPrice: 0,
							v3LongEntryPrice: 0,
							v3ShortEntryPrice: 0,
							annotate: ''
						});
					}

					// console.log('92929292992929292');
					setStartTime((prevState) => {
						return {
							from: prevState.from,
							now: prevState.now.add(20, 'hours')
						};
					});
					setViewData((prevState) => {
						return {
							...prevState,
							candleData: forChart
						};
					});
					setIsTimerRunning(true);
				} else {
					// console.log('939393939393939393');
					setIsTimerRunning(false);
				}
				// console.log('1010101010101010');
			}
		},
		isTimerRunning ? 10 : null
	);

	const handleSymbol = (value) => {
		const { priceStep, pricePrecision, quantityStep, quantityPrecision } = getBinanceStep(symbols.find((x) => x.symbol === value));
		setSymbol({ s: value, priceStep, pricePrecision, quantityStep, quantityPrecision });
	};

	const handleSimulator = (values, flag) => {
		if (flag) {
			setViewData((prevState) => {
				return {
					...prevState,
					positions: {
						v2: {
							LONG: {
								...prevState.positions.v2.LONG,
								budget: values.v2LongBudget,
								targetRoe: values.v2LongTargetRoe
							},
							SHORT: {
								...prevState.positions.v2.SHORT,
								budget: values.v2ShortBudget,
								targetRoe: values.v2ShortTargetRoe
							}
						},
						v3: {
							LONG: {
								...prevState.positions.v3.LONG,
								budget: values.v3LongBudget,
								targetRoe: values.v3LongTargetRoe
							},
							SHORT: {
								...prevState.positions.v3.SHORT,
								budget: values.v3ShortBudget,
								targetRoe: values.v3ShortTargetRoe
							}
						}
					}
				};
			});
			setIsTimerRunning(true);
		} else {
			setIsTimerRunning(false);
		}
	};

	return (
		<div style={{ maxWidth: '1400px', margin: '0 auto' }}>
			<Card title="코라밸 바이낸스 시뮬레이터" size="small" style={{ width: '100%', textAlign: 'center' }}>
				<Form {...layout} name="simulator" initialValues={{ symbol: symbol.s, startTime: startTime.from, v2LongBudget: 1000, v2LongTargetRoe: 0.008, v2ShortBudget: 500, v2ShortTargetRoe: 0.01, v3LongBudget: 1000, v3LongTargetRoe: 0.05, v3ShortBudget: 500, v3ShortTargetRoe: 0.05 }} onFinish={(e) => handleSimulator(e, symbol.s && !isTimerRunning)}>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={8}>
							<Form.Item name="symbol" label="코인">
								<Select showSearch style={{ width: 200 }} placeholder="코인을 선택해주세요." optionFilterProp="children" value={symbol.s} onChange={(e) => handleSymbol(e)} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
									{useMemo(
										() =>
											symbols &&
											symbols.map((item, i) => (
												<Select.Option key={item.symbol} value={item.symbol}>
													{item.symbol.replace('USDT', '')}
												</Select.Option>
											)),
										[symbols]
									)}
								</Select>
							</Form.Item>
							<Form.Item name="startTime" label="시작 시간">
								<DatePicker format="YYYY-MM-DD HH" showTime={{ defaultValue: moment('00:00:00', 'HH') }} onChange={(e) => setStartTime({ now: moment(e).startOf('day'), from: moment(e).startOf('day') })} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label={<label style={{ color: '#03a66d' }}>{`${symbol?.s?.replace('USDT', '') || 'COIN'} LONG`}</label>} style={{ marginBottom: 0 }}>
								<Form.Item
									name="v2LongBudget"
									style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Number(value) < 500 ? Promise.reject(new Error('예산은 $500 이상 입력해주세요.')) : Promise.resolve())
										},
										{
											required: true,
											message: '예산을 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix="USDT" />
								</Form.Item>
								<Form.Item
									name="v2LongTargetRoe"
									style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '목표 수익률을 입력하세요.'
										}
									]}
								>
									<InputNumber style={{ width: 100 }} min={0} max={100} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
								</Form.Item>
							</Form.Item>
							<Form.Item label={<label style={{ color: '#cf304a' }}>{`${symbol?.s?.replace('USDT', '') || 'COIN'} SHORT`}</label>} style={{ marginBottom: 0 }}>
								<Form.Item
									name="v2ShortBudget"
									style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Number(value) < 500 ? Promise.reject(new Error('예산은 $500 이상 입력해주세요.')) : Promise.resolve())
										},
										{
											required: true,
											message: '예산을 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix="USDT" />
								</Form.Item>
								<Form.Item
									name="v2ShortTargetRoe"
									style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '목표 수익률을 입력하세요.'
										}
									]}
								>
									<InputNumber style={{ width: 100 }} min={0} max={100} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
								</Form.Item>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label={<label style={{ color: '#1890ff' }}>{`${symbol?.s?.replace('USDT', '') || 'COIN'} LONG(V3)`}</label>} style={{ marginBottom: 0 }}>
								<Form.Item
									name="v3LongBudget"
									style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Number(value) < 500 ? Promise.reject(new Error('예산은 $500 이상 입력해주세요.')) : Promise.resolve())
										},
										{
											required: true,
											message: '예산을 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix="USDT" />
								</Form.Item>
								<Form.Item
									name="v3LongTargetRoe"
									style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '목표 수익률을 입력하세요.'
										}
									]}
								>
									<InputNumber style={{ width: 100 }} min={0} max={100} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
								</Form.Item>
							</Form.Item>
							<Form.Item label={<label style={{ color: '#ff4d4f' }}>{`${symbol?.s?.replace('USDT', '') || 'COIN'} SHORT(V3)`}</label>} style={{ marginBottom: 0 }}>
								<Form.Item
									name="v3ShortBudget"
									style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Number(value) < 500 ? Promise.reject(new Error('예산은 $500 이상 입력해주세요.')) : Promise.resolve())
										},
										{
											required: true,
											message: '예산을 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix="USDT" />
								</Form.Item>
								<Form.Item
									name="v3ShortTargetRoe"
									style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '목표 수익률을 입력하세요.'
										}
									]}
								>
									<InputNumber style={{ width: 100 }} min={0} max={100} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
								</Form.Item>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={24}>
							<Form.Item {...tailLayout}>
								<Button type={isTimerRunning ? 'danger' : 'primary'} htmlType="submit" style={{ marginRight: '8px' }}>
									{isTimerRunning ? '시뮬레이션 중지' : '시뮬레이션 시작'}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Card>
			<Card title="트레이딩 뷰" size="small" style={{ width: '100%', textAlign: 'center' }}>
				{viewData?.chartData?.data?.length > 1 && (
					<ChartCanvas ratio={2} width={1200} height={400} margin={{ left: 90, right: 90, top: 70, bottom: 30 }} type={'hybrid'} seriesName="MSFT" data={viewData.chartData.data} xScale={viewData.chartData.xScale} xAccessor={viewData.chartData.xAccessor} displayXAccessor={viewData.chartData.displayXAccessor} xExtents={viewData.chartData.xExtents}>
						<Chart id={1} yExtents={(d) => [d.high, d.low]} padding={{ top: 10, bottom: 20 }}>
							<XAxis axisAt="bottom" orient="bottom" />
							<YAxis axisAt="right" orient="right" ticks={5} tickFormat={currencyFormat} />
							<CandlestickSeries />
							<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={(d) => d.close} fill={'#f79437'} />
							<EdgeIndicator itemType="last" orient="left" edgeAt="left" yAccessor={(d) => d.v2LongEntryPrice} fill={'#03a66d'} />
							<EdgeIndicator itemType="last" orient="left" edgeAt="left" yAccessor={(d) => d.v2ShortEntryPrice} fill={'#cf304a'} />
							<EdgeIndicator itemType="last" orient="left" edgeAt="left" yAccessor={(d) => d.v3LongEntryPrice} fill={'#1890ff'} />
							<EdgeIndicator itemType="last" orient="left" edgeAt="left" yAccessor={(d) => d.v3ShortEntryPrice} fill={'#ff4d4f'} />
							<Annotate with={LabelAnnotation} when={(d) => d.annotate.indexOf('v2LongReposition') !== -1} usingProps={v2LongReposition} />
							<Annotate with={LabelAnnotation} when={(d) => d.annotate.indexOf('v2ShortReposition') !== -1} usingProps={v2ShortReposition} />
							<Annotate with={LabelAnnotation} when={(d) => d.annotate.indexOf('v3LongReposition') !== -1} usingProps={v3LongReposition} />
							<Annotate with={LabelAnnotation} when={(d) => d.annotate.indexOf('v3ShortReposition') !== -1} usingProps={v3ShortReposition} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('v2LongBuy') !== -1} usingProps={v2LongBuy} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('v2LongSell') !== -1} usingProps={v2LongSell} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('v2ShortBuy') !== -1} usingProps={v2ShortBuy} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('v2ShortSell') !== -1} usingProps={v2ShortSell} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('v3LongBuy') !== -1} usingProps={v3LongBuy} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('v3LongSell') !== -1} usingProps={v3LongSell} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('v3ShortBuy') !== -1} usingProps={v3ShortBuy} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('v3ShortSell') !== -1} usingProps={v3ShortSell} />
						</Chart>
					</ChartCanvas>
				)}
			</Card>
			<Card title={`수익 및 자산 변화 | 현재 시간: ${moment(viewData?.candleData[0]?.timeStamp).local().format('YYYY/MM/DD HH:mm')}`} size="small" style={{ width: '100%', textAlign: 'center' }}>
				<Descriptions bordered size="small" column={{ xs: 2, xl: 6 }}>
					<Descriptions.Item label={`${symbol?.s?.replace('USDT', '') || 'COIN'} LONG`} labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: '#03a66d' }}>
						{format(`,.${symbol?.quantityPrecision || 0}f`)(viewData?.positions?.v2?.LONG?.size || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="진입가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{format(`$,.${symbol?.pricePrecision || 0}f`)(viewData?.positions?.v2?.LONG?.entryPrice || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="미실현손익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.unrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.LONG?.unrealizedProfit || 0)} (${format(',.2%')((viewData?.currentPrice || 1) / (viewData?.positions?.v2?.LONG?.entryPrice || 1) - 1)})`}
					</Descriptions.Item>
					<Descriptions.Item label="실현수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.realizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.LONG?.realizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="현재 순수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.realizedProfit || 0) + (viewData?.positions?.v2?.LONG?.unrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')((viewData?.positions?.v2?.LONG?.realizedProfit || 0) + (viewData?.positions?.v2?.LONG?.unrealizedProfit || 0))} (${format(',.2%')(((viewData?.positions?.v2?.LONG?.realizedProfit || 0) + (viewData?.positions?.v2?.LONG?.unrealizedProfit || 0)) / (viewData?.positions?.v2?.LONG?.budget || 1000))})`}
					</Descriptions.Item>
					<Descriptions.Item label="양방향 합산수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.realizedProfit || 0) + (viewData?.positions?.v2?.LONG?.unrealizedProfit || 0) + (viewData?.positions?.v2.SHORT?.realizedProfit || 0) + (viewData?.positions?.v2.SHORT?.unrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')((viewData?.positions?.v2?.LONG?.realizedProfit || 0) + (viewData?.positions?.v2?.LONG?.unrealizedProfit || 0) + (viewData?.positions?.v2.SHORT?.realizedProfit || 0) + (viewData?.positions?.v2.SHORT?.unrealizedProfit || 0))}`}
					</Descriptions.Item>
					<Descriptions.Item label={`${symbol?.s?.replace('USDT', '') || 'COIN'} SHORT`} labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: '#cf304a' }}>
						{format(`,.${symbol?.quantityPrecision || 0}f`)(viewData?.positions?.v2.SHORT?.size || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="진입가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{format(`$,.${symbol?.pricePrecision || 0}f`)(viewData?.positions?.v2.SHORT?.entryPrice || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="미실현손익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2.SHORT?.unrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2.SHORT?.unrealizedProfit || 0)} (${format(',.2%')((viewData?.positions?.v2?.SHORT?.entryPrice || 1) / (viewData?.currentPrice || 1) - 1)})`}
					</Descriptions.Item>
					<Descriptions.Item label="실현수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2.SHORT?.realizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2.SHORT?.realizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="현재 순수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2.SHORT?.realizedProfit || 0) + (viewData?.positions?.v2.SHORT?.unrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')((viewData?.positions?.v2.SHORT?.realizedProfit || 0) + (viewData?.positions?.v2.SHORT?.unrealizedProfit || 0))} (${format(',.2%')(((viewData?.positions?.v2.SHORT?.realizedProfit || 0) + (viewData?.positions?.v2.SHORT?.unrealizedProfit || 0)) / (viewData?.positions?.v2.SHORT?.budget || 1000))})`}
					</Descriptions.Item>
					<Descriptions.Item label="양방향 합산수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.realizedProfit || 0) + (viewData?.positions?.v2?.LONG?.unrealizedProfit || 0) + (viewData?.positions?.v2.SHORT?.realizedProfit || 0) + (viewData?.positions?.v2.SHORT?.unrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')((viewData?.positions?.v2?.LONG?.realizedProfit || 0) + (viewData?.positions?.v2?.LONG?.unrealizedProfit || 0) + (viewData?.positions?.v2.SHORT?.realizedProfit || 0) + (viewData?.positions?.v2.SHORT?.unrealizedProfit || 0))}`}
					</Descriptions.Item>
					<Descriptions.Item label={`${symbol?.s?.replace('USDT', '') || 'COIN'} LONG (V3)`} labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: '#1890ff' }}>
						{format(`,.${symbol?.quantityPrecision || 0}f`)(viewData?.positions?.v3?.LONG?.size || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="진입가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{format(`$,.${symbol?.pricePrecision || 0}f`)(viewData?.positions?.v3?.LONG?.entryPrice || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="미실현손익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.unrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.LONG?.unrealizedProfit || 0)} (${format(',.2%')((viewData?.currentPrice || 1) / (viewData?.positions?.v3?.LONG?.entryPrice || 1) - 1)})`}
					</Descriptions.Item>
					<Descriptions.Item label="실현수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.realizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.LONG?.realizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="현재 순수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.realizedProfit || 0) + (viewData?.positions?.v3?.LONG?.unrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')((viewData?.positions?.v3?.LONG?.realizedProfit || 0) + (viewData?.positions?.v3?.LONG?.unrealizedProfit || 0))} (${format(',.2%')(((viewData?.positions?.v3?.LONG?.realizedProfit || 0) + (viewData?.positions?.v3?.LONG?.unrealizedProfit || 0)) / (viewData?.positions?.v3?.LONG?.budget || 1000))})`}
					</Descriptions.Item>
					<Descriptions.Item label="양방향 합산수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.realizedProfit || 0) + (viewData?.positions?.v3?.LONG?.unrealizedProfit || 0) + (viewData?.positions?.v3.SHORT?.realizedProfit || 0) + (viewData?.positions?.v3.SHORT?.unrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')((viewData?.positions?.v3?.LONG?.realizedProfit || 0) + (viewData?.positions?.v3?.LONG?.unrealizedProfit || 0) + (viewData?.positions?.v3.SHORT?.realizedProfit || 0) + (viewData?.positions?.v3.SHORT?.unrealizedProfit || 0))}`}
					</Descriptions.Item>
					<Descriptions.Item label={`${symbol?.s?.replace('USDT', '') || 'COIN'} SHORT (V3)`} labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: '#ff4d4f' }}>
						{format(`,.${symbol?.quantityPrecision || 0}f`)(viewData?.positions?.v3.SHORT?.size || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="진입가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{format(`$,.${symbol?.pricePrecision || 0}f`)(viewData?.positions?.v3.SHORT?.entryPrice || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="미실현손익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3.SHORT?.unrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3.SHORT?.unrealizedProfit || 0)} (${format(',.2%')((viewData?.positions?.v3?.SHORT?.entryPrice || 1) / (viewData?.currentPrice || 1) - 1)})`}
					</Descriptions.Item>
					<Descriptions.Item label="실현수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3.SHORT?.realizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3.SHORT?.realizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="현재 순수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3.SHORT?.realizedProfit || 0) + (viewData?.positions?.v3.SHORT?.unrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')((viewData?.positions?.v3.SHORT?.realizedProfit || 0) + (viewData?.positions?.v3.SHORT?.unrealizedProfit || 0))} (${format(',.2%')(((viewData?.positions?.v3.SHORT?.realizedProfit || 0) + (viewData?.positions?.v3.SHORT?.unrealizedProfit || 0)) / (viewData?.positions?.v3.SHORT?.budget || 1000))})`}
					</Descriptions.Item>
					<Descriptions.Item label="양방향 합산수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.realizedProfit || 0) + (viewData?.positions?.v3?.LONG?.unrealizedProfit || 0) + (viewData?.positions?.v3.SHORT?.realizedProfit || 0) + (viewData?.positions?.v3.SHORT?.unrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')((viewData?.positions?.v3?.LONG?.realizedProfit || 0) + (viewData?.positions?.v3?.LONG?.unrealizedProfit || 0) + (viewData?.positions?.v3.SHORT?.realizedProfit || 0) + (viewData?.positions?.v3.SHORT?.unrealizedProfit || 0))}`}
					</Descriptions.Item>
				</Descriptions>
			</Card>
			{/* (LONG: ${format(`$,.2f`)(viewData?.positions?.v3?.LONG?.tempRealizedProfit || 0)} ${format(',.2%')((((viewData?.currentPrice || 1) - viewData?.positions?.v3?.LONG?.entryPrice) * viewData?.positions?.v3?.LONG?.size + viewData?.positions?.v3?.LONG?.tempRealizedProfit) / (viewData?.positions?.v3?.LONG?.entryPrice * viewData?.positions?.v3?.LONG?.size) - 1)} ${format('$,.2f')(((viewData?.currentPrice || 1) - viewData?.positions?.v3?.LONG?.entryPrice) * viewData?.positions?.v3?.LONG?.size + viewData?.positions?.v3?.LONG?.tempRealizedProfit)} / ${format('$,.2f')(viewData?.positions?.v3?.LONG?.entryPrice * viewData?.positions?.v3?.LONG?.size)}, SHORT: ${format(`$,.2f`)(viewData?.positions?.v3?.SHORT?.tempRealizedProfit || 0)} ${format(`$,.2f`)((((viewData?.positions?.v3?.SHORT?.entryPrice - (viewData?.currentPrice || 1)) * viewData?.positions?.v3?.SHORT?.size) + viewData?.positions?.v3?.SHORT?.tempRealizedProfit) / (viewData?.positions?.v3?.SHORT?.entryPrice * viewData?.positions?.v3?.SHORT?.size) - 1)}) */}
			<Card title={`${symbol?.s?.replace('USDT', '') || 'COIN'} 시뮬레이션 결과`} size="small" style={{ width: '100%', textAlign: 'center' }}>
				<Descriptions bordered size="small" column={{ xs: 2, xl: 6 }}>
					<Descriptions.Item label="시작" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`${startTime?.from?.format('YYYY/MM/DD HH')}`}
					</Descriptions.Item>
					<Descriptions.Item label="종료" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`${moment(viewData?.candleData[0]?.timeStamp).local().format('YYYY/MM/DD HH')}`}
					</Descriptions.Item>
					<Descriptions.Item label="시가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`${format(`$,.${symbol?.pricePrecision || 0}f`)(viewData?.openPrice || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="종가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`${format(`$,.${symbol?.pricePrecision || 0}f`)(viewData?.closePrice || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="고가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`${format(`$,.${symbol?.pricePrecision || 0}f`)(viewData?.highPrice || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="저가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`${format(`$,.${symbol?.pricePrecision || 0}f`)(viewData?.lowPrice || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label={`${symbol?.s?.replace('USDT', '') || 'COIN'} LONG`} labelStyle={{ padding: '2px 16px', color: '#03a66d' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`$${viewData?.positions?.v2?.LONG?.budget || 0} (${format(',.2%')(viewData?.positions?.v2?.LONG?.targetRoe || 0)})`}
					</Descriptions.Item>
					<Descriptions.Item label={`재진입: ${viewData?.positions?.v2?.LONG?.repositionCount || 0}`} labelStyle={{ textAlign: 'right', padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`별동대: ${viewData?.positions?.v2?.LONG?.closeCount || 0}`}
					</Descriptions.Item>
					<Descriptions.Item label="최고 미실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.maxUnrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.LONG?.maxUnrealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최저 미실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.minUnrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.LONG?.minUnrealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최고 실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.maxRealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.LONG?.maxRealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최저 실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.LONG?.minRealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.LONG?.minRealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label={`${symbol?.s?.replace('USDT', '') || 'COIN'} SHORT`} labelStyle={{ padding: '2px 16px', color: '#cf304a' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`$${viewData?.positions?.v2?.SHORT?.budget || 0} (${format(',.2%')(viewData?.positions?.v2?.SHORT?.targetRoe || 0)})`}
					</Descriptions.Item>
					<Descriptions.Item label={`재진입: ${viewData?.positions?.v2?.SHORT?.repositionCount || 0}`} labelStyle={{ textAlign: 'right', padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`별동대: ${viewData?.positions?.v2?.SHORT?.closeCount || 0}`}
					</Descriptions.Item>
					<Descriptions.Item label="최고 미실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.SHORT?.maxUnrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.SHORT?.maxUnrealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최저 미실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.SHORT?.minUnrealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.SHORT?.minUnrealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최고 실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.SHORT?.maxRealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.SHORT?.maxRealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최저 실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v2?.SHORT?.minRealizedProfit || 0) >= 0 ? '#03a66d' : '#cf304a' }}>
						{`${format('$,.2f')(viewData?.positions?.v2?.SHORT?.minRealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label={`${symbol?.s?.replace('USDT', '') || 'COIN'} LONG (V3)`} labelStyle={{ padding: '2px 16px', color: '#1890ff' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`$${viewData?.positions?.v3?.LONG?.budget || 0} (${format(',.2%')(viewData?.positions?.v3?.LONG?.targetRoe || 0)})`}
					</Descriptions.Item>
					<Descriptions.Item label={`재진입: ${viewData?.positions?.v3?.LONG?.repositionCount || 0}`} labelStyle={{ textAlign: 'right', padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`별동대: ${viewData?.positions?.v3?.LONG?.closeCount || 0}`}
					</Descriptions.Item>
					<Descriptions.Item label="최고 미실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.maxUnrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.LONG?.maxUnrealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최저 미실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.minUnrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.LONG?.minUnrealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최고 실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.maxRealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.LONG?.maxRealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최저 실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.LONG?.minRealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.LONG?.minRealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label={`${symbol?.s?.replace('USDT', '') || 'COIN'} SHORT (V3)`} labelStyle={{ padding: '2px 16px', color: '#ff4d4f' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`$${viewData?.positions?.v3?.SHORT?.budget || 0} (${format(',.2%')(viewData?.positions?.v3?.SHORT?.targetRoe || 0)})`}
					</Descriptions.Item>
					<Descriptions.Item label={`재진입: ${viewData?.positions?.v3?.SHORT?.repositionCount || 0}`} labelStyle={{ textAlign: 'right', padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`별동대: ${viewData?.positions?.v3?.SHORT?.closeCount || 0}`}
					</Descriptions.Item>
					<Descriptions.Item label="최고 미실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.SHORT?.maxUnrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.SHORT?.maxUnrealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최저 미실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.SHORT?.minUnrealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.SHORT?.minUnrealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최고 실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.SHORT?.maxRealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.SHORT?.maxRealizedProfit || 0)}`}
					</Descriptions.Item>
					<Descriptions.Item label="최저 실현" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: (viewData?.positions?.v3?.SHORT?.minRealizedProfit || 0) >= 0 ? '#1890ff' : '#ff4d4f' }}>
						{`${format('$,.2f')(viewData?.positions?.v3?.SHORT?.minRealizedProfit || 0)}`}
					</Descriptions.Item>
				</Descriptions>
			</Card>
			<Card title="주문" size="small" style={{ width: '100%', textAlign: 'center' }}>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col span={6}>
						<Descriptions bordered size="small" column={{ xs: 1, xl: 1 }}>
							<Descriptions.Item label={`V2 (${viewData?.orders?.v2?.LONG?.length || 0})`} labelStyle={{ textAlign: 'center', padding: '2px 16px' }} contentStyle={{ textAlign: 'center', padding: '2px 16px', color: '#03a66d' }}>
								LONG
							</Descriptions.Item>
							{viewData?.orders?.v2?.LONG?.map((item, key) => {
								return (
									<Descriptions.Item key={key} label={`${item.side === 'BUY' ? 'OPEN' : 'CLOSE'} (${item.quantity})`} labelStyle={{ textAlign: 'right', padding: '2px 16px', color: item.side === 'BUY' ? '#03a66d' : '#cf304a', fontWeight: item.type === 'SFT' ? 'bold' : 'normal' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: item.type === 'SFT' ? 'bold' : 'normal' }}>
										{format(`$,.${symbol.pricePrecision}f`)(item.price)}
									</Descriptions.Item>
								);
							})}
						</Descriptions>
					</Col>
					<Col span={6}>
						<Descriptions bordered size="small" column={{ xs: 1, xl: 1 }}>
							<Descriptions.Item label={`V2 (${viewData?.orders?.v2?.SHORT?.length || 0})`} labelStyle={{ textAlign: 'center', padding: '2px 16px' }} contentStyle={{ textAlign: 'center', padding: '2px 16px', color: '#cf304a' }}>
								SHORT
							</Descriptions.Item>
							{viewData?.orders?.v2?.SHORT?.map((item, key) => {
								return (
									<Descriptions.Item key={key} label={`${item.side === 'SELL' ? 'OPEN' : 'CLOSE'} (${item.quantity})`} labelStyle={{ textAlign: 'right', padding: '2px 16px', color: item.side === 'BUY' ? '#03a66d' : '#cf304a', fontWeight: item.type === 'SFT' ? 'bold' : 'normal' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: item.type === 'SFT' ? 'bold' : 'normal' }}>
										{format(`$,.${symbol.pricePrecision}f`)(item.price)}
									</Descriptions.Item>
								);
							})}
						</Descriptions>
					</Col>
					<Col span={6}>
						<Descriptions bordered size="small" column={{ xs: 1, xl: 1 }}>
							<Descriptions.Item label={`v3 (${viewData?.orders?.v3?.LONG?.length || 0})`} labelStyle={{ textAlign: 'center', padding: '2px 16px' }} contentStyle={{ textAlign: 'center', padding: '2px 16px', color: '#1890ff' }}>
								LONG
							</Descriptions.Item>
							{viewData?.orders?.v3?.LONG?.map((item, key) => {
								return (
									<Descriptions.Item key={key} label={`${item.side === 'BUY' ? 'OPEN' : 'CLOSE'} (${item.quantity})`} labelStyle={{ textAlign: 'right', padding: '2px 16px', color: item.side === 'BUY' ? '#1890ff' : '#ff4d4f', fontWeight: item.type === 'SFT' ? 'bold' : 'normal' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: item.type === 'SFT' ? 'bold' : 'normal' }}>
										{format(`$,.${symbol.pricePrecision}f`)(item.price)}
									</Descriptions.Item>
								);
							})}
						</Descriptions>
					</Col>
					<Col span={6}>
						<Descriptions bordered size="small" column={{ xs: 1, xl: 1 }}>
							<Descriptions.Item label={`v3 (${viewData?.orders?.v3?.SHORT?.length || 0})`} labelStyle={{ textAlign: 'center', padding: '2px 16px' }} contentStyle={{ textAlign: 'center', padding: '2px 16px', color: '#ff4d4f' }}>
								SHORT
							</Descriptions.Item>
							{viewData?.orders?.v3?.SHORT?.map((item, key) => {
								return (
									<Descriptions.Item key={key} label={`${item.side === 'SELL' ? 'OPEN' : 'CLOSE'} (${item.quantity})`} labelStyle={{ textAlign: 'right', padding: '2px 16px', color: item.side === 'BUY' ? '#1890ff' : '#ff4d4f', fontWeight: item.type === 'SFT' ? 'bold' : 'normal' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: item.type === 'SFT' ? 'bold' : 'normal' }}>
										{format(`$,.${symbol.pricePrecision}f`)(item.price)}
									</Descriptions.Item>
								);
							})}
						</Descriptions>
					</Col>
				</Row>
			</Card>
		</div>
	);
};
