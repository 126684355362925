import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Space, Card, message, Tabs } from 'antd';
import publicIp from 'public-ip';
import { format } from 'd3-format';

import UpbitSetting from './upbitSetting';
import BinanceSetting from './binanceSetting';

import * as coinAPI from '../../api/coin';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { span: 24 },
	style: { textAlign: 'center' }
};
const { TabPane } = Tabs;

export default ({
	upbitTicker,
	binanceTicker,
	handleSignOut,
	handleGetExchangeRate,
	handleSetExchangeRate,
	exchangeRate,
	handleSetUpbitSetting,
	upbitSetting,
	handleGetUpbitWallet,
	upbitWallet,
	symbols,
	futuresSymbols,
	coinmSymbols,
	handleSetBinanceSetting,
	binanceSetting,
	handleGetBinanceWallet,
	handleSendFuturesPositionsToTelegramBot,
	handleSendFuturesTriggersToTelegramBot,
	binanceWallet,
	handleGetBinanceFuturesWallet,
	binanceFuturesWallet,
	handleGetBinanceCoinmWallet,
	binanceCoinmWallet,
	handleBinanceLeverage,
	handleBinanceMarginType,
	handleBinancePositionSide,
	baseUrl,
	auth
}) => {
	const [ipAddress, setIpAddress] = useState('');
	const per30Days = 300000;
	const per90Days = Math.floor((per30Days * 3 * 0.92) / 1000) * 1000;
	const per180Days = Math.floor((per30Days * 6 * 0.84) / 1000) * 1000;
	const per1Year = Math.floor((per30Days * 12 * 0.74) / 10000) * 10000;
	const per2Year = Math.floor((per30Days * 24 * 0.62) / 10000) * 10000;
	const per3Year = Math.floor((per30Days * 36 * 0.48) / 10000) * 10000;

	const [settingForm] = Form.useForm();

	const handleGetIpAddress = () => {
		publicIp.v4().then((result) => {
			message.success('아이피 주소를 가져왔습니다.');
			setIpAddress(result);
		});
	};

	const handleServerRestart = () => {
		coinAPI.serverRestart({ auth, baseUrl });
	};

	const handleServerReboot = () => {
		coinAPI.serverReboot({ auth, baseUrl });
	};

	return (
		<Tabs defaultActiveKey="basic">
			<TabPane tab="기본 설정" key="basic">
				<Space direction="vertical" style={{ width: '100%' }}>
					<Card title="설정" size="small" style={{ width: '100%' }}>
						<Form {...layout} form={settingForm} initialValues={{ exchangeRate: 1000 }}>
							{/* <Form.Item label="My IP">
										<span className="ant-form-text">{ipAddress || '0.0.0.0'}</span>
									</Form.Item> */}
							<Form.Item label="환율">
								<span className="ant-form-text">{`₩${format(',.2f')(exchangeRate || 1000)}`}</span>
							</Form.Item>
							<Form.Item label="환율 직접입력" name="exchangeRate">
								<Input style={{ width: 150 }} prefix="₩" />
							</Form.Item>
							<Form.Item {...tailLayout}>
								{/* <Button htmlType="button" style={{ marginRight: '8px' }} onClick={() => handleGetIpAddress()}>
									Get my IP
								</Button> */}
								<Button htmlType="button" style={{ marginRight: '8px' }} onClick={() => handleSetExchangeRate(settingForm.getFieldValue('exchangeRate'))}>
									환율 변경하기
								</Button>
								<Button htmlType="button" onClick={() => handleGetExchangeRate()}>
									환율 가져오기
								</Button>
							</Form.Item>
						</Form>
					</Card>
					<Card title="서버" size="small" style={{ width: '100%' }}>
						<Button type="primary" style={{ marginRight: '8px' }} htmlType="button" onClick={() => handleServerRestart()}>
							재시작
						</Button>
						{/* <Button danger htmlType="button" onClick={() => handleServerReboot()}>
							Reboot
						</Button> */}
					</Card>
					<Card title="계정" size="small" style={{ width: '100%' }}>
						<Button danger htmlType="button" onClick={() => handleSignOut()}>
							로그아웃
						</Button>
					</Card>
				</Space>
			</TabPane>
			<TabPane tab="업빗 설정" key="upbit">
				<UpbitSetting handleSetUpbitSetting={handleSetUpbitSetting} upbitSetting={upbitSetting} handleGetUpbitWallet={handleGetUpbitWallet} upbitWallet={upbitWallet} baseUrl={baseUrl} auth={auth} />
			</TabPane>
			<TabPane tab="바낸 설정" key="binance">
				<BinanceSetting
					symbols={symbols}
					futuresSymbols={futuresSymbols}
					coinmSymbols={coinmSymbols}
					binanceTicker={binanceTicker}
					handleSetBinanceSetting={handleSetBinanceSetting}
					binanceSetting={binanceSetting}
					handleGetBinanceWallet={handleGetBinanceWallet}
					binanceWallet={binanceWallet}
					handleGetBinanceFuturesWallet={handleGetBinanceFuturesWallet}
					handleSendFuturesPositionsToTelegramBot={handleSendFuturesPositionsToTelegramBot}
					handleSendFuturesTriggersToTelegramBot={handleSendFuturesTriggersToTelegramBot}
					handleGetBinanceCoinmWallet={handleGetBinanceCoinmWallet}
					binanceCoinmWallet={binanceCoinmWallet}
					binanceFuturesWallet={binanceFuturesWallet}
					handleBinanceLeverage={handleBinanceLeverage}
					handleBinanceMarginType={handleBinanceMarginType}
					handleBinancePositionSide={handleBinancePositionSide}
					baseUrl={baseUrl}
					auth={auth}
				/>
			</TabPane>
			<TabPane tab="프리미엄 사용" key="premium">
				<Space direction="vertical" style={{ width: '100%' }}>
					<Card title="관리자 연락처" size="small" style={{ width: '100%' }}>
						<Form.Item label="텔레그램">
							<span className="ant-form-text">@makeitcount81</span>
						</Form.Item>
						<Form.Item label="이메일">
							<span className="ant-form-text">young.kr82@gmail.com</span>
						</Form.Item>
					</Card>
					<Card title="아래 계좌로 송금인 이름에 '코라밸 아이디' 를 적어서 보내주세요." size="small" style={{ width: '100%' }}>
						<Form {...layout}>
							<Form.Item label="은행명">
								<span className="ant-form-text">케이뱅크</span>
							</Form.Item>
							<Form.Item label="계좌번호">
								<span className="ant-form-text">100188370170</span>
							</Form.Item>
							<Form.Item label="예금주명">
								<span className="ant-form-text">정종영</span>
							</Form.Item>
							<Form.Item label="30일 프리미엄">
								<span className="ant-form-text">{`₩${format(',.0f')(per30Days)}`}</span>
							</Form.Item>
							<Form.Item label="90일 프리미엄">
								<span className="ant-form-text">{`₩${format(',.0f')(per90Days)}`}</span>
							</Form.Item>
							<Form.Item label="180일 프리미엄">
								<span className="ant-form-text">{`₩${format(',.0f')(per180Days)}`}</span>
							</Form.Item>
							{/* <Form.Item label="1 year Premium">
								<span className="ant-form-text">{`₩${format(',.0f')(per1Year)}`}</span>
							</Form.Item>
							<Form.Item label="2 years Premium">
								<span className="ant-form-text">{`₩${format(',.0f')(per2Year)}`}</span>
							</Form.Item>
							<Form.Item label="3 years Premium">
								<span className="ant-form-text">{`₩${format(',.0f')(per3Year)}`}</span>
							</Form.Item> */}
						</Form>
					</Card>
					{/* <Card title="OPTION 2: Send XRP to address below and email me or use memo function in Upbit the your username." size="small" style={{ width: '100%' }}>
						<Form {...layout}>
							<Form.Item label="XRP address">
								<span className="ant-form-text">raQwCVAJVqjrVm1Nj5SFRcX8i22BhdC9WA</span>
							</Form.Item>
							<Form.Item label="XRP tag">
								<span className="ant-form-text">2575411886</span>
							</Form.Item>
							<Form.Item label="30 days Premium">
								<span className="ant-form-text">{`${format(',.2f')(per30Days / (upbitTicker && upbitTicker['KRW-XRP'] ? upbitTicker['KRW-XRP'] : 1000))} XRP (about ₩${format(',.0f')(per30Days)})`}</span>
							</Form.Item>
							<Form.Item label="90 days Premium">
								<span className="ant-form-text">{`${format(',.2f')(per90Days / (upbitTicker && upbitTicker['KRW-XRP'] ? upbitTicker['KRW-XRP'] : 1000))} XRP (about ₩${format(',.0f')(per90Days)})`}</span>
							</Form.Item>
							<Form.Item label="180 days Premium">
								<span className="ant-form-text">{`${format(',.2f')(per180Days / (upbitTicker && upbitTicker['KRW-XRP'] ? upbitTicker['KRW-XRP'] : 1000))} XRP (about ₩${format(',.0f')(per180Days)})`}</span>
							</Form.Item>
							<Form.Item label="1 year Premium">
								<span className="ant-form-text">{`${format(',.2f')(per1Year / (upbitTicker && upbitTicker['KRW-XRP'] ? upbitTicker['KRW-XRP'] : 1000))} XRP (about ₩${format(',.0f')(per1Year)})`}</span>
							</Form.Item>
							<Form.Item label="2 years Premium">
								<span className="ant-form-text">{`${format(',.2f')(per2Year / (upbitTicker && upbitTicker['KRW-XRP'] ? upbitTicker['KRW-XRP'] : 1000))} XRP (about ₩${format(',.0f')(per2Year)})`}</span>
							</Form.Item>
							<Form.Item label="3 years Premium">
								<span className="ant-form-text">{`${format(',.2f')(per3Year / (upbitTicker && upbitTicker['KRW-XRP'] ? upbitTicker['KRW-XRP'] : 1000))} XRP (about ₩${format(',.0f')(per3Year)})`}</span>
							</Form.Item>
						</Form>
					</Card> */}
				</Space>
			</TabPane>
		</Tabs>
	);
};
