import React from 'react';
import { Form, Input, Button, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const NewPassword = ({ onNewPassword, onToggleSignInUp, username }) => {
	return (
		<>
			<Form name="newPassword" className="login-form" initialValues={{ username }} onFinish={onNewPassword}>
				<Form.Item name="username" rules={[{ required: true, message: '아이디를 입력해주세요!' }]}>
					<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="아이디" type="text" />
				</Form.Item>
				<Form.Item name="code" rules={[{ required: true, message: '인증코드를 입력해주세요!' }]}>
					<Input prefix={<LockOutlined className="site-form-item-icon" />} type="text" placeholder="인증코드" />
				</Form.Item>
				<Form.Item
					name="password"
					rules={[
						{
							validator: (_, value) => (value.length < 8 ? Promise.reject(new Error('8글자 이상 필요')) : !/[A-Z]/.test(value) ? Promise.reject(new Error('1개 이상의 대문자 필요')) : !/[a-z]/.test(value) ? Promise.reject(new Error('1개 이상의 소문자 필요')) : !/\d/.test(value) ? Promise.reject(new Error('1개 이상의 숫자 필요')) : Promise.resolve())
						},
						{ required: true, message: '비밀번호를 입력해주세요!' }
					]}
				>
					<Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="비밀번호" />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" className="login-form-button">
						확인
					</Button>
					<Divider />
					<a href="#signin" onClick={() => onToggleSignInUp('signIn')}>
						로그인하기
					</a>
				</Form.Item>
			</Form>
		</>
	);
};

export default NewPassword;
