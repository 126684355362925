import React, { useState, useEffect } from 'react';
import { format } from 'd3-format';
import { Input, Col, Row, Button, Form, InputNumber, Card, Descriptions, Switch } from 'antd';

import { getUpbitStep, getBinanceStep } from '../../hooks/useLibrary';
import * as coinAPI from '../../api/coin';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};

export default ({ auth, code, symbols, upbitPrice, binancePrice, order, trigger, exchangeRate, handlePremiumAddOrders, handelDeletePremiumOrder, handlePremiumAddTriger, handelDeletePremiumTrigger }) => {
	const [orderForm] = Form.useForm();
	const { step, premiumStep } = getUpbitStep(upbitPrice);
	const { priceStep, pricePrecision, quantityStep, quantityPrecision } = getBinanceStep(symbols.find((x) => x.symbol === code + 'USDT'));
	const _step = order ? premiumStep * -1 : premiumStep;
	const premium = ((upbitPrice + _step) / exchangeRate - (binancePrice - _step / exchangeRate)) / (binancePrice - _step / exchangeRate);
	let color = '#d9d9d9';
	if (premium >= 0.1) color = '#f5222d';
	else if (premium < 0.1 && premium >= 0.075) color = '#ff4d4f';
	else if (premium < 0.075 && premium >= 0.05) color = '#ff7875';
	else if (premium < 0.05 && premium >= 0.025) color = '#ffa39e';
	else if (premium < 0.025 && premium > 0) color = '#ffccc7';
	else if (premium < 0 && premium >= -0.025) color = '#d6e4ff';
	else if (premium < -0.025 && premium >= -0.05) color = '#adc6ff';
	else if (premium < -0.05 && premium >= -0.075) color = '#85a5ff';
	else if (premium < -0.075 && premium >= -0.1) color = '#597ef7';
	else if (premium < -0.1) color = '#2f54eb';

	const handlePremiumOrder = () => {
		handlePremiumAddOrders({
			upbit: {
				market: { cd: 'KRW-' + code, tp: upbitPrice, step, premiumStep },
				side: order ? 'ask' : 'bid',
				orderCount: 1,
				tradeStartPrice: upbitPrice + _step,
				tradeStep: 0,
				tradeQuantity: order ? order.upbit : Number(Number(orderForm.getFieldValue('premiumAmount') / upbitPrice).toFixed(8))
			},
			binance: {
				symbol: { s: code + 'USDT', c: binancePrice, priceStep, pricePrecision, quantityStep, quantityPrecision },
				side: order ? 'BUY' : 'SELL',
				positionSide: 'SHORT',
				orderCount: 1,
				tradeStartPrice: binancePrice - _step / exchangeRate,
				tradeStep: 0,
				tradeQuantity: order ? order.binance : Number(orderForm.getFieldValue('premiumAmount') / (binancePrice * exchangeRate)).toFixed(quantityPrecision)
			},
			code,
			premium,
			amount: orderForm.getFieldValue('premiumAmount'),
			order
		});
	};

	const handlePremiumTrigger = () => {
		handlePremiumAddTriger({
			code,
			premium,
			targetProfit: orderForm.getFieldValue('targetProfit'),
			exchangeRate,
			upbit: {
				market: { cd: 'KRW-' + code, tp: upbitPrice, step, premiumStep },
				tradeQuantity: order.upbit
			},
			binance: {
				symbol: { s: code + 'USDT', c: binancePrice, priceStep, pricePrecision, quantityStep, quantityPrecision },
				tradeQuantity: order.binance
			}
		});
	};

	return (
		<Col span={12}>
			<Form {...layout} form={orderForm} initialValues={{ premiumAmount: order ? order.amount : 100000, targetProfit: trigger ? trigger.targetProfit : 0.003 }}>
				<Descriptions bordered size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
					<Descriptions.Item label={code} contentStyle={{ textAlign: 'right', color, fontSize: '1.5em' }}>
						{`${format(',.2%')(premium)}`}
					</Descriptions.Item>
					{(auth.attributes['custom:premium'] === 'true') && (
						<>
							<Descriptions.Item label={`${format(',.2%')(order ? order.premium : 0)}`} contentStyle={order ? (premium > order.premium + 0.002 ? { textAlign: 'right', color: '#85a5ff', fontSize: '1.5em' } : { textAlign: 'right', color: '#ff4d4f', fontSize: '1.5em' }) : { textAlign: 'right', color: '#1f1f1f' }}>
								{`${format(',.2%')(order ? premium - order.premium - 0.002 : 0)}`}
							</Descriptions.Item>
							<Descriptions.Item label="Order" span={2}>
								<Form.Item name="premiumAmount" noStyle>
									<InputNumber style={{ width: '150px', marginRight: '20px' }} min={10000} max={100000000} step={10000} formatter={(value) => '₩' + value} parser={(value) => value.replace('₩', '')} />
								</Form.Item>
								<Form.Item noStyle>
									{order ? (
										<>
											<Button htmlType="button" style={{ color: '#1f1f1f', background: '#cf304a', borderColor: '#cf304a', marginRight: '8px' }} onClick={() => handlePremiumOrder()}>
												Close
											</Button>
											<Button htmlType="button" onClick={() => handelDeletePremiumOrder(code)}>
												Clear
											</Button>
										</>
									) : (
										<>
											<Button htmlType="button" style={{ color: '#1f1f1f', background: '#03a66d', borderColor: '#03a66d', marginRight: '8px' }} onClick={() => handlePremiumOrder()}>
												Open
											</Button>
											<Button htmlType="button" disabled>
												Clear
											</Button>
										</>
									)}
								</Form.Item>
							</Descriptions.Item>
							<Descriptions.Item label="Trigger" span={2}>
								<Form.Item name="targetProfit" noStyle>
									<InputNumber style={{ width: '120px', marginRight: '20px' }} min={0.001} max={0.5} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
								</Form.Item>
								<Form.Item noStyle>
									{order ? (
										<Button htmlType="button" type="primary" style={{ marginRight: '8px' }} onClick={() => handlePremiumTrigger()}>
											Set
										</Button>
									) : (
										<Button htmlType="button" type="primary" disabled>
											Set
										</Button>
									)}
									{trigger ? (
										<Button htmlType="button" danger style={{ marginRight: '8px' }} onClick={() => handelDeletePremiumTrigger(code)}>
											Clear
										</Button>
									) : (
										<Button htmlType="button" danger disabled>
											Clear
										</Button>
									)}
								</Form.Item>
							</Descriptions.Item>
						</>
					)}
				</Descriptions>
			</Form>
		</Col>
	);
};
