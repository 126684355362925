import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { Form, Input, Button, Row, Col, Space, Card, InputNumber, Radio, Select, Switch, Divider, Descriptions, DatePicker } from 'antd';
import { format } from 'd3-format';
import { timeFormat, timeParse } from 'd3-time-format';
import { ChartCanvas, Chart } from 'react-stockcharts';
import { CandlestickSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { EdgeIndicator } from 'react-stockcharts/lib/coordinates';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { Label, Annotate, SvgPathAnnotation, LabelAnnotation, buyPath, sellPath } from 'react-stockcharts/lib/annotation';

import { getUpbitMarketsAction } from '../modules/coin';
import { getUpbitStep } from '../hooks/useLibrary';
import useInterval from '../hooks/useInterval';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { span: 24 }
};

const buy = {
	y: ({ yScale, datum }) => yScale(datum.low),
	fill: '#ff4d4f',
	path: buyPath,
	tooltip: 'Buy'
};
const sell = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fill: '#1890ff',
	path: sellPath,
	tooltip: 'Sell'
};

const finish = {
	y: ({ yScale, datum }) => yScale(datum.high),
	fontSize: 23,
	fill: '#1890ff',
	text: '₩'
};

const currencyFormat = (value) => new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW', notation: 'compact', compactDisplay: 'short', maximumSignificantDigits: 4 }).format(Number(value));

const getUpbitCandle = async (to, market) => {
	return await axios
		.get('https://api.upbit.com/v1/candles/minutes/1', {
			params: { market, to, count: 180 }
		})
		.then((response) => response.data)
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export default () => {
	const { markets } = useSelector(({ coinReducer }) => ({
		markets: coinReducer.markets.data
	}));
	const dispatch = useDispatch();

	const defaultViewData = {
		wallet: {
			startPrice: 0,
			startBalance: 0,
			startAsset: 0,
			averagePrice: 0,
			balance: 0,
			autoTradeProfit: 0,
			autoTradeCount: 0,
			asset: 0,
			availableAsset: 0
		},
		orders: [],
		candleData: [],
		chartSourceData: [],
		chartData: { data: [], xScale: null, xAccessor: null, displayXAccessor: null, start: 0, end: 0, xExtents: [] },
		currentPrice: 0,
		startBudget: 0,
		startAmount: 0,
		diffStartPrice: 0,
		diffStartAmount: 0,
		averageBudget: 0,
		averageAmount: 0,
		diffAveragePrice: 0,
		diffAverageAmount: 0,
		realAveragePrice: 0,
		realAverageAmount: 0,
		openPrice: 0,
		closePrice: 0,
		highPrice: 0,
		lowPrice: 0
	};

	const [market, setMarket] = useState({});
	const [startTime, setStartTime] = useState({
		now: moment().subtract(7, 'days').startOf('day'),
		from: moment().subtract(7, 'days').startOf('day')
	});
	const [viewData, setViewData] = useState(defaultViewData);
	const [isTimerRunning, setIsTimerRunning] = useState(false);

	useEffect(async () => {
		dispatch(getUpbitMarketsAction());
	}, []);

	useInterval(
		async () => {
			if (viewData?.candleData?.length) {
				let ticker = {
					...viewData.candleData[0],
					averagePrice: viewData.wallet.averagePrice,
					realAveragePrice: (viewData.startBudget - viewData.wallet.autoTradeProfit) / viewData.wallet.balance || 0,
					annotate: '',
					count: 0
				};
				let wallet = { ...viewData.wallet };
				let orders = [...viewData.orders];

				if (orders.length === 0) {
					setIsTimerRunning(false);

					handleMarket(market.cd, ticker.close);

					for (let i = 0; i < viewData.orderCount; i++) {
						orders.push({
							side: 'bid',
							price: Number((viewData.tradeStartPrice - i * viewData.tradeStep).toFixed(market.pricePrecision || 2)),
							quantity: viewData.tradeQuantity,
							buyPrice: Number((viewData.tradeStartPrice - i * viewData.tradeStep).toFixed(market.pricePrecision || 2))
						});
						wallet.startAsset += Number((viewData.tradeStartPrice - i * viewData.tradeStep).toFixed(market.pricePrecision || 2)) * viewData.tradeQuantity;
					}

					wallet.asset = wallet.startAsset;

					const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => timeParse('%Y/%m/%d %H:%M:%S')(moment(d.timeStamp).local().format('YYYY/MM/DD HH:mm:ss')));
					const { data, xScale, xAccessor, displayXAccessor } = await xScaleProvider([...viewData.chartSourceData]);
					const start = data.length;
					const end = data.length >= 300 ? data.length - 300 : 0;
					const xExtents = [start, end];

					setViewData((prevState) => {
						return {
							...prevState,
							wallet,
							orders,
							chartSourceData: [...(prevState.chartSourceData.length > 600 ? prevState.chartSourceData.splice(1) : prevState.chartSourceData), ticker],
							chartData: { data, xScale, xAccessor, displayXAccessor, start, end, xExtents },
							currentPrice: ticker.close,
							startAmount: ticker.close * prevState.wallet.startBalance,
							diffStartPrice: ticker.close - prevState.wallet.startPrice,
							diffStartAmount: (ticker.close - prevState.wallet.startPrice) * prevState.wallet.startBalance,
							averageBudget: prevState.wallet.averagePrice * prevState.wallet.balance,
							averageAmount: ticker.close * prevState.wallet.balance,
							diffAveragePrice: ticker.close - prevState.wallet.averagePrice,
							diffAverageAmount: (ticker.close - prevState.wallet.averagePrice) * prevState.wallet.balance,
							realAveragePrice: (prevState.startBudget + prevState.wallet.startAsset - prevState.wallet.asset) / prevState.wallet.balance,
							realAverageAmount: prevState.startBudget + prevState.wallet.startAsset - prevState.wallet.asset,
							openPrice: ticker.close,
							closePrice: ticker.close,
							highPrice: ticker.close,
							lowPrice: ticker.close
						};
					});

					setIsTimerRunning(true);
				} else {
					// setIsTimerRunning(false);

					for (let _candelData of viewData.candleData) {
						ticker = {
							...ticker,
							timeStamp: _candelData.timeStamp,
							high: ticker.high < _candelData.high ? _candelData.high : ticker.high,
							low: ticker.low > _candelData.low ? _candelData.low : ticker.low,
							close: _candelData.close,
							count: ticker.count + 1
						};

						const processOrders = orders?.filter((x) => (x.side === 'bid' && x.price >= ticker.close) || (x.side === 'ask' && x.price <= ticker.close));

						if (processOrders.length) {
							const remainOrders = orders.filter((x) => (x.side === 'bid' && x.price < ticker.close) || (x.side === 'ask' && x.price > ticker.close));

							for (const processOrder of processOrders) {
								if (processOrder.side === 'bid') {
									wallet.autoTradeProfit += processOrder.price * processOrder.quantity * -0.0005;
									wallet.averagePrice = (wallet.averagePrice * wallet.balance + processOrder.price * processOrder.quantity * 1.0005) / (wallet.balance + processOrder.quantity);
									wallet.balance += processOrder.quantity;
									wallet.asset -= processOrder.price * processOrder.quantity * 1.0005;
									wallet.availableAsset -= processOrder.price * processOrder.quantity * 0.0005;
									ticker.annotate += 'buy';
								} else {
									wallet.autoTradeProfit += processOrder.buyPrice ? (processOrder.price - processOrder.buyPrice) * processOrder.quantity - processOrder.price * processOrder.quantity * 0.0005 : 0;
									wallet.balance -= processOrder.quantity;
									wallet.asset += processOrder.price * processOrder.quantity * 0.9995;
									wallet.availableAsset += processOrder.price * processOrder.quantity * 0.9995;
									wallet.autoTradeCount = wallet.autoTradeCount + 1;
									ticker.annotate += 'sell';
								}

								const orderPrice = processOrder.price + viewData.tradeStep * (processOrder.side === 'bid' ? 2 : -2);

								remainOrders.push({
									side: processOrder.side === 'bid' ? 'ask' : 'bid',
									price: Number(orderPrice.toFixed(market.pricePrecision || 2)),
									quantity: processOrder.quantity,
									buyPrice: processOrder.side === 'bid' ? processOrder.price : 0
								});

								if (processOrder.side === 'ask') {
									wallet.availableAsset -= Number(orderPrice.toFixed(market.pricePrecision || 2)) * processOrder.quantity;
								}
							}

							orders = [...remainOrders];
							orders.sort((a, b) => (Math.abs(ticker.close - a.price) < Math.abs(ticker.close - b.price) ? -1 : 1));
						}

						if (((ticker.close - wallet.averagePrice) * wallet.balance + wallet.autoTradeProfit) / (wallet.averagePrice * wallet.balance) > wallet.targetRoe) {
							wallet.autoTradeProfit += (ticker.close - wallet.averagePrice) * wallet.balance * 0.9995;
							wallet.balance = 0;
							ticker.annotate += 'finish';
							orders = [];
						}

						if (ticker.count === 60) break;
					}

					const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => timeParse('%Y/%m/%d %H:%M:%S')(moment(d.timeStamp).local().format('YYYY/MM/DD HH:mm:ss')));
					const { data, xScale, xAccessor, displayXAccessor } = await xScaleProvider([...viewData.chartSourceData]);
					const start = data.length;
					const end = data.length >= 300 ? data.length - 300 : 0;
					const xExtents = [start, end];

					setViewData((prevState) => {
						return {
							...prevState,
							wallet: {
								...prevState.wallet,
								...wallet,
								averageBudget: wallet.averagePrice * wallet.balance
							},
							orders,
							candleData: [...prevState.candleData.splice(ticker.count)],
							chartSourceData: [...(prevState.chartSourceData.length > 600 ? prevState.chartSourceData.splice(1) : prevState.chartSourceData), ticker],
							chartData: { data, xScale, xAccessor, displayXAccessor, start, end, xExtents },
							currentPrice: ticker.close,
							startAmount: ticker.close * prevState.wallet.startBalance,
							diffStartPrice: ticker.close - prevState.wallet.startPrice,
							diffStartAmount: (ticker.close - prevState.wallet.startPrice) * prevState.wallet.startBalance,
							averageBudget: wallet.averagePrice * wallet.balance,
							averageAmount: ticker.close * wallet.balance,
							diffAveragePrice: ticker.close - wallet.averagePrice,
							diffAverageAmount: (ticker.close - wallet.averagePrice) * wallet.balance,
							realAveragePrice: (prevState.startBudget + prevState.wallet.startAsset - wallet.asset) / wallet.balance,
							realAverageAmount: prevState.startBudget + prevState.wallet.startAsset - wallet.asset,
							closePrice: ticker.close,
							highPrice: prevState.highPrice < ticker.high ? ticker.high : prevState.highPrice,
							lowPrice: prevState.lowPrice > ticker.low ? ticker.low : prevState.lowPrice
						};
					});

					// setIsTimerRunning(true);
				}
			} else {
				if (market.cd && startTime.now.diff(moment(), 'hours') < 0) {
					setIsTimerRunning(false);

					const candles = await getUpbitCandle(startTime.now.format(), market.cd);
					const forChart = [];

					for (const candle of candles) {
						forChart.unshift({
							timeStamp: candle.candle_date_time_kst,
							open: candle.opening_price,
							high: candle.high_price,
							low: candle.low_price,
							close: candle.trade_price,
							averagePrice: 0,
							annotate: ''
						});
					}

					setStartTime((prevState) => {
						return {
							from: prevState.from,
							now: prevState.now.add(3, 'hours')
						};
					});
					setViewData((prevState) => {
						return {
							...prevState,
							candleData: forChart
						};
					});

					setIsTimerRunning(true);
				} else {
					setIsTimerRunning(false);
				}
			}
		},
		isTimerRunning ? 20 : null
	);

	const handleMarket = (value, price) => {
		const { step, upperStep, ps, pricePrecision } = getUpbitStep(price);
		setMarket({ cd: value, tp: price, step, upperStep, ps, pricePrecision });
	};

	const handleSimulator = (values, flag) => {
		if (flag) {
			setViewData((prevState) => {
				return {
					...prevState,
					wallet: {
						startPrice: Number(values.averagePrice),
						startBalance: Number(values.balance),
						startAsset: 0,
						averagePrice: Number(values.averagePrice),
						balance: Number(values.balance),
						autoTradeProfit: 0,
						autoTradeCount: 0,
						asset: 0,
						availableAsset: 0
					},
					currentPrice: 0,
					startBudget: Number(values.averagePrice) * Number(values.balance),
					startAmount: 0,
					diffStartPrice: 0,
					diffStartAmount: 0,
					averageBudget: Number(values.averagePrice) * Number(values.balance),
					averageAmount: 0,
					diffAveragePrice: 0,
					diffAverageAmount: 0,
					realAveragePrice: 0,
					realAverageAmount: 0,
					tradeQuantity: Number(values.tradeQuantity),
					orderCount: values.orderCount,
					tradeStartPrice: Number(values.tradeStartPrice),
					tradeStep: Number(values.tradeStep)
				};
			});
			setIsTimerRunning(true);
		} else {
			setIsTimerRunning(false);
		}
	};

	return (
		<div style={{ maxWidth: '1400px', margin: '0 auto' }}>
			<Card title="코라밸 업비트 시뮬레이터" size="small" style={{ width: '100%', textAlign: 'center' }}>
				<Form {...layout} name="simulator" initialValues={{ market: market.cd, startTime: startTime.from, averagePrice: '0', balance: '0', tradeQuantity: '0', orderCount: 0, tradeStartPrice: '0', tradeStep: '0' }} onFinish={(e) => handleSimulator(e, market.cd && !isTimerRunning)}>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={6}>
							<Form.Item name="market" label="코인">
								<Select showSearch style={{ width: 200 }} placeholder="코인을 선택해주세요." optionFilterProp="children" value={market.cd} onChange={(e) => handleMarket(e, 1000)} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
									{useMemo(
										() =>
											markets &&
											markets.map((item, i) => (
												<Select.Option key={item.market} value={item.market}>
													{item.market.replace('KRW-', '')}
												</Select.Option>
											)),
										[markets]
									)}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="averagePrice" label="매수 평균가">
								<Input suffix="KRW" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="tradeQuantity" label="주문당 코인수">
								<Input suffix={market.cd ? market.cd.replace('KRW-', '') : '코인'} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="orderCount" label="주문수">
								<InputNumber min={1} max={100} step={1} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="startTime" label="시작 시간">
								<DatePicker format="YYYY-MM-DD HH" showTime={{ defaultValue: moment('00:00:00', 'HH') }} onChange={(e) => setStartTime({ now: moment(e).startOf('day'), from: moment(e).startOf('day') })} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="balance" label="보유 수량">
								<Input suffix={market.cd ? market.cd.replace('KRW-', '') : '코인'} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="tradeStartPrice" label="시작가격">
								<Input suffix="KRW" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="tradeStep" label="주문간 가격차">
								<Input suffix="KRW" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col span={24}>
							<Form.Item {...tailLayout}>
								<Button type={isTimerRunning ? 'danger' : 'primary'} htmlType="submit" style={{ marginRight: '8px' }}>
									{isTimerRunning ? '시뮬레이션 중지' : '시뮬레이션 시작'}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Card>
			<Card title="트레이딩 뷰" size="small" style={{ width: '100%', textAlign: 'center' }}>
				{viewData?.chartData?.data?.length > 1 && (
					<ChartCanvas ratio={2} width={1200} height={400} margin={{ left: 90, right: 90, top: 70, bottom: 30 }} type={'hybrid'} seriesName="MSFT" data={viewData.chartData.data} xScale={viewData.chartData.xScale} xAccessor={viewData.chartData.xAccessor} displayXAccessor={viewData.chartData.displayXAccessor} xExtents={viewData.chartData.xExtents}>
						<Chart id={1} yExtents={(d) => [d.high, d.low]} padding={{ top: 10, bottom: 20 }}>
							<XAxis axisAt="bottom" orient="bottom" />
							<YAxis axisAt="right" orient="right" ticks={5} tickFormat={currencyFormat} />
							<CandlestickSeries />
							<EdgeIndicator itemType="last" orient="right" edgeAt="right" yAccessor={(d) => d.close} fill={'#f79437'} />
							<EdgeIndicator itemType="last" orient="left" edgeAt="left" yAccessor={(d) => d.averagePrice} fill={'#03a66d'} />
							<EdgeIndicator itemType="last" orient="left" edgeAt="left" yAccessor={(d) => d.realAveragePrice} fill={'#1890ff'} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('buy') !== -1} usingProps={buy} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('sell') !== -1} usingProps={sell} />
							<Annotate with={SvgPathAnnotation} when={(d) => d.annotate.indexOf('finish') !== -1} usingProps={finish} />
						</Chart>
					</ChartCanvas>
				)}
			</Card>
			<Card title={`수익 및 자산 변화 | 현재 시간: ${moment(viewData?.candleData[0]?.timeStamp).local().format('YYYY/MM/DD HH:mm')}`} size="small" style={{ width: '100%', textAlign: 'center' }}>
				<Descriptions bordered size="small" column={{ xs: 2, xl: 6 }}>
					<Descriptions.Item label="시작" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`${startTime?.from?.format('YYYY/MM/DD HH')}`}
					</Descriptions.Item>
					<Descriptions.Item label="종료" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{`${moment(viewData?.candleData[0]?.timeStamp).local().format('YYYY/MM/DD HH')}`}
					</Descriptions.Item>
					<Descriptions.Item label="시가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW', minimumFractionDigits: 0, maximumFractionDigits: market?.pricePrecision || 0 }).format(viewData?.openPrice || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="종가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW', minimumFractionDigits: 0, maximumFractionDigits: market?.pricePrecision || 0 }).format(viewData?.closePrice || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="고가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW', minimumFractionDigits: 0, maximumFractionDigits: market?.pricePrecision || 0 }).format(viewData?.highPrice || 0)}
					</Descriptions.Item>
					<Descriptions.Item label="저가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
						{new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW', minimumFractionDigits: 0, maximumFractionDigits: market?.pricePrecision || 0 }).format(viewData?.lowPrice || 0)}
					</Descriptions.Item>
				</Descriptions>
				<br></br>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col span={8}>
						<Descriptions title="물린 상태 방치" bordered size="small" column={{ xs: 1, xl: 1 }}>
							<Descriptions.Item label="보유수량" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{format(`,.3f`)(viewData.wallet.startBalance)}
							</Descriptions.Item>
							<Descriptions.Item label="매수평균가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.wallet.startPrice)}`}
							</Descriptions.Item>
							<Descriptions.Item label="매수금액" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.startBudget)}`}
							</Descriptions.Item>
							<Descriptions.Item label="평가금액" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: viewData.diffStartPrice >= 0 ? '#ff4d4f' : '#1890ff' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.startAmount)}`}
							</Descriptions.Item>
							<Descriptions.Item label="평가수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: viewData.diffStartPrice >= 0 ? '#ff4d4f' : '#1890ff' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.diffStartAmount)} (${format(',.2%')(viewData.diffStartAmount / viewData.startBudget || 0)})`}
							</Descriptions.Item>
							<Descriptions.Item label="보유 KRW" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.wallet.startAsset)}`}
							</Descriptions.Item>
							<Descriptions.Item label="주문가능금액" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.wallet.startAsset)}`}
							</Descriptions.Item>
							<Descriptions.Item label="총보유자산" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.startAmount + viewData.wallet.startAsset)}`}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col span={8}>
						<Descriptions title="코라밸 자동매매" bordered size="small" column={{ xs: 1, xl: 1 }}>
							<Descriptions.Item label="보유수량" labelStyle={{ padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: 'bold' }}>
								{format(`,.3f`)(viewData.wallet.balance)}
							</Descriptions.Item>
							<Descriptions.Item label="매수평균가" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: viewData.diffAveragePrice >= 0 ? '#ff4d4f' : '#1890ff' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.wallet.averagePrice)}`}
							</Descriptions.Item>
							<Descriptions.Item label="매수금액" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.averageBudget)}`}
							</Descriptions.Item>
							<Descriptions.Item label="평가금액" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.averageAmount)}`}
							</Descriptions.Item>
							<Descriptions.Item label="평가수익" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', color: viewData.diffAveragePrice >= 0 ? '#ff4d4f' : '#1890ff' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.diffAverageAmount)} (${format(',.2%')(viewData.diffAverageAmount / viewData.averageBudget || 0)})`}
							</Descriptions.Item>
							<Descriptions.Item label="보유 KRW" labelStyle={{ padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: 'bold' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.wallet.asset)}`}
							</Descriptions.Item>
							<Descriptions.Item label="주문가능금액" labelStyle={{ padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: 'bold' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.wallet.availableAsset)}`}
							</Descriptions.Item>
							<Descriptions.Item label="총보유자산" labelStyle={{ padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: 'bold' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.averageAmount + viewData.wallet.asset)}`}
							</Descriptions.Item>
							<Descriptions.Item label="실제매수평균가" labelStyle={{ padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: 'bold', color: viewData.currentPrice - viewData.realAveragePrice >= 0 ? '#ff4d4f' : '#1890ff' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.realAveragePrice)}`}
							</Descriptions.Item>
							<Descriptions.Item label="실제매수금액" labelStyle={{ padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: 'bold' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.realAverageAmount)}`}
							</Descriptions.Item>
							<Descriptions.Item label="실제평가수익" labelStyle={{ padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: 'bold', color: viewData.currentPrice - viewData.realAveragePrice >= 0 ? '#ff4d4f' : '#1890ff' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format((viewData.currentPrice - viewData.realAveragePrice) * viewData.wallet.balance)} (${format(',.2%')(((viewData.currentPrice - viewData.realAveragePrice) * viewData.wallet.balance) / viewData.realAverageAmount || 0)})`}
							</Descriptions.Item>
							<Descriptions.Item label="자동매매수익" labelStyle={{ padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'right', padding: '2px 16px', fontWeight: 'bold' }}>
								{`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(viewData.wallet.autoTradeProfit)}`}
							</Descriptions.Item>
							<Descriptions.Item label="자동매매횟수" labelStyle={{ padding: '2px 16px' }} contentStyle={{ textAlign: 'right', padding: '2px 16px' }}>
								{viewData.wallet.autoTradeCount}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col span={8}>
						<Descriptions title="주문" bordered size="small" column={{ xs: 1, xl: 1 }}>
							<Descriptions.Item label="주문가격" labelStyle={{ textAlign: 'center', padding: '2px 16px', fontWeight: 'bold' }} contentStyle={{ textAlign: 'center', padding: '2px 16px', fontWeight: 'bold' }}>
								주문개수
							</Descriptions.Item>
							{viewData?.orders?.map((item, key) => (
								<Descriptions.Item key={key} label={`${new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW', minimumFractionDigits: 0, maximumFractionDigits: market?.pricePrecision || 0 }).format(item.price)}`} labelStyle={{ textAlign: 'center', padding: '2px 16px', color: item.side === 'ask' ? '#1890ff' : '#ff4d4f' }} contentStyle={{ textAlign: 'center', padding: '2px 16px' }}>
									{new Intl.NumberFormat('ko-KR', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 8 }).format(item.quantity)}
								</Descriptions.Item>
							))}
						</Descriptions>
					</Col>
				</Row>
			</Card>
		</div>
	);
};
