import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Button, Row, Col, Space, Card, InputNumber, Radio, Select, message, Descriptions, Switch } from 'antd';
import { format } from 'd3-format';

import { getUpbitStep } from '../../hooks/useLibrary';
import * as coinAPI from '../../api/coin';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { offset: 8, span: 16 }
};

export default ({ auth, markets, upbitTicker, handleUpbitAddOrders, handleUpbitAddMarginTriger, upbitSetting, baseUrl, triggerValues, handelUpbitTriggerValue }) => {
	const [market, setMarket] = useState({});
	const [orderButtonStyle, setOrderButtonStype] = useState({ background: '#ff4d4f', borderColor: '#ff4d4f' });
	const [coinStatus, setCoinStatus] = useState({
		roofPrice: 0,
		floorPrice: 0,
		quantityPerOrder: 0,
		orderGap: 0,
		orderCount: 0,
		missingOrders: '',
		totalOrderCount: 0
	});
	const [mode, setMode] = useState(false);

	const [orderForm] = Form.useForm();
	const [marginForm] = Form.useForm();
	const [raceForm] = Form.useForm();

	useEffect(() => {
		orderForm.resetFields();
		setOrderButtonStype({ background: '#ff4d4f', borderColor: '#ff4d4f' });
		marginForm.resetFields();
		raceForm.resetFields();
		if (triggerValues) {
			if (triggerValues.type === 'race') {
				raceForm.setFieldsValue({
					targetProfit: triggerValues.targetProfit
				});
				setMode(true);
			} else {
				marginForm.setFieldsValue({
					tradeQuantity: triggerValues.tradeQuantity,
					buyQuantity: triggerValues.buyQuantity,
					bidStep: triggerValues.bidStep,
					askStep: triggerValues.askStep,
					stopBuy: triggerValues.stopBuy,
					stopSell: triggerValues.stopSell
				});
				setMode(false);
			}
		}
	}, [market]);

	useEffect(() => {
		if (triggerValues) {
			handleMarket(triggerValues.market, false);
		}
	}, [triggerValues]);

	const handleMarket = (value, triggerFlag) => {
		if (triggerFlag) {
			handelUpbitTriggerValue(null);
		}
		const { step, upperStep, ps, pricePrecision } = getUpbitStep(upbitTicker[value]);
		setMarket({ cd: value, tp: upbitTicker[value], step, upperStep, ps, pricePrecision });
		handleGetMarketOrders(value);
	};

	const handleOrderSide = (value) => {
		if (value === 'bid') {
			setOrderButtonStype({ background: '#ff4d4f', borderColor: '#ff4d4f' });
		} else {
			setOrderButtonStype({ background: '#1890ff', borderColor: '#1890ff' });
		}
	};

	const handelOrderForm = (value, values) => {
		let resourceKrw = 0;
		for (let i = 0; i < values.orderCount; i++) {
			resourceKrw += (values.tradeStartPrice - values.tradeStep * i) * values.tradeQuantity;
		}
		orderForm.setFieldsValue({
			tradeEndPrice: `₩${Number((values.tradeStartPrice + (values.orderCount - 1) * values.tradeStep * (values.side === 'ask' ? 1 : -1)).toFixed(market.pricePrecision || 2))}`,
			resource: values.side === 'ask' ? `${values.tradeQuantity * values.orderCount} ${market.cd ? market.cd.replace('KRW-', '') : '코인'}` : `₩${format(',.2f')(resourceKrw)}`
		});
	};

	const handelUpbitAddMarginTrigerForm = (value, values) => {
		marginForm.setFieldsValue({
			initAmount: format(',.0f')((values.initPrice || 0) * (values.initQuantity || 0))
		});
	};

	const handleGetMarketOrders = (marketCode) => {
		if (upbitSetting) {
			coinAPI.getUpbitOrders({ auth, baseUrl, ...upbitSetting, market: marketCode, uuids: [] }).then((response) => {
				if (response && response.orders && response.orders.length) {
					response.orders.sort((a, b) => Number(b.price) - Number(a.price));
					const orderGap = response.orders.length > 1 ? Number(response.orders[0].price) - Number(response.orders[1].price) : 0;
					let missingOrders = '';
					for (let i = 0, l = response.orders.length - 1; i < l; i++) {
						if ((Number(response.orders[i].price) - orderGap).toFixed(2) !== Number(response.orders[i + 1].price).toFixed(2)) {
							missingOrders += missingOrders === '' ? response.orders[i + 1].price : ', ' + response.orders[i + 1].price;
						}
					}

					setCoinStatus({
						roofPrice: Number(response.orders[0].price),
						floorPrice: Number(response.orders[response.orders.length - 1].price),
						quantityPerOrder: Number(response.orders[0].volume),
						orderGap,
						orderCount: response.orders.length,
						missingOrders,
						totalOrderCount: response.orderCount
					});
				} else {
					setCoinStatus({
						roofPrice: 0,
						floorPrice: 0,
						quantityPerOrder: 0,
						orderGap: 0,
						orderCount: 0,
						missingOrders: '',
						totalOrderCount: response ? response.orderCount || 0 : 0
					});
				}
			});
		} else message.warning('업빗 API 키를 입력해주세요.');
	};

	return (
		<>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col span={24}>
					<Form {...layout} name="upbit_setting" initialValues={{ market: market.cd }}>
						<Form.Item name="market" label="코인">
							<Select showSearch style={{ width: 200 }} placeholder="코인을 선택해주세요." optionFilterProp="children" value={market.cd} onChange={(e) => handleMarket(e, true)} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{useMemo(
									() =>
										markets &&
										markets.map((item, i) => (
											<Select.Option key={item.market} value={item.market}>
												{item.market.replace('KRW-', '')}
											</Select.Option>
										)),
									[markets]
								)}
							</Select>
						</Form.Item>
					</Form>
					<Card title={`${market.cd || '코인'} 상황`} size="small" style={{ width: '100%' }} extra={<span style={{ color: '#cf304a' }}>{`전체 주문수: ${coinStatus.totalOrderCount}`}</span>}>
						<Descriptions bordered size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
							<Descriptions.Item label="최고값" contentStyle={{ textAlign: 'right', color: '#ff4d4f' }}>
								{`₩${format(',.2f')(coinStatus.roofPrice)}`}
							</Descriptions.Item>
							<Descriptions.Item label="최저값" contentStyle={{ textAlign: 'right', color: '#1890ff' }}>
								{`₩${format(',.2f')(coinStatus.floorPrice)}`}
							</Descriptions.Item>
							<Descriptions.Item label="주문당 코인수" contentStyle={{ textAlign: 'center' }}>
								{`${format(',.5f')(coinStatus.quantityPerOrder)}`}
							</Descriptions.Item>
							<Descriptions.Item label="주문간 가격차" contentStyle={{ textAlign: 'right' }}>
								{`₩${format(',.2f')(coinStatus.orderGap)}`}
							</Descriptions.Item>
							<Descriptions.Item label="주문수" contentStyle={{ textAlign: 'center' }}>
								{coinStatus.orderCount}
							</Descriptions.Item>
							<Descriptions.Item label="빠진 주문가격" contentStyle={{ textAlign: 'center' }}>
								미지원
							</Descriptions.Item>
						</Descriptions>
					</Card>
				</Col>
			</Row>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" xs={24} xl={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Card title="일괄 주문" size="small" style={{ width: '100%', backgroundColor: '#adc6ff' }}>
							<Form {...layout} form={orderForm} initialValues={{ market: market.cd, side: 'bid', tradeQuantity: 0, orderCount: 1, tradeStartPrice: (market && market.tp) || 0, tradeStep: (market && market.ps) || 1 }} onValuesChange={(e, allValues) => handelOrderForm(e, allValues)} onFinish={(e) => handleUpbitAddOrders({ ...e, market })}>
								<Form.Item name="market" label="코인" rules={[{ required: true, message: '코인을 선택해주세요!' }]}>
									<Input disabled />
								</Form.Item>
								<Form.Item label="주문 구분" name="side" onChange={(e) => handleOrderSide(e.target.value)}>
									<Radio.Group buttonStyle="solid">
										<Radio.Button value="ask">매도</Radio.Button>
										<Radio.Button value="bid">매수</Radio.Button>
									</Radio.Group>
								</Form.Item>
								<Form.Item
									name="tradeQuantity"
									label="주문당 코인수"
									rules={[
										{
											validator: (_, value) => (isNaN(value) || Number(value) === 0 ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '코인수를 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix={market.cd ? market.cd.replace('KRW-', '') : '코인'} />
								</Form.Item>
								<Form.Item name="tradeStartPrice" label="시작가격">
									<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(market && market.step) || 0} formatter={(value) => '₩ ' + value} parser={(value) => value.replace('₩ ', '')} />
								</Form.Item>
								<Form.Item name="tradeEndPrice" label="종료가격">
									<Input style={{ width: 150 }} disabled />
								</Form.Item>
								<Form.Item name="tradeStep" label="주문간 가격차">
									<InputNumber style={{ width: 150 }} min={0} max={10000000} step={(market && market.step) || 0} formatter={(value) => '₩ ' + value} parser={(value) => value.replace('₩ ', '')} />
								</Form.Item>
								<Form.Item name="orderCount" label="주문수">
									<InputNumber min={1} max={500} step={1} />
								</Form.Item>
								<Form.Item name="resource" label="비용">
									<Input style={{ width: 150 }} disabled />
								</Form.Item>
								<Form.Item {...tailLayout}>
									<Button type="primary" htmlType="submit" style={orderButtonStyle}>
										주문
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Space>
				</Col>
				<Col className="gutter-row" xs={24} xl={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Card title="조건식 추가" size="small" style={{ width: '100%', backgroundColor: '#adc6ff' }} extra={<Switch style={{ marginRight: '8px' }} checked={mode} checkedChildren="경주마" unCheckedChildren="일반" onChange={(e) => setMode(e)} />}>
							<div style={{ display: mode ? 'none' : undefined }}>
								<Form {...layout} form={marginForm} initialValues={{ market: market.cd, tradeQuantity: 0, buyQuantity: 0, bidStep: (market && market.ps * 2) || 0, askStep: (market && market.ps * 2) || 0, stopBuy: 0, stopSell: 0, initPrice: 0, initQuantity: 0, initAmount: 0 }} onValuesChange={(e, allValues) => handelUpbitAddMarginTrigerForm(e, allValues)} onFinish={(e) => handleUpbitAddMarginTriger({ ...e, market, type: 'margin' })}>
									<Form.Item name="market" label="코인" rules={[{ required: true, message: '코인을 선택해주세요!' }]}>
										<Input disabled />
									</Form.Item>
									<Form.Item
										name="tradeQuantity"
										label="매도 수량"
										rules={[
											{
												validator: (_, value) => (isNaN(value) || !Number(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '매도 수량을 입력해주세요.'
											}
										]}
									>
										<Input style={{ width: 150 }} suffix={market.cd ? market.cd.replace('KRW-', '') : '코인'} />
									</Form.Item>
									<Form.Item
										name="buyQuantity"
										label="매수 수량"
										rules={[
											{
												validator: (_, value) => (isNaN(value) || !Number(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '매수 수량을 입력해주세요.'
											}
										]}
									>
										<Input style={{ width: 150 }} suffix={market.cd ? market.cd.replace('KRW-', '') : '코인'} />
									</Form.Item>
									<Form.Item
										name="askStep"
										label="매도가 증가폭"
										rules={[
											{
												validator: (_, value) => (isNaN(value) || Number(value) === 0 ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '매도가 증가폭을 입력해주세요.'
											}
										]}
									>
										<InputNumber style={{ width: 150 }} min={0} max={10000000} step={(market && market.step) || 0} formatter={(value) => '₩ ' + value} parser={(value) => value.replace('₩ ', '')} />
									</Form.Item>
									<Form.Item
										name="bidStep"
										label="매수가 감소폭"
										rules={[
											{
												validator: (_, value) => (isNaN(value) || Number(value) === 0 ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '매수가 감소폭을 입력해주세요.'
											}
										]}
									>
										<InputNumber style={{ width: 150 }} min={0} max={10000000} step={(market && market.step) || 0} formatter={(value) => '₩ ' + value} parser={(value) => value.replace('₩ ', '')} />
									</Form.Item>
									<Form.Item
										name="stopBuy"
										label="매수 상한선"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '매수 상한선을 입력해주세요.'
											}
										]}
									>
										<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(market && market.step) || 0} formatter={(value) => '₩ ' + value} parser={(value) => value.replace('₩ ', '')} />
									</Form.Item>
									<Form.Item
										name="stopSell"
										label="매도 하한선"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '매도 하한선을 입력해주세요.'
											}
										]}
									>
										<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(market && market.step) || 0} formatter={(value) => '₩ ' + value} parser={(value) => value.replace('₩ ', '')} />
									</Form.Item>
									{/* <Form.Item
										name="initPrice"
										label="초기 평단가"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '초기 평단가를 입력해주세요.'
											}
										]}
									>
										<Input style={{ width: 150 }} prefix="₩" />
									</Form.Item>
									<Form.Item
										name="initQuantity"
										label="초기 매수량"
										rules={[
											{
												validator: (_, value) => (isNaN(value) || !Number(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '초기 매수량을 입력해주세요.'
											}
										]}
									>
										<Input style={{ width: 150 }} suffix={market.cd ? market.cd.replace('KRW-', '') : '코인'} />
									</Form.Item>
									<Form.Item name="initAmount" label="초기 평가금액">
										<Input style={{ width: 150 }} prefix="₩" disabled />
									</Form.Item> */}
									<Form.Item {...tailLayout}>
										<Button type="primary" htmlType="submit">
											추가(변경)
										</Button>
									</Form.Item>
								</Form>
							</div>
							<div style={{ display: mode ? undefined : 'none' }}>
								<Form {...layout} form={raceForm} initialValues={{ market: market.cd, targetProfit: 0.05 }} onFinish={(e) => handleUpbitAddMarginTriger({ ...e, market, type: 'race' })}>
									<Form.Item name="market" label="코인" rules={[{ required: true, message: '코인을 선택해주세요!' }]}>
										<Input disabled />
									</Form.Item>
									<Form.Item
										label="목표 수익률"
										name="targetProfit"
										rules={[
											{
												validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
											},
											{
												required: true,
												message: '목표 수익률을 입력해주세요.'
											}
										]}
									>
										<InputNumber style={{ width: 100 }} min={0} max={100} step={0.005} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
									</Form.Item>
									<Form.Item {...tailLayout}>
										<Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
											추가(변경)
										</Button>
									</Form.Item>
								</Form>
							</div>
						</Card>
					</Space>
				</Col>
			</Row>
		</>
	);
};
