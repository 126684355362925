import React from 'react';
import { Form, Input, Button, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const ForgotPassword = ({ onForgotPassword, onToggleSignInUp, username }) => {
	return (
		<>
			<Form name="forgotPassword" className="login-form" initialValues={{ username, remember: true }} onFinish={onForgotPassword}>
				<Form.Item name="username" rules={[{ required: true, message: '아이디를 입력해주세요!' }]}>
					<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="아이디" type="text" />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" className="login-form-button">
						비밀번호 초기화
					</Button>
					<Divider />
					<a href="#signin" onClick={() => onToggleSignInUp('signIn')}>
						로그인하기
					</a>
				</Form.Item>
			</Form>
		</>
	);
};

export default ForgotPassword;
