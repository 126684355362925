import React from 'react';
import { Row } from 'antd';

import PremiumTableForm from './premiumTableForm';

export default ({ auth, symbols, upbitTicker, binanceTicker, exchangeRate, premiumOrders, premiumTriggers, handlePremiumAddOrders, handelDeletePremiumOrder, handlePremiumAddTriger, handelDeletePremiumTrigger }) => {
	const _upbitTicker = Object.keys(upbitTicker)
		.sort()
		.reduce((obj, key) => {
			obj[key] = upbitTicker[key];
			return obj;
		}, {});

	return (
		<>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				{Object.entries(_upbitTicker).map(([key, value]) => {
					const code = key.replace('KRW-', '');
					const order = premiumOrders.find((x) => x.code === code);
					if (binanceTicker.hasOwnProperty(`${code}USDT`) && order) {
						return <PremiumTableForm key={key} auth={auth} code={code} symbols={symbols} upbitPrice={value} binancePrice={Number(binanceTicker[code + 'USDT'])} order={order} trigger={premiumTriggers.find((x) => x.code === code)} exchangeRate={exchangeRate} handlePremiumAddOrders={handlePremiumAddOrders} handelDeletePremiumOrder={handelDeletePremiumOrder} handlePremiumAddTriger={handlePremiumAddTriger} handelDeletePremiumTrigger={handelDeletePremiumTrigger}></PremiumTableForm>;
					}
				})}
				{Object.entries(_upbitTicker).map(([key, value]) => {
					const code = key.replace('KRW-', '');
					const order = premiumOrders.find((x) => x.code === code);
					if (binanceTicker.hasOwnProperty(`${code}USDT`) && !order) {
						return <PremiumTableForm key={key} auth={auth} code={code} symbols={symbols} upbitPrice={value} binancePrice={Number(binanceTicker[code + 'USDT'])} order={order} trigger={premiumTriggers.find((x) => x.code === code)} exchangeRate={exchangeRate} handlePremiumAddOrders={handlePremiumAddOrders} handelDeletePremiumOrder={handelDeletePremiumOrder} handlePremiumAddTriger={handlePremiumAddTriger} handelDeletePremiumTrigger={handelDeletePremiumTrigger}></PremiumTableForm>;
					}
				})}
			</Row>
		</>
	);
};
