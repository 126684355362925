import React from 'react';
import { Descriptions, Space, Card, Switch, Button } from 'antd';
import { format } from 'd3-format';

export default ({ auth, triggers, handelTrigger, handelRePositionOrders, handelDeleteTrigger, handelBinanceCoinmTriggerValue }) => {
	triggers.sort((a, b) => (b.symbol > a.symbol ? -1 : 1));

	return (
		<Space direction="vertical" style={{ width: '100%', backgroundColor: '#efdbff' }}>
			{triggers.map((item, key) => (
				<Card
					key={`${item.symbol}`}
					title={
						<span onClick={() => handelBinanceCoinmTriggerValue(item)} style={{ cursor: 'pointer', color: item.positionSide === 'LONG' ? '#03a66d' : '#cf304a' }}>
							{item.name}
						</span>
					}
					size="small"
					style={{ width: '100%' }}
					extra={
						<>
							<Switch style={{ marginRight: '8px' }} checked={item.autoReposition} checkedChildren="자동" unCheckedChildren="수동" onChange={(e) => handelTrigger({ symbol: item.symbol, autoReposition: e, positionSide: item.positionSide })} />
							<Button style={{ color: '#ffffff', background: '#2f54eb', borderColor: '#2f54eb', marginRight: '8px' }} onClick={() => handelRePositionOrders({ symbol: item.symbol, positionSide: item.positionSide })}>
								재진입
							</Button>
							<Button style={{ color: '#ffffff', background: '#cf304a', borderColor: '#cf304a' }} onClick={() => handelDeleteTrigger(item.symbol, item.positionSide)}>
								삭제
							</Button>
						</>
					}
				>
					<div style={{ cursor: 'pointer' }} onClick={() => handelBinanceCoinmTriggerValue(item)}>
						<Descriptions bordered size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
							<Descriptions.Item label="예산" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#2f54eb' }}>
								{`${format(`$,.2f`)(item.budget)}`}
							</Descriptions.Item>
							<Descriptions.Item label="수익률" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#ff7875' }}>
								{format(`,.${item.targetRoe ? 2 : 0}%`)(item.targetRoe)}
							</Descriptions.Item>
							{item.stopReposition && (
								<Descriptions.Item label="재진입 제한선" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#fadb14' }} span={2}>
									{`${format(`$,.${item.pricePrecision}f`)(item.stopReposition)}`}
								</Descriptions.Item>
							)}
						</Descriptions>
					</div>
				</Card>
			))}
		</Space>
	);
};
