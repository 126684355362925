import React from 'react';
import { Form, Input, Button, Checkbox, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const SignIn = ({ onSignIn, onToggleSignInUp, username }) => {
	return (
		<>
			<Form name="signin" className="login-form" initialValues={{ username, remember: true }} onFinish={onSignIn}>
				<Form.Item name="username" rules={[{ required: true, message: '아이디를 입력해주세요!' }]}>
					<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="아이디" type="text" />
				</Form.Item>
				<Form.Item name="password" rules={[{ required: true, message: '비밀번호를 입력해주세요!' }]}>
					<Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="비밀번호" />
				</Form.Item>
				{/* <Form.Item>
					<Form.Item name="remember" valuePropName="checked" noStyle>
						<Checkbox>Remember me</Checkbox>
					</Form.Item>
				</Form.Item> */}
				<Form.Item>
					<Button type="primary" htmlType="submit" className="login-form-button">
						로그인
					</Button>
					<Divider />
					<a className="login-form-forgot" href="#forgotPassword" onClick={() => onToggleSignInUp('forgotPassword')}>
						비밀번호찾기
					</a>
					{'  |  '}
					<a href="#signup" onClick={() => onToggleSignInUp('signUp')}>
						회원가입하기
					</a>
				</Form.Item>
			</Form>
		</>
	);
};

export default SignIn;
