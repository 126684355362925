import React from 'react';
import { Descriptions, Space, Card, Switch, Button } from 'antd';
import { format } from 'd3-format';

export default ({ auth, triggers, handelTrigger, handelDeleteTrigger, handelUpbitTriggerValue }) => {
	triggers.sort((a, b) => (b.market > a.market ? -1 : 1));

	return (
		<Space direction="vertical" style={{ width: '100%', backgroundColor: '#adc6ff' }}>
			{triggers.map((item, key) => (
				<Card
					key={`${item.market}`}
					title={
						<span onClick={() => handelUpbitTriggerValue(item)} style={{ cursor: 'pointer' }}>
							{item.name}
						</span>
					}
					size="small"
					style={{ width: '100%' }}
					extra={
						<>
							<Button style={{ color: '#ffffff', background: '#cf304a', borderColor: '#cf304a', marginRight: '8px' }} onClick={() => handelDeleteTrigger({ market: item.market, tradeQuantity: item.tradeQuantity, type: item.type })}>
								삭제
							</Button>
							<Switch checked={item.activate} checkedChildren="ON" unCheckedChildren="OFF" onChange={(e) => handelTrigger({ market: item.market, activate: e, tradeQuantity: item.tradeQuantity, type: item.type })} />
						</>
					}
				>
					<div style={{ cursor: 'pointer' }} onClick={() => handelUpbitTriggerValue(item)}>
						{item.type === 'race' ? (
							<Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
								<Descriptions.Item label="목표 수익률" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#ff7875' }}>
									{format(`,.${item.targetProfit ? 2 : 0}%`)(item.targetProfit)}
								</Descriptions.Item>
							</Descriptions>
						) : (
							<Descriptions bordered size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
								<Descriptions.Item label={`${item.buyQuantity && item.tradeQuantity !== item.buyQuantity ? '매도' : '매매'} 수량`} labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#1890ff' }} span={item.buyQuantity && item.tradeQuantity !== item.buyQuantity ? 1 : 2}>
									{item.tradeQuantity}
								</Descriptions.Item>
								{item.buyQuantity && item.tradeQuantity !== item.buyQuantity && (
									<Descriptions.Item label="매수 수량" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#ff4d4f' }}>
										{item.buyQuantity}
									</Descriptions.Item>
								)}
								<Descriptions.Item label="증가폭" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#1890ff' }}>
									{`₩${format(',.2f')(item.askStep)}`}
								</Descriptions.Item>
								<Descriptions.Item label="감소폭" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#ff4d4f' }}>
									{`₩${format(',.2f')(item.bidStep)}`}
								</Descriptions.Item>
								{(item.stopBuy || item.stopSell) && (
									<>
										<Descriptions.Item label="매수 상한선" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#ff4d4f' }}>
											{`₩${format(',.2f')(item.stopBuy || 0)}`}
										</Descriptions.Item>
										<Descriptions.Item label="매도 하한선" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#1890ff' }}>
											{`₩${format(',.2f')(item.stopSell || 0)}`}
										</Descriptions.Item>
									</>
								)}
							</Descriptions>
						)}
					</div>
				</Card>
			))}
		</Space>
	);
};
