export const getUpbitStep = (price) => {
	let ps = 0.002;
	if (price >= 10000000) ps = 50000;
	else if (price < 10000000 && price >= 1000000) ps = 20000;
	else if (price < 1000000 && price >= 500000) ps = 10000;
	else if (price < 500000 && price >= 100000) ps = 500;
	else if (price < 100000 && price >= 20000) ps = 200;
	else if (price < 20000 && price >= 10000) ps = 100;
	else if (price < 10000 && price >= 5000) ps = 50;
	else if (price < 5000 && price >= 1000) ps = 20;
	else if (price < 1000 && price >= 500) ps = 5;
	else if (price < 500 && price >= 200) ps = 2;
	else if (price < 200 && price >= 100) ps = 1;
	else if (price < 100 && price >= 10) ps = 0.2;
	else if (price < 10 && price >= 1) ps = 0.02;

	if (price >= 2000000) return { step: 1000, premiumStep: 1000 >= price * 0.0001 ? 1000 : Math.round(price * 0.0001 / 1000) * 1000, ps, pricePrecision: 0 };
	else if (price < 2000000 && price >= 1000000) return { step: 500, premiumStep: 500 >= price * 0.0001 ? 500 : Math.round(price * 0.0001 / 500) * 500, ps, pricePrecision: 0 };
	else if (price < 1000000 && price >= 500000) return { step: 100, premiumStep: 100 >= price * 0.0001 ? 100 : Math.round(price * 0.0001 / 100) * 100, ps, pricePrecision: 0 };
	else if (price < 500000 && price >= 100000) return { step: 50, premiumStep: 50 >= price * 0.0001 ? 50 : Math.round(price * 0.0001 / 50) * 50, ps, pricePrecision: 0 };
	else if (price < 100000 && price >= 10000) return { step: 10, premiumStep: 10 >= price * 0.0001 ? 10 : Math.round(price * 0.0001 / 10) * 10, ps, pricePrecision: 0 };
	else if (price < 10000 && price >= 1000) return { step: 5, premiumStep: 5 >= price * 0.0001 ? 5 : Math.round(price * 0.0001 / 5) * 5, ps, pricePrecision: 0 };
	else if (price < 1000 && price >= 100) return { step: 1, premiumStep: 1 >= price * 0.0001 ? 1 : Math.round(price * 0.0001 / 1) * 1, ps, pricePrecision: 0 };
	else if (price < 100 && price >= 10) return { step: 0.1, premiumStep: 0.1 >= price * 0.0001 ? 0.1 : Math.round(price * 0.0001 / 0.1) * 0.1, ps, pricePrecision: 1 };
	else if (price < 10 && price >= 1) return { step: 0.01, premiumStep: 0.01 >= price * 0.0001 ? 0.01 : Math.round(price * 0.0001 / 0.01) * 0.01, ps, pricePrecision: 2 };
	else return { step: 0.001, premiumStep: 0.001 >= price * 0.0001 ? 0.001 : Math.round(price * 0.0001 / 0.001) * 0.001, ps, pricePrecision: 3 };
};

export const getUpbitValues = (price, addUpPrice, amount, step) => {
	const upbitPrice = Math.round((price + addUpPrice) / step) * step;
	const upbitQuantity = amount / upbitPrice;
	return { upbitPrice, upbitQuantity };
};

export const getBinanceStep = (symbol) => {
	const priceFilter = symbol.filters.find(x => x.filterType === 'PRICE_FILTER');
	const quantityFilter = symbol.filters.find(x => x.filterType === 'LOT_SIZE');
	let pricePrecision = 0;
	if (Number(priceFilter.tickSize) === 0.1) pricePrecision = 1;
	else if (Number(priceFilter.tickSize) === 0.01) pricePrecision = 2;
	else if (Number(priceFilter.tickSize) === 0.001) pricePrecision = 3;
	else if (Number(priceFilter.tickSize) === 0.0001) pricePrecision = 4;
	else if (Number(priceFilter.tickSize) === 0.00001) pricePrecision = 5;
	else if (Number(priceFilter.tickSize) === 0.000001) pricePrecision = 6;
	else if (Number(priceFilter.tickSize) === 0.0000001) pricePrecision = 7;
	else if (Number(priceFilter.tickSize) === 0.00000001) pricePrecision = 8;
	let quantityPrecision = 0;
	if (Number(quantityFilter.stepSize) === 0.1) quantityPrecision = 1;
	else if (Number(quantityFilter.stepSize) === 0.01) quantityPrecision = 2;
	else if (Number(quantityFilter.stepSize) === 0.001) quantityPrecision = 3;
	else if (Number(quantityFilter.stepSize) === 0.0001) quantityPrecision = 4;
	else if (Number(quantityFilter.stepSize) === 0.00001) quantityPrecision = 5;
	else if (Number(quantityFilter.stepSize) === 0.000001) quantityPrecision = 6;
	else if (Number(quantityFilter.stepSize) === 0.0000001) quantityPrecision = 7;
	else if (Number(quantityFilter.stepSize) === 0.00000001) quantityPrecision = 8;
	return { priceStep: Number(priceFilter.tickSize), pricePrecision, quantityStep: Number(quantityFilter.stepSize), quantityPrecision };
};
