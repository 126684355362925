import Amplify, { Auth } from 'aws-amplify';
import moment from 'moment';

Amplify.configure({
	Auth: {
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
		region: process.env.REACT_APP_REGION
	}
});

export const signIn = async ({ username, password }) => {
	return await Auth.signIn(username, password);
};

export const signUp = async ({ username, email, password, referer }) => {
	return await Auth.signUp({
		username,
		password,
		attributes: {
			email,
			'custom:group': 'coin',
			'custom:upbit': 'false',
			'custom:binance': 'false',
			'custom:premium': 'false',
			'custom:expiry': moment().add(2, 'days').format('YYYY-MM-DD'),
			'custom:baseURL': 'http://localhost',
			'custom:referer': referer,
			'custom:point': '0',
			'custom:memo': ' ',
			'custom:name': ' ',
			'custom:version': 'v2'
		}
	});
};

export const confirmSignUp = async ({ username, code }) => {
	return await Auth.confirmSignUp(username, code);
};

export const forgotPassword = async ({username}) => {
	return await Auth.forgotPassword(username);
};

export const newPassword = async ({ username, code, password }) => {
	return await Auth.forgotPasswordSubmit(username, code, password);
};

export const checkSignIn = async () => {
	return await Auth.currentAuthenticatedUser({
		bypassCache: true
	});
};

export const signOut = async () => {
	return await Auth.signOut();
};
