import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { rootSaga } from './modules';
import { createBrowserHistory } from 'history';
import App from './app';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';

const customHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware({
	history: customHistory
});

const composeEnhancers = composeWithDevTools({
	actionsBlacklist: ['SET_UPBIT_TICKER', 'SET_UPBIT_TICKER_SUCCESS', 'SET_UPBIT_TICKER_FAILURE', 'SET_BINANCE_TICKER', 'SET_BINANCE_TICKER_SUCCESS', 'SET_BINANCE_TICKER_FAILURE']
});
const store = createStore(rootReducer, (process.env.REACT_APP_NODE_ENV == 'dev' ? composeEnhancers(applyMiddleware(sagaMiddleware)) : applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

reportWebVitals();
