import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const ConfirmSignUp = ({ onConfirmSignUp, onResendCode, username }) => {
	return (
		<>
			<Form name="confirmSignUp" className="login-form" initialValues={{ username }} onFinish={onConfirmSignUp}>
				<Form.Item name="username" value={username} rules={[{ required: true, message: '아이디를 입력해주세요!' }]}>
					<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="아이디" type="text" />
				</Form.Item>
				<Form.Item name="code" rules={[{ required: true, message: '인증코드를 입력해주세요!' }]}>
					<Input prefix={<LockOutlined className="site-form-item-icon" />} type="text" placeholder="인증코드" />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" className="login-form-button">
						확인
					</Button>
					<Button htmlType="button" className="login-form-button" onClick={() => onResendCode(username)}>
						재전송
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default ConfirmSignUp;
