import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createRequestActionType, createPromiseSaga, reducerUtils, handleDefaultActions } from '../lib/utils';
import * as authAPI from '../api/auth';

// 1.Creact Action Type
const [SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_FAILURE] = createRequestActionType('auth/SIGN_IN');
const [SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_FAILURE] = createRequestActionType('auth/SIGN_UP');
const [CONFIRM_SIGN_UP, CONFIRM_SIGN_UP_SUCCESS, CONFIRM_SIGN_UP_FAILURE] = createRequestActionType('auth/CONFIRM_SIGN_UP');
const [FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE] = createRequestActionType('auth/FORGOT_PASSWORD');
const [NEW_PASSWORD, NEW_PASSWORD_SUCCESS, NEW_PASSWORD_FAILURE] = createRequestActionType('auth/NEW_PASSWORD');
const [CHECK_SIGN_IN, CHECK_SIGN_IN_SUCCESS, CHECK_SIGN_IN_FAILURE] = createRequestActionType('auth/CHECK_SIGN_IN');
const [SIGN_OUT, SIGN_OUT_SUCCESS, SIGN_OUT_FAILURE] = createRequestActionType('auth/SIGN_OUT');

// 2.Create Action
export const signInAction = createAction(SIGN_IN);
export const signUpAction = createAction(SIGN_UP);
export const confirmSignUpAction = createAction(CONFIRM_SIGN_UP);
export const forgotPasswordAction = createAction(FORGOT_PASSWORD);
export const newPasswordAction = createAction(NEW_PASSWORD);
export const checkSignInAction = createAction(CHECK_SIGN_IN);
export const signOutAction = createAction(SIGN_OUT);

// 3.Create Saga function
const signIn = createPromiseSaga(SIGN_IN, authAPI.signIn);
const signUp = createPromiseSaga(SIGN_UP, authAPI.signUp);
const confirmSignUp = createPromiseSaga(CONFIRM_SIGN_UP, authAPI.confirmSignUp);
const forgotPassword = createPromiseSaga(FORGOT_PASSWORD, authAPI.forgotPassword);
const newPassword = createPromiseSaga(NEW_PASSWORD, authAPI.newPassword);
const checkSignIn = createPromiseSaga(CHECK_SIGN_IN, authAPI.checkSignIn);
const signOut = createPromiseSaga(SIGN_OUT, authAPI.signOut);

export function* authSaga() {
	yield takeLatest(SIGN_IN, signIn);
	yield takeLatest(SIGN_UP, signUp);
	yield takeLatest(CONFIRM_SIGN_UP, confirmSignUp);
	yield takeLatest(FORGOT_PASSWORD, forgotPassword);
	yield takeLatest(NEW_PASSWORD, newPassword);
	yield takeLatest(CHECK_SIGN_IN, checkSignIn);
	yield takeLatest(SIGN_OUT, signOut);
}

// 4.Create state
const initState = {
	auth: reducerUtils.initial()
};

// 5.Create Action handling
const authReducer = (state = initState, action) => {
	switch (action.type) {
		case SIGN_IN:
		case SIGN_IN_SUCCESS:
		case SIGN_IN_FAILURE:
			return handleDefaultActions(SIGN_IN, 'auth', false)(state, action);
		case SIGN_UP:
		case SIGN_UP_SUCCESS:
		case SIGN_UP_FAILURE:
			return handleDefaultActions(SIGN_UP, 'auth', false)(state, action);
		case CONFIRM_SIGN_UP:
		case CONFIRM_SIGN_UP_SUCCESS:
		case CONFIRM_SIGN_UP_FAILURE:
			return handleDefaultActions(CONFIRM_SIGN_UP, 'auth', false)(state, action);
		case FORGOT_PASSWORD:
		case FORGOT_PASSWORD_SUCCESS:
		case FORGOT_PASSWORD_FAILURE:
			return handleDefaultActions(FORGOT_PASSWORD, 'auth', false)(state, action);
		case NEW_PASSWORD:
		case NEW_PASSWORD_SUCCESS:
		case NEW_PASSWORD_FAILURE:
			return handleDefaultActions(NEW_PASSWORD, 'auth', false)(state, action);
		case CHECK_SIGN_IN:
		case CHECK_SIGN_IN_SUCCESS:
		case CHECK_SIGN_IN_FAILURE:
			return handleDefaultActions(CHECK_SIGN_IN, 'auth', false)(state, action);
		case SIGN_OUT:
		case SIGN_OUT_SUCCESS:
		case SIGN_OUT_FAILURE:
			return handleDefaultActions(SIGN_OUT, 'auth', false)(state, action);
		default:
			return state;
	}
};

export default authReducer;
