import React, { useState, useEffect, useMemo, Fragment } from 'react';
import moment from 'moment';
import { Form, Switch, Button, Select, DatePicker, Input, InputNumber, List, Space, Card, Radio } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { offset: 8, span: 16 }
};

export default ({ auth, coinAPI, baseUrl }) => {
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState(null);

	const [userForm] = Form.useForm();

	useEffect(async () => {
		handelGetUsers();
	}, []);

	useEffect(async () => {
		userForm.resetFields();
	}, [user]);

	const handelGetUsers = async () => {
		const response = await coinAPI.getUsers(auth);
		setUsers(response.Users);
	};

	const handelGetUser = async (value) => {
		setUser(users.find((x) => x.Username === value));
	};

	const handelSetUser = (values) => {
		coinAPI.setUser({ auth, Username: user.Username, values });
		coinAPI.setBasicSetting({
			auth,
			baseUrl,
			serverUrl: values['custom:baseURL'] || 'http://localhost',
			upbit: values['custom:upbit'],
			binance: values['custom:binance'],
			expiry: moment(values['custom:expiry']).format('YYYY-MM-DD'),
			point: values['custom:point'] || 0,
			version: values['custom:version'] || 'v2'
		});
		setUsers((prevState) => {
			const _user = prevState.find((x) => x.Username === user.Username);
			_user.Attributes = [
				{
					Name: 'custom:expiry',
					Value: moment(values['custom:expiry']).format('YYYY-MM-DD')
				},
				{ Name: 'custom:upbit', Value: values['custom:upbit'] ? 'true' : 'false' },
				{ Name: 'custom:binance', Value: values['custom:binance'] ? 'true' : 'false' },
				{ Name: 'custom:premium', Value: values['custom:premium'] ? 'true' : 'false' },
				{ Name: 'custom:baseURL', Value: values['custom:baseURL'] || 'http://localhost' },
				{ Name: 'custom:referer', Value: values['custom:referer'] || '' },
				{ Name: 'custom:point', Value: values['custom:point'] || 0 },
				{ Name: 'custom:memo', Value: values['custom:memo'] || '' },
				{ Name: 'custom:name', Value: values['custom:name'] || '' },
				{ Name: 'custom:version', Value: values['custom:version'] || 'v2' }
			];
			return [...prevState.filter((x) => x.Username !== user.Username), _user];
		});
	};

	const handleServerUpdate = (serverUrl) => {
		coinAPI.serverUpdate({ auth, baseUrl, serverUrl });
	};

	const handleServerUpdateAll = (activeUsers) => {
		coinAPI.serverUpdateAll({ auth, baseUrl, activeUsers });
	};

	const handleServerReboot = (serverUrl) => {
		coinAPI.serverReboot({ auth, baseUrl, serverUrl });
	};

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Card title="Users" size="small" style={{ width: '100%' }}>
					<Form {...layout} name="user_form" initialValues={{}}>
						<Form.Item name="username" label="User">
							<Select showSearch style={{ width: 200 }} placeholder="Select a user" optionFilterProp="children" onChange={handelGetUser} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{useMemo(
									() =>
										users &&
										users.map((item, i) => (
											<Select.Option key={item.Username} value={item.Username}>
												{item.Username}
											</Select.Option>
										)),
									[users]
								)}
							</Select>
							<Button type="primary" icon={<RedoOutlined />} onClick={() => handelGetUsers()} />
						</Form.Item>
					</Form>
					<Form
						{...layout}
						form={userForm}
						name="user_form"
						initialValues={{
							'custom:upbit': user && user.Attributes.find((x) => x.Name === 'custom:upbit') ? user.Attributes.find((x) => x.Name === 'custom:upbit').Value === 'true' : true,
							'custom:binance': user && user.Attributes.find((x) => x.Name === 'custom:binance') ? user.Attributes.find((x) => x.Name === 'custom:binance').Value === 'true' : true,
							'custom:premium': user && user.Attributes.find((x) => x.Name === 'custom:premium') ? user.Attributes.find((x) => x.Name === 'custom:premium').Value === 'true' : true,
							'custom:expiry': user && user.Attributes.find((x) => x.Name === 'custom:expiry') ? moment(user.Attributes.find((x) => x.Name === 'custom:expiry').Value) : '',
							'custom:baseURL': user && user.Attributes.find((x) => x.Name === 'custom:baseURL') ? user.Attributes.find((x) => x.Name === 'custom:baseURL').Value : '',
							'custom:referer': user && user.Attributes.find((x) => x.Name === 'custom:referer') ? user.Attributes.find((x) => x.Name === 'custom:referer').Value : '',
							'custom:point': user && user.Attributes.find((x) => x.Name === 'custom:point') ? user.Attributes.find((x) => x.Name === 'custom:point').Value : 0,
							// 'custom:instanceId': user && user.Attributes.find((x) => x.Name === 'custom:instanceId') ? user.Attributes.find((x) => x.Name === 'custom:instanceId').Value : '',
							'custom:memo': user && user.Attributes.find((x) => x.Name === 'custom:memo') ? user.Attributes.find((x) => x.Name === 'custom:memo').Value : '',
							'custom:name': user && user.Attributes.find((x) => x.Name === 'custom:name') ? user.Attributes.find((x) => x.Name === 'custom:name').Value : '',
							'custom:version': user && user.Attributes.find((x) => x.Name === 'custom:version') ? user.Attributes.find((x) => x.Name === 'custom:version').Value : 'v2'
						}}
						onFinish={handelSetUser}
					>
						<Form.Item name="custom:name" label="Name">
							<Input placeholder="Name" type="text" />
						</Form.Item>
						<Form.Item name="custom:expiry" label="Expiry">
							<DatePicker />
						</Form.Item>
						<Form.Item name="custom:baseURL" label="Base URL">
							<Input placeholder="Base URL" type="text" />
						</Form.Item>
						<Form.Item name="custom:referer" label="Referer">
							<Input placeholder="Referer" type="text" />
						</Form.Item>
						<Form.Item name="custom:point" label="Point">
							<InputNumber style={{ width: 150 }} min={0} step={5000} formatter={(value) => '₩' + value} parser={(value) => value.replace('₩', '')} />
						</Form.Item>
						<Form.Item name="custom:memo" label="Memo">
							<Input.TextArea rows={8} />
						</Form.Item>
						{/* <Form.Item name="custom:instanceId" label="Instance ID">
							<Input placeholder="Instance ID" type="text" />
						</Form.Item> */}
						<Form.Item name="custom:upbit" label="Upbit" valuePropName="checked">
							<Switch checkedChildren="활성화" unCheckedChildren="비활성화" />
						</Form.Item>
						<Form.Item name="custom:binance" label="Binance" valuePropName="checked">
							<Switch checkedChildren="활성화" unCheckedChildren="비활성화" />
						</Form.Item>
						<Form.Item name="custom:premium" label="Premium" valuePropName="checked">
							<Switch checkedChildren="활성화" unCheckedChildren="비활성화" />
						</Form.Item>
						<Form.Item name="custom:version" label="Version">
							<Radio.Group buttonStyle="solid">
								<Radio.Button value="v2">V2.1</Radio.Button>
								<Radio.Button value="v3">V3.0</Radio.Button>
							</Radio.Group>
						</Form.Item>
						{user && (
							<Form.Item {...tailLayout}>
								<Button htmlType="button" style={{ color: '#1f1f1f', background: '#cf304a', borderColor: '#cf304a', marginRight: '8px' }} onClick={() => handleServerUpdate(user.Attributes.find((x) => x.Name === 'custom:baseURL') ? user.Attributes.find((x) => x.Name === 'custom:baseURL').Value : '')}>
									Update
								</Button>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</Form.Item>
						)}
						{users && (
							<Form.Item {...tailLayout}>
								<Button htmlType="button" style={{ color: '#1f1f1f', background: '#cf304a', borderColor: '#cf304a', marginRight: '8px' }} onClick={() => handleServerUpdateAll(users)}>
									Update all
								</Button>
								<Button htmlType="button" style={{ color: '#1f1f1f', background: '#cf304a', borderColor: '#cf304a', marginRight: '8px' }} onClick={() => handleServerReboot(user.Attributes.find((x) => x.Name === 'custom:baseURL') ? user.Attributes.find((x) => x.Name === 'custom:baseURL').Value : '')}>
									Reboot
								</Button>
							</Form.Item>
						)}
					</Form>
				</Card>
				<Card title="Referers" size="small" style={{ width: '100%' }}>
					{users && user && (
						<List
							size="small"
							bordered
							dataSource={users.filter((x) => x.Attributes.findIndex((y) => y.Name === 'custom:referer') >= 0 && x.Attributes.find((y) => y.Name === 'custom:referer').Value && x.Attributes.find((y) => y.Name === 'custom:referer').Value.toLowerCase() === user.Username.toLowerCase())}
							renderItem={(item) => <List.Item>{`${item.Username} (${item.Attributes.find((x) => x.Name === 'custom:name').Value}) (${moment(item.Attributes.find((x) => x.Name === 'custom:expiry').Value).format('YYYY-MM-DD')})`}</List.Item>}
						/>
					)}
				</Card>
				<Card title="Expired or ending soon" size="small" style={{ width: '100%' }}>
					{users && (
						<List
							size="small"
							bordered
							dataSource={users.filter(
								(x) =>
									x.Attributes.findIndex((y) => y.Name === 'custom:expiry') >= 0 &&
									x.Attributes.find((y) => y.Name === 'custom:expiry').Value &&
									moment(x.Attributes.find((y) => y.Name === 'custom:expiry').Value)
										.endOf('day')
										.diff(moment().local().add(3, 'days'), 'hours') < 0
							)}
							renderItem={(item) => <List.Item>{`${item.Username} (${item.Attributes.find((x) => x.Name === 'custom:name').Value}) (${moment(item.Attributes.find((x) => x.Name === 'custom:expiry').Value).format('YYYY-MM-DD')})`}</List.Item>}
						/>
					)}
				</Card>
			</Space>
		</>
	);
};
