import React, { useState, Fragment } from 'react';
import { Form, Input, Button, Space, Card, InputNumber, Descriptions } from 'antd';
import { format } from 'd3-format';
import moment from 'moment';

import * as coinAPI from '../../api/coin';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { span: 24 },
	style: { textAlign: 'center' }
};

export default ({ handleSetUpbitSetting, upbitSetting, handleGetUpbitWallet, upbitWallet, baseUrl, auth }) => {
	const [profits, setProfits] = useState([]);

	const handleGetUpbitProfit = () => {
		if (upbitSetting) {
			coinAPI.getUpbitProfit({ auth, baseUrl, profitPage: 1, ...upbitSetting }).then((response) => {
				if (response) {
					const sumProfits = [];
					const sumProfit = {
						date: '',
						profit: 0
					};
					for (const profit of response) {
						if (sumProfit.date === profit.date.substring(0, 2)) {
							sumProfit.profit += profit.profit;
						} else {
							if (sumProfit.profit) {
								sumProfits.push({
									date: `${Number(sumProfit.date)}월`,
									profit: sumProfit.profit
								});
							}
							sumProfit.date = profit.date.substring(0, 2);
							sumProfit.profit = profit.profit;
						}
					}
					if (sumProfit.profit) {
						sumProfits.push({
							date: `${Number(sumProfit.date)}월`,
							profit: sumProfit.profit
						});
					}
					setProfits([...sumProfits, ...response]);
				}
			});
		} else message.warning('업빗 API 키를 입력해주세요.');
	};

	return (
		<>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Card title="설정" size="small" style={{ width: '100%' }}>
					<Form
						{...layout}
						name="upbit_setting"
						initialValues={{
							accessKey: (upbitSetting && upbitSetting.accessKey) || '',
							secretKey: (upbitSetting && upbitSetting.secretKey) || '',
							telegramBotToken: (upbitSetting && upbitSetting.telegramBotToken) || '',
							telegramChatId: (upbitSetting && upbitSetting.telegramChatId) || ''
						}}
						onFinish={handleSetUpbitSetting}
					>
						<Form.Item name="accessKey" label="Access key" rules={[{ required: true, message: 'Required!' }]}>
							<Input placeholder="Access key" type="text" />
						</Form.Item>
						<Form.Item name="secretKey" label="Secret key" rules={[{ required: true, message: 'Required!' }]}>
							<Input placeholder="Secret key" type="text" />
						</Form.Item>
						<Form.Item name="telegramBotToken" label="텔레그램 token">
							<Input placeholder="Token" type="text" />
						</Form.Item>
						<Form.Item name="telegramChatId" label="텔레그램 Chat ID">
							<Input placeholder="Chat ID" type="text" />
						</Form.Item>
						<Form.Item {...tailLayout}>
							<Button htmlType="button" style={{ marginRight: '8px' }} onClick={() => handleGetUpbitWallet()}>
								지갑 조회
							</Button>
							<Button type="primary" htmlType="submit">
								저장
							</Button>
						</Form.Item>
					</Form>
				</Card>
				<Descriptions bordered size="small" column={{ xs: 1, xl: 3 }}>
					{upbitWallet && upbitWallet.asset && (
						<>
							<Descriptions.Item label="KRW" contentStyle={{ textAlign: 'right' }}>
								{`₩${format(',.2f')(Number(upbitWallet.asset.balance) + Number(upbitWallet.asset.locked))}`}
							</Descriptions.Item>
							<Descriptions.Item label="주문가능" contentStyle={{ textAlign: 'right' }}>
								{`₩${format(',.2f')(Number(upbitWallet.asset.balance))}`}
							</Descriptions.Item>
							<Descriptions.Item label="미체결" contentStyle={{ textAlign: 'right' }}>
								{`₩${format(',.2f')(Number(upbitWallet.asset.locked))}`}
							</Descriptions.Item>
							{upbitWallet.coins.map((item, key) => (
								<Fragment key={item}>
									<Descriptions.Item label={item.currency} contentStyle={{ textAlign: 'right' }}>
										{`${format(',.2f')(Number(item.balance) + Number(item.locked))}`}
									</Descriptions.Item>
									<Descriptions.Item label="주문가능" contentStyle={{ textAlign: 'right' }}>
										{`${format(',.2f')(Number(item.balance))}`}
									</Descriptions.Item>
									<Descriptions.Item label="미체결" contentStyle={{ textAlign: 'right' }}>
										{`${format(',.2f')(Number(item.locked))}`}
									</Descriptions.Item>
								</Fragment>
							))}
						</>
					)}
				</Descriptions>
				<Card title="수익" size="small" style={{ width: '100%' }}>
					<Form {...layout} name="upbit_profit">
						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="submit" onClick={() => handleGetUpbitProfit()}>
								수익 조회
							</Button>
						</Form.Item>
					</Form>
					<Descriptions bordered size="small" column={{ xs: 1, xl: 3 }}>
						{profits &&
							profits.map((item, i) => (
								<Descriptions.Item key={item.date} label={item.date} contentStyle={{ textAlign: 'right' }}>
									{`₩${format(',.2f')(item.profit)}`}
								</Descriptions.Item>
							))}
					</Descriptions>
				</Card>
			</Space>
		</>
	);
};
