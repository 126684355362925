import { createAction } from 'redux-actions';
import { call, put, takeLatest, takeEvery, takeLeading, delay } from 'redux-saga/effects';
import { message } from 'antd';
import { compare, compareUnsorted } from 'js-deep-equals';
import { createRequestActionType, createSaga, createPromiseSaga, reducerUtils, handleDefaultActions, handleActionsWhenUpdateOnly, handleActionsWhenUpdateOnlyBySymbol } from '../lib/utils';
import * as coinAPI from '../api/coin';
import { getUpbitStep, getBinanceStep } from '../hooks/useLibrary';

// 1.Creact Action Type
const [GET_MARKETS, GET_MARKETS_SUCCESS, GET_MARKETS_FAILURE] = createRequestActionType('coin/GET_MARKETS');
const [GET_SYMBOLS, GET_SYMBOLS_SUCCESS, GET_SYMBOLS_FAILURE] = createRequestActionType('coin/GET_SYMBOLS');
const [GET_FUTURES_SYMBOLS, GET_FUTURES_SYMBOLS_SUCCESS, GET_FUTURES_SYMBOLS_FAILURE] = createRequestActionType('coin/GET_FUTURES_SYMBOLS');
const [GET_COINM_SYMBOLS, GET_COINM_SYMBOLS_SUCCESS, GET_COINM_SYMBOLS_FAILURE] = createRequestActionType('coin/GET_COINM_SYMBOLS');
const [UPBIT_WALLET, UPBIT_WALLET_SUCCESS, UPBIT_WALLET_FAILURE] = createRequestActionType('coin/UPBIT_WALLET');
const [BINANCE_WALLET, BINANCE_WALLET_SUCCESS, BINANCE_WALLET_FAILURE] = createRequestActionType('coin/BINANCE_WALLET');
const [BINANCE_FUTURES_WALLET, BINANCE_FUTURES_WALLET_SUCCESS, BINANCE_FUTURES_WALLET_FAILURE] = createRequestActionType('coin/BINANCE_FUTURES_WALLET');
const [BINANCE_COINM_WALLET, BINANCE_COINM_WALLET_SUCCESS, BINANCE_COINM_WALLET_FAILURE] = createRequestActionType('coin/BINANCE_COINM_WALLET');


// const [UPBIT_SETTING, UPBIT_SETTING_SUCCESS, UPBIT_SETTING_FAILURE] = createRequestActionType('coin/UPBIT_SETTING');
// const [BINANCE_SETTING, BINANCE_SETTING_SUCCESS, BINANCE_SETTING_FAILURE] = createRequestActionType('coin/BINANCE_SETTING');
// const [BINANCE_SET_LEVERAGE, BINANCE_SET_LEVERAGE_SUCCESS, BINANCE_SET_LEVERAGE_FAILURE] = createRequestActionType('coin/BINANCE_SET_LEVERAGE');
// const [BINANCE_SET_MARGIN_TYPE, BINANCE_SET_MARGIN_TYPE_SUCCESS, BINANCE_SET_MARGIN_TYPE_FAILURE] = createRequestActionType('coin/BINANCE_SET_MARGIN_TYPE');
// const [BINANCE_SET_POSITION_SIDE, BINANCE_SET_POSITION_SIDE_SUCCESS, BINANCE_SET_POSITION_SIDE_FAILURE] = createRequestActionType('coin/BINANCE_SET_POSITION_SIDE');
// const [UPBIT_NEW_ORDER, UPBIT_NEW_ORDER_SUCCESS, UPBIT_NEW_ORDER_FAILURE] = createRequestActionType('coin/UPBIT_NEW_ORDER');
// const [SET_UPBIT_TICKER, SET_UPBIT_TICKER_SUCCESS, SET_UPBIT_TICKER_FAILURE] = createRequestActionType('coin/SET_UPBIT_TICKER');
// const [GET_UPBIT_TICK, GET_UPBIT_TICK_SUCCESS, GET_UPBIT_TICK_FAILURE] = createRequestActionType('coin/GET_UPBIT_TICK');
// const [UPBIT_GET_ORDER, UPBIT_GET_ORDER_SUCCESS, UPBIT_GET_ORDER_FAILURE] = createRequestActionType('coin/UPBIT_GET_ORDER');
// const [SET_BINANCE_TICKER, SET_BINANCE_TICKER_SUCCESS, SET_BINANCE_TICKER_FAILURE] = createRequestActionType('coin/SET_BINANCE_TICKER');
// const [GET_BINANCE_TICK, GET_BINANCE_TICK_SUCCESS, GET_BINANCE_TICK_FAILURE] = createRequestActionType('coin/GET_BINANCE_TICK');
// const [BINANCE_WALLET_WEBSOCKET, BINANCE_WALLET_WEBSOCKET_SUCCESS, BINANCE_WALLET_WEBSOCKET_FAILURE] = createRequestActionType('coin/BINANCE_WALLET_WEBSOCKET');
// const [BINANCE_GET_ORDER, BINANCE_GET_ORDER_SUCCESS, BINANCE_GET_ORDER_FAILURE] = createRequestActionType('coin/BINANCE_GET_ORDER');
// const [BINANCE_GET_ORDER_WEBSOCKET, BINANCE_GET_ORDER_WEBSOCKET_SUCCESS, BINANCE_GET_ORDER_WEBSOCKET_FAILURE] = createRequestActionType('coin/BINANCE_GET_ORDER_WEBSOCKET');
// const [BINANCE_NEW_ORDER, BINANCE_NEW_ORDER_SUCCESS, BINANCE_NEW_ORDER_FAILURE] = createRequestActionType('coin/BINANCE_NEW_ORDER');

// 2.Create Action
export const getUpbitMarketsAction = createAction(GET_MARKETS);
export const getBinanceSymbolsAction = createAction(GET_SYMBOLS);
export const getBinanceFuturesSymbolsAction = createAction(GET_FUTURES_SYMBOLS);
export const getBinanceCoinmSymbolsAction = createAction(GET_COINM_SYMBOLS);
export const getUpbitWalletAction = createAction(UPBIT_WALLET);
export const getBinanceWalletAction = createAction(BINANCE_WALLET);
export const getBinanceFuturesWalletAction = createAction(BINANCE_FUTURES_WALLET);
export const getBinanceCoinmWalletAction = createAction(BINANCE_COINM_WALLET);


// export const upbitSettingAction = createAction(UPBIT_SETTING);
// export const binanceSettingAction = createAction(BINANCE_SETTING);
// export const binanceSetLeverageAction = createAction(BINANCE_SET_LEVERAGE);
// export const binanceSetMarginTypeAction = createAction(BINANCE_SET_MARGIN_TYPE);
// export const binanceSetPositionSideAction = createAction(BINANCE_SET_POSITION_SIDE);
// export const upbitNewOrderAction = (payload, meta) => ({ type: UPBIT_NEW_ORDER, payload, meta });
// export const setUpbitTickerAction = (payload, meta) => ({ type: SET_UPBIT_TICKER, payload, meta });
// export const getUpbitTickAction = (payload, meta) => ({ type: GET_UPBIT_TICK, payload, meta });
// export const upbitGetOrderAction = (payload, meta) => ({ type: UPBIT_GET_ORDER, payload, meta });
// export const setBinanceTickerAction = (payload, meta) => ({ type: SET_BINANCE_TICKER, payload, meta });
// export const getBinanceTickAction = (payload, meta) => ({ type: GET_BINANCE_TICK, payload, meta });
// export const binanceWalletWebSocketAction = (payload, meta) => ({ type: BINANCE_WALLET_WEBSOCKET, payload, meta });
// export const binanceGetOrderAction = createAction(BINANCE_GET_ORDER);
// export const binanceGetOrderWebSocketAction = createAction(BINANCE_GET_ORDER_WEBSOCKET);
// export const binanceNewOrderAction = createAction(BINANCE_NEW_ORDER);

// 3.Create Saga function
const getUpbitMarkets = createPromiseSaga(GET_MARKETS, coinAPI.getUpbitMarkets);
const getBinanceSymbols = createPromiseSaga(GET_SYMBOLS, coinAPI.getBinanceSymbols);
const getBinanceFuturesSymbols = createPromiseSaga(GET_FUTURES_SYMBOLS, coinAPI.getBinanceFuturesSymbols);
const getBinanceCoinmSymbols = createPromiseSaga(GET_COINM_SYMBOLS, coinAPI.getBinanceCoinmSymbols);
const getUpbitWallet = createPromiseSaga(UPBIT_WALLET, coinAPI.getUpbitWallet);
const getBinanceWallet = createPromiseSaga(BINANCE_WALLET, coinAPI.getBinanceWallet);
const getBinanceFuturesWallet = createPromiseSaga(BINANCE_FUTURES_WALLET, coinAPI.getBinanceFuturesWallet);
const getBinanceCoinmWallet = createPromiseSaga(BINANCE_COINM_WALLET, coinAPI.getBinanceCoinmWallet);


// const upbitSetting = createPromiseSaga(UPBIT_SETTING, coinAPI.setUpbitSetting);
// const binanceSetting = createPromiseSaga(BINANCE_SETTING, coinAPI.setBinanceSetting);
// const binanceSetLeverage = createPromiseSaga(BINANCE_SET_LEVERAGE, coinAPI.setBinanceLeverage);
// const binanceSetMarginType = createPromiseSaga(BINANCE_SET_MARGIN_TYPE, coinAPI.setBinanceMarginType);
// const binanceSetPositionSide = createPromiseSaga(BINANCE_SET_POSITION_SIDE, coinAPI.setBinancePositionSide);
// const upbitNewOrder = createPromiseSaga(UPBIT_NEW_ORDER, coinAPI.setUpbitOrder);
// const setUpbitTicker = createSaga(SET_UPBIT_TICKER);
// function* getUpbitTick(action) {
// 	try {
// 		const payload = yield call(coinAPI.getUpbitTick, action.payload.upbitMarket);
// 		const { step, addProfit } = getUpbitStep(payload.trade_price, action.payload.upbitTargetProfit);
// 		yield put({ type: GET_UPBIT_TICK_SUCCESS, payload: { ...payload, step, addProfit } });
// 	} catch (err) {
// 		console.log(err);
// 		yield put({ type: GET_UPBIT_TICK_FAILURE, error: true, payload: err });
// 	}
// }
// const upbitGetOrder = createPromiseSaga(UPBIT_GET_ORDER, coinAPI.getUpbitOrders);
// const setBinanceTicker = createSaga(SET_BINANCE_TICKER);
// function* getBinanceTick(action) {
// 	try {
// 		const payload = yield call(coinAPI.getBinanceTick, action.payload.binanceSymbol);
// 		const { step, addProfit } = getBinanceStep(payload.price, action.payload.binanceTargetProfit, action.payload.symbol.pricePrecision);
// 		yield put({ type: GET_BINANCE_TICK_SUCCESS, payload: { ...payload, step, addProfit, pricePrecision: action.payload.symbol.pricePrecision, quantityPrecision: action.payload.symbol.quantityPrecision } });
// 	} catch (err) {
// 		console.log(err);
// 		yield put({ type: GET_BINANCE_TICK_FAILURE, error: true, payload: err });
// 	}
// }
// const binanceWalletWebSocket = createSaga(BINANCE_WALLET_WEBSOCKET);
// const binanceGetOrder = createPromiseSaga(BINANCE_GET_ORDER, coinAPI.binanceGetOrderAction);
// const binanceGetOrderWebSocket = createSaga(BINANCE_GET_ORDER_WEBSOCKET);
// const binanceNewOrder = createPromiseSaga(BINANCE_NEW_ORDER, coinAPI.binanceNewOrderAction);

export function* coinSaga() {
	yield takeLatest(GET_MARKETS, getUpbitMarkets);
	yield takeLatest(GET_SYMBOLS, getBinanceSymbols);
	yield takeLatest(GET_FUTURES_SYMBOLS, getBinanceFuturesSymbols);
	yield takeLatest(GET_COINM_SYMBOLS, getBinanceCoinmSymbols);
	yield takeLatest(UPBIT_WALLET, getUpbitWallet);
	yield takeLatest(BINANCE_WALLET, getBinanceWallet);
	yield takeLatest(BINANCE_FUTURES_WALLET, getBinanceFuturesWallet);
	yield takeLatest(BINANCE_COINM_WALLET, getBinanceCoinmWallet);


	// yield takeLatest(UPBIT_SETTING, upbitSetting);
	// yield takeLatest(BINANCE_SETTING, binanceSetting);
	// yield takeLatest(BINANCE_SET_LEVERAGE, binanceSetLeverage);
	// yield takeLatest(BINANCE_SET_MARGIN_TYPE, binanceSetMarginType);
	// yield takeLatest(BINANCE_SET_POSITION_SIDE, binanceSetPositionSide);
	// yield takeEvery(UPBIT_NEW_ORDER, upbitNewOrder);
	// yield takeLatest(SET_UPBIT_TICKER, setUpbitTicker);
	// yield takeLatest(GET_UPBIT_TICK, getUpbitTick);
	// yield takeLatest(UPBIT_GET_ORDER, upbitGetOrder);
	// yield takeLatest(SET_BINANCE_TICKER, setBinanceTicker);
	// yield takeLatest(GET_BINANCE_TICK, getBinanceTick);
	// yield takeLatest(BINANCE_WALLET_WEBSOCKET, binanceWalletWebSocket);
	// yield takeLatest(BINANCE_GET_ORDER, binanceGetOrder);
	// yield takeLatest(BINANCE_GET_ORDER_WEBSOCKET, binanceGetOrderWebSocket);
	// yield takeEvery(BINANCE_NEW_ORDER, binanceNewOrder);
}

// 4.Create state
const initState = {
	markets: reducerUtils.initial(),
	symbols: reducerUtils.initial(),
	futuresSymbols: reducerUtils.initial(),
	coinmSymbols: reducerUtils.initial(),
	upbitWallet: reducerUtils.initial(),
	binanceWallet: reducerUtils.initial(),
	binanceFuturesWallet: reducerUtils.initial(),
	binanceCoinmWallet: reducerUtils.initial(),


	// upbitSetting: reducerUtils.initial(),
	// upbitTicker: {},
	// upbitTick: reducerUtils.initial(),
	// upbitOrders: reducerUtils.initial(),
	// upbitLocalOrders: reducerUtils.initial(),
	// binanceSetting: reducerUtils.initial(),
	// binanceTicker: {},
	// binanceTick: reducerUtils.initial(),
	// binanceOrders: reducerUtils.initial(),
	// exchangeRate: 1000
};

// 5.Create Action handling
const coinReducer = (state = initState, action) => {
	switch (action.type) {
		case GET_MARKETS_SUCCESS:
		case GET_MARKETS_FAILURE:
			return handleActionsWhenUpdateOnly(GET_MARKETS, 'markets')(state, action);
		case GET_SYMBOLS_SUCCESS:
		case GET_SYMBOLS_FAILURE:
			return handleActionsWhenUpdateOnly(GET_SYMBOLS, 'symbols')(state, action);
		case GET_FUTURES_SYMBOLS_SUCCESS:
		case GET_FUTURES_SYMBOLS_FAILURE:
			return handleActionsWhenUpdateOnly(GET_FUTURES_SYMBOLS, 'futuresSymbols')(state, action);
		case GET_COINM_SYMBOLS_SUCCESS:
		case GET_COINM_SYMBOLS_FAILURE:
			return handleActionsWhenUpdateOnly(GET_COINM_SYMBOLS, 'coinmSymbols')(state, action);
		case UPBIT_WALLET_SUCCESS:
			message.success('업비트 지갑을 조회했습니다.');
			return handleActionsWhenUpdateOnly(UPBIT_WALLET, 'upbitWallet')(state, action);
		case UPBIT_WALLET_FAILURE:
			message.error('Failted to get Upbit wallet.');
			return handleActionsWhenUpdateOnly(UPBIT_WALLET, 'upbitWallet')(state, action);
		case BINANCE_WALLET_SUCCESS:
			message.success('바낸 현물 지갑을 조회했습니다.');
			return handleActionsWhenUpdateOnly(BINANCE_WALLET, 'binanceWallet')(state, action);
		case BINANCE_WALLET_FAILURE:
			message.error('Failted to get Binance wallet.');
			return handleActionsWhenUpdateOnly(BINANCE_WALLET, 'binanceWallet')(state, action);
		case BINANCE_FUTURES_WALLET_SUCCESS:
			message.success('바낸 선물 지갑을 조회했습니다.');
			return handleActionsWhenUpdateOnly(BINANCE_FUTURES_WALLET, 'binanceFuturesWallet')(state, action);
		case BINANCE_FUTURES_WALLET_FAILURE:
			message.error('Failted to get Binance futures wallet.');
			return handleActionsWhenUpdateOnly(BINANCE_FUTURES_WALLET, 'binanceFuturesWallet')(state, action);
		case BINANCE_COINM_WALLET_SUCCESS:
			message.success('바낸 코인엠 지갑을 조회했습니다.');
			return handleActionsWhenUpdateOnly(BINANCE_COINM_WALLET, 'binanceCoinmWallet')(state, action);
		case BINANCE_COINM_WALLET_FAILURE:
			message.error('Failted to get Binance coinm wallet.');
			return handleActionsWhenUpdateOnly(BINANCE_COINM_WALLET, 'binanceCoinmWallet')(state, action);


		// case UPBIT_SETTING_SUCCESS:
		// 	message.success('Success to Upbit setting.');
		// 	return handleDefaultActions(UPBIT_SETTING, 'upbitSetting', true)(state, action);
		// case UPBIT_SETTING_FAILURE:
		// 	message.error('Failted to Upbit setting.');
		// 	return handleDefaultActions(UPBIT_SETTING, 'upbitSetting', true)(state, action);
		// case BINANCE_SETTING_SUCCESS:
		// 	message.success('Success to Binance setting.');
		// 	return handleDefaultActions(BINANCE_SETTING, 'binanceSetting', true)(state, action);
		// case BINANCE_SETTING_FAILURE:
		// 	message.error('Failted to Binance setting.');
		// 	return handleDefaultActions(BINANCE_SETTING, 'binanceSetting', true)(state, action);
		// case BINANCE_SET_LEVERAGE_SUCCESS:
		// 	message.success('Success to Binance set leverage.');
		// 	return state;
		// case BINANCE_SET_LEVERAGE_FAILURE:
		// 	message.error('Failted to Binance set leverage.');
		// 	return state;
		// case BINANCE_SET_MARGIN_TYPE_SUCCESS:
		// 	message.success('Success to Binance set margin type.');
		// 	return state;
		// case BINANCE_SET_MARGIN_TYPE_FAILURE:
		// 	message.error('Failted to Binance set margin type.');
		// 	return state;
		// case BINANCE_SET_POSITION_SIDE_SUCCESS:
		// 	message.success('Success to Binance set position side.');
		// 	return state;
		// case BINANCE_SET_POSITION_SIDE_FAILURE:
		// 	message.error('Failted to Binance set position side.');
		// 	return state;
		// case UPBIT_NEW_ORDER_SUCCESS:
		// 	message.success('Success to Upbit order.');
		// 	return state;
		// case UPBIT_NEW_ORDER_FAILURE:
		// 	message.error('Failted to Upbit order.');
		// 	return state;
		// case SET_UPBIT_TICKER_SUCCESS:
		// 	return {
		// 		...state,
		// 		upbitTicker: {
		// 			...state.upbitTicker,
		// 			[action.meta]: action.payload
		// 		}
		// 	};
		// case GET_UPBIT_TICK_SUCCESS:
		// case GET_UPBIT_TICK_FAILURE:
		// 	return handleActionsWhenUpdateOnly(GET_UPBIT_TICK, 'upbitTick')(state, action);
		// case UPBIT_GET_ORDER_SUCCESS:
		// 	if (compareUnsorted(state.upbitOrders.data, action.payload)) {
		// 		return state;
		// 	} else {
		// 		return {
		// 			...state,
		// 			upbitOrders: {
		// 				...state.upbitOrders,
		// 				data: action.payload
		// 			},
		// 			upbitLocalOrders: {
		// 				...state.upbitLocalOrders,
		// 				data: action.meta ? action.payload : state.upbitLocalOrders.data || action.payload
		// 			}
		// 		};
		// 	}
		// case UPBIT_GET_ORDER_FAILURE:
		// 	return state;
		// case SET_BINANCE_TICKER_SUCCESS:
		// 	return {
		// 		...state,
		// 		binanceTicker: {
		// 			...state.binanceTicker,
		// 			[action.meta]: action.payload
		// 		}
		// 	};
		// case GET_BINANCE_TICK_SUCCESS:
		// case GET_BINANCE_TICK_FAILURE:
		// 	return handleActionsWhenUpdateOnly(GET_BINANCE_TICK, 'binanceTick')(state, action);
		// case BINANCE_WALLET_WEBSOCKET_SUCCESS:
		// 	return {
		// 		...state,
		// 		binanceWallet: {
		// 			...state.binanceWallet,
		// 			data: {
		// 				asset: action.payload.asset,
		// 				positions: {
		// 					...state.binanceWallet.data.positions,
		// 					[action.meta]: action.payload.position
		// 				}
		// 			}
		// 		}
		// 	};
		// case BINANCE_WALLET_WEBSOCKET_FAILURE:
		// 	return state;
		// case BINANCE_GET_ORDER_SUCCESS:
		// 	message.success('Success to Binance order.');
		// 	return handleActionsWhenUpdateOnly(BINANCE_GET_ORDER, 'binanceOrders')(state, action);
		// case BINANCE_GET_ORDER_FAILURE:
		// 	message.error('Failted to Binance order.');
		// 	return handleActionsWhenUpdateOnly(BINANCE_GET_ORDER, 'binanceOrders')(state, action);
		// case BINANCE_GET_ORDER_WEBSOCKET_SUCCESS:
		// 	if (action.payload.status === 'NEW' || action.payload.status === 'PARTIALLY_FILLED') {
		// 		return {
		// 			...state,
		// 			binanceOrders: {
		// 				...state.binanceOrders,
		// 				data: [...state.binanceOrders.data, action.payload]
		// 			}
		// 		};
		// 	} else {
		// 		return {
		// 			...state,
		// 			binanceOrders: {
		// 				...state.binanceOrders,
		// 				data: state.binanceOrders.data.filter((x) => x.price !== action.payload.price)
		// 			}
		// 		};
		// 	}
		// case BINANCE_GET_ORDER_WEBSOCKET_FAILURE:
		// 	return state;
		// case BINANCE_NEW_ORDER_SUCCESS:
		// case BINANCE_NEW_ORDER_FAILURE:
		// 	// return handleActionsWhenUpdateOnlyBySymbol(BINANCE_NEW_ORDER, 'binanceOrders')(state, action);
		// 	return state;
		// case GET_MARKETS:
		// case SET_UPBIT_TICKER_FAILURE:
		// case GET_UPBIT_TICK:
		// case UPBIT_WALLET:
		// case UPBIT_GET_ORDER:
		// case UPBIT_NEW_ORDER:
		// case UPBIT_SETTING:
		// case GET_SYMBOLS:
		// case SET_BINANCE_TICKER_FAILURE:
		// case BINANCE_WALLET:
		// case BINANCE_GET_ORDER:
		// case BINANCE_NEW_ORDER:
		// case BINANCE_SETTING:
		default:
			return state;
	}
};

export default coinReducer;
