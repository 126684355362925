import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Button, Row, Col, Space, Card, InputNumber, Radio, Select, Switch } from 'antd';
import { format } from 'd3-format';

import { getBinanceStep } from '../../hooks/useLibrary';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};
const tailLayout = {
	wrapperCol: { offset: 8, span: 16 }
};

export default ({ auth, symbols, binanceTicker, handleBinanceAddOrders, handleBinanceAddMarginTriger, triggerValues, handelBinanceCoinmTriggerValue }) => {
	const [symbol, setSymbol] = useState({});
	const [orderButtonStyle, setOrderButtonStype] = useState({ background: '#cf304a', borderColor: '#cf304a' });

	const [orderForm] = Form.useForm();
	const [marginForm] = Form.useForm();

	useEffect(() => {
		orderForm.resetFields();
		setOrderButtonStype({ background: '#cf304a', borderColor: '#cf304a' });
		marginForm.resetFields();
		if (triggerValues) {
			marginForm.setFieldsValue({
				positionSide: triggerValues.positionSide,
				budget: triggerValues.budget,
				targetRoe: triggerValues.targetRoe,
				autoReposition: triggerValues.autoReposition,
				stopReposition: triggerValues.stopReposition,
				version: triggerValues.version || false
			});
		}
	}, [symbol]);

	useEffect(() => {
		if (triggerValues) {
			handleSymbol(triggerValues.symbol, false);
		}
	}, [triggerValues]);

	const handleSymbol = (value, triggerFlag) => {
		if (triggerFlag) {
			handelBinanceCoinmTriggerValue(null);
		}
		const { priceStep, pricePrecision, quantityStep, quantityPrecision } = getBinanceStep(symbols.find((x) => x.symbol === value));
		setSymbol({ s: value, c: binanceTicker[value.replace('USD_PERP', 'USDT')], priceStep, pricePrecision, quantityStep, quantityPrecision });
	};

	const handleOrderSide = (value) => {
		if (value === 'SELL') {
			setOrderButtonStype({ background: '#cf304a', borderColor: '#cf304a' });
		} else {
			setOrderButtonStype({ background: '#03a66d', borderColor: '#03a66d' });
		}
	};

	const handelOrderForm = (value, values) => {
		if (symbol.s) {
			let resourceUSD = 0;
			for (let i = 0; i < values.orderCount; i++) {
				resourceUSD += (values.tradeStartPrice - values.tradeStep * i) * Number(values.tradeQuantity);
			}
			orderForm.setFieldsValue({ tradeEndPrice: `$${Number(Number(values.tradeStartPrice + (values.orderCount - 1) * values.tradeStep * (values.side === 'SELL' ? 1 : -1)).toFixed(symbol.pricePrecision))}` });
			orderForm.setFieldsValue({ resource: values.side === 'SELL' ? `${Number(values.tradeQuantity) * values.orderCount} ${symbol.s ? symbol.s.replace('USD_PERP', '') : '코인'}` : format(`$,.${symbol.pricePrecision}f`)(resourceUSD) });
		}
	};

	return (
		<>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col span={24}>
					<Form {...layout} name="binance_setting" initialValues={{ symbol: symbol.s }}>
						<Form.Item name="symbol" label="코인">
							<Select showSearch style={{ width: 200 }} placeholder="코인을 선택해주세요." optionFilterProp="children" value={symbol.s} onChange={(e) => handleSymbol(e, true)} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{useMemo(
									() =>
										symbols &&
										symbols.map((item, i) => (
											<Select.Option key={item.symbol} value={item.symbol}>
												{item.symbol.replace('USD_PERP', '')}
											</Select.Option>
										)),
									[symbols]
								)}
							</Select>
						</Form.Item>
					</Form>
				</Col>
			</Row>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row" xs={24} xl={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Card title="일괄 주문" size="small" style={{ width: '100%', backgroundColor: '#efdbff' }}>
							<Form {...layout} form={orderForm} initialValues={{ symbol: symbol.s, positionSide: 'LONG', side: 'BUY', tradeQuantity: 1, orderCount: 1, tradeStartPrice: (symbol && Number(symbol.c)) || 0, tradeStep: (symbol && symbol.quantityStep) || 1 }} onValuesChange={(e, allValues) => handelOrderForm(e, allValues)} onFinish={(e) => handleBinanceAddOrders({ ...e, symbol })}>
								<Form.Item name="symbol" label="코인" rules={[{ required: true, message: '코인을 선택하세요!' }]}>
									<Input disabled />
								</Form.Item>
								<Form.Item label="포지션 구분" name="positionSide">
									<Radio.Group buttonStyle="solid">
										<Radio.Button value="LONG">롱</Radio.Button>
										<Radio.Button value="SHORT">숏</Radio.Button>
									</Radio.Group>
								</Form.Item>
								<Form.Item label="주문 구분" name="side" onChange={(e) => handleOrderSide(e.target.value)}>
									<Radio.Group buttonStyle="solid">
										<Radio.Button value="SELL">매도</Radio.Button>
										<Radio.Button value="BUY">매수</Radio.Button>
									</Radio.Group>
								</Form.Item>
								<Form.Item
									name="tradeQuantity"
									label="주문당 계약($10)수"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '주문당 계약수를 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix={'Contract'} />
								</Form.Item>
								<Form.Item name="tradeStartPrice" label="시작가격">
									<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item name="tradeEndPrice" label="종료가격">
									<Input style={{ width: 150 }} disabled />
								</Form.Item>
								<Form.Item name="tradeStep" label="주문간 가격차">
									<InputNumber style={{ width: 150 }} min={0} max={100000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item name="orderCount" label="주문수">
									<InputNumber min={1} max={500} step={1} />
								</Form.Item>
								<Form.Item name="resource" label="비용">
									<Input style={{ width: 150 }} disabled />
								</Form.Item>
								<Form.Item {...tailLayout}>
									<Button type="primary" htmlType="submit" style={orderButtonStyle}>
										주문
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Space>
				</Col>
				<Col className="gutter-row" xs={24} xl={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Card title="조건식 추가" size="small" style={{ width: '100%', backgroundColor: '#efdbff' }}>
							<Form {...layout} form={marginForm} initialValues={{ symbol: symbol.s, positionSide: 'LONG', budget: 1000, targetRoe: 0.01, stopReposition: 0, autoReposition: true, version: false }} onFinish={(e) => handleBinanceAddMarginTriger({ ...e, symbol, type: 'simple' })}>
								<Form.Item name="symbol" label="코인" rules={[{ required: true, message: '코인을 선택하세요!' }]}>
									<Input disabled />
								</Form.Item>
								<Form.Item label="포지션 구분" name="positionSide">
									<Radio.Group buttonStyle="solid">
										<Radio.Button value="LONG">롱</Radio.Button>
										<Radio.Button value="SHORT">숏</Radio.Button>
									</Radio.Group>
								</Form.Item>
								<Form.Item
									label="예산"
									name="budget"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Number(value) < 500 ? Promise.reject(new Error('예산은 $500 이상 입력해주세요.')) : Promise.resolve())
										},
										{
											required: true,
											message: '예산을 입력해주세요.'
										}
									]}
								>
									<Input style={{ width: 150 }} suffix="USDT" />
								</Form.Item>
								<Form.Item
									label="목표 수익률"
									name="targetRoe"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '목표 수익률을 입력하세요.'
										}
									]}
								>
									<InputNumber style={{ width: 100 }} min={0} max={100} step={0.001} formatter={(value) => format(',.2%')(value)} parser={(value) => Number(value.replace('%', '')) / 100} />
								</Form.Item>
								<Form.Item
									name="stopReposition"
									label="재진입 제한선"
									rules={[
										{
											validator: (_, value) => (isNaN(value) ? Promise.reject(new Error('유효한 숫자가 아닙니다!')) : Promise.resolve())
										},
										{
											required: true,
											message: '재진입 제한선을 입력해주세요.'
										}
									]}
								>
									<InputNumber style={{ width: 150 }} min={0} max={100000000} step={(symbol && symbol.priceStep) || 0} formatter={(value) => '$' + value} parser={(value) => value.replace('$', '')} />
								</Form.Item>
								<Form.Item name="autoReposition" label="재진입" valuePropName="checked">
									<Switch checkedChildren="자동" unCheckedChildren="수동" />
								</Form.Item>
								{auth.attributes['custom:version'] !== 'v2' && (
									<Form.Item name="version" label="버전" valuePropName="checked">
										<Switch checkedChildren="V3" unCheckedChildren="V2" />
									</Form.Item>
								)}
								<Form.Item {...tailLayout}>
									<Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
										추가(변경)
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Space>
				</Col>
			</Row>
		</>
	);
};
