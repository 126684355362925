import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import { useCookies } from 'react-cookie';
import { Auth } from 'aws-amplify';

import { AuthForm } from '../styles/auth';
import SignIn from '../components/auth/signIn';
import SignUp from '../components/auth/signUp';
import ConfirmSignUp from '../components/auth/confirmSignUp';
import ForgotPassword from '../components/auth/forgotPassword';
import NewPassword from '../components/auth/newPassword';
import { signInAction, signUpAction, confirmSignUpAction, forgotPasswordAction, newPasswordAction } from '../modules/auth';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};

function AuthContainer() {
	const { auth } = useSelector(({ authReducer }) => authReducer);
	const dispatch = useDispatch();
	const [cookies, setCookie] = useCookies(['username']);
	const [authType, setAuthType] = useState('signIn');
	const [username, setUsername] = useState('');

	useEffect(() => {
		if (auth.error && auth.error.message) {
			if (auth.error.code === 'UserNotConfirmedException')  {
				setAuthType('confirmSignUp');
			} else {
				message.error(auth.error.message);
			}
		} else if (auth.data && auth.data.user && auth.data.user.authenticationFlowType === 'USER_SRP_AUTH') {
			setUsername(auth.data.user.username);
			setAuthType('confirmSignUp');
		} else if (auth.data === 'SUCCESS') {
			setAuthType('signIn');
		} else if (auth.data && auth.data.CodeDeliveryDetails) {
			message.success('인증코드가 전송되었습니다.');
			setAuthType('newPassword');
		} else if (!auth.data) {
			setAuthType('signIn');
		}
	}, [auth]);

	const handleSignIn = (values) => {
		if (values.remember) setCookie('username', values.username, {});
		else setCookie('username', '', {});
		setUsername(values.username);
		dispatch(signInAction(values));
	};

	const handleSignUp = (values) => {
		dispatch(signUpAction(values));
	};

	const handleConfirmSignUp = (values) => {
		dispatch(confirmSignUpAction(values));
	};

	const handleResendCode = (values) => {
		Auth.resendSignUp(values.username);
		message.success('인증코드가 전송되었습니다.');
	};

	const handleForgotPassword = (values) => {
		setUsername(values.username);
		dispatch(forgotPasswordAction(values));
	};

	const handleNewPassword = (values) => {
		dispatch(newPasswordAction(values));
	};

	return (
		<>
			<AuthForm>
				{authType === 'signIn' && (
					<>
						<SignIn onSignIn={handleSignIn} onToggleSignInUp={setAuthType} username={cookies.username} />
					</>
				)}
				{authType === 'signUp' && (
					<>
						<SignUp onSignUp={handleSignUp} onToggleSignInUp={setAuthType} />
					</>
				)}
				{authType === 'confirmSignUp' && (
					<>
						<ConfirmSignUp onConfirmSignUp={handleConfirmSignUp} onResendCode={handleResendCode} username={username} />
					</>
				)}
				{authType === 'newPassword' && (
					<>
						<NewPassword onNewPassword={handleNewPassword} username={username} />
					</>
				)}
				{authType === 'forgotPassword' && (
					<>
						<ForgotPassword onForgotPassword={handleForgotPassword} onToggleSignInUp={setAuthType} username={cookies.username} />
					</>
				)}
			</AuthForm>
		</>
	);
}

export default AuthContainer;
