import React from 'react';
import { Descriptions, Space, Card, Switch, Button } from 'antd';
import { format } from 'd3-format';

export default ({ auth, triggers, handelTrigger, handelDeleteTrigger }) => {
	return (
		<>
			<Space direction="vertical" style={{ width: '100%', backgroundColor: '#eaff8f' }}>
				{triggers
					.filter((x) => x.activate)
					.map((item, key) => (
						<Card
							key={`${item.symbol}`}
							title={item.name}
							size="small"
							style={{ width: '100%' }}
							extra={
								<>
									<Button style={{ marginRight: '8px' }} onClick={() => handelDeleteTrigger(item.symbol, item.tradeQuantity)}>
										삭제
									</Button>
									<Switch checked={item.activate} checkedChildren="ON" unCheckedChildren="OFF" onChange={(e) => handelTrigger({ symbol: item.symbol, tradeQuantity: item.tradeQuantity, activate: e })} />
								</>
							}
						>
							<Descriptions bordered size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
								<Descriptions.Item label={`${item.buyQuantity && item.tradeQuantity !== item.buyQuantity ? '매도' : '매매'} 수량`} labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#cf304a' }} span={item.buyQuantity && item.tradeQuantity !== item.buyQuantity ? 1 : 2}>
									{`${format(`,.${item.quantityPrecision}f`)(item.tradeQuantity)}`}
								</Descriptions.Item>
								{item.buyQuantity && item.tradeQuantity !== item.buyQuantity && (
									<Descriptions.Item label="매수 수량" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#03a66d' }}>
										{`${format(`,.${item.quantityPrecision}f`)(item.buyQuantity)}`}
									</Descriptions.Item>
								)}
								<Descriptions.Item label="증가폭" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#cf304a' }}>
									{`${format(`$,.${item.pricePrecision}f`)(item.sellStep)}`}
								</Descriptions.Item>
								<Descriptions.Item label="감소폭" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#03a66d' }}>
									{`${format(`$,.${item.pricePrecision}f`)(item.buyStep)}`}
								</Descriptions.Item>
								{(item.type === 'margin' || item.type === 'one way') && (item.stopBuy || item.stopSell) && (
									<>
										<Descriptions.Item label="매수 상한선" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#03a66d' }}>
											{`${format(`$,.${item.pricePrecision}f`)(item.stopBuy || 0)}`}
										</Descriptions.Item>
										<Descriptions.Item label="매도 하한선" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#cf304a' }}>
											{`${format(`$,.${item.pricePrecision}f`)(item.stopSell || 0)}`}
										</Descriptions.Item>
									</>
								)}
							</Descriptions>
						</Card>
					))}
			</Space>
			<Space direction="vertical" style={{ width: '100%', backgroundColor: '#eaff8f' }}>
				{triggers
					.filter((x) => !x.activate)
					.map((item, key) => (
						<Card
							key={`${item.symbol}`}
							title={item.name}
							size="small"
							style={{ width: '100%' }}
							extra={
								<>
									<Button style={{ marginRight: '8px' }} onClick={() => handelDeleteTrigger(item.symbol, item.tradeQuantity)}>
										삭제
									</Button>
									<Switch checked={item.activate} checkedChildren="ON" unCheckedChildren="OFF" onChange={(e) => handelTrigger({ symbol: item.symbol, tradeQuantity: item.tradeQuantity, activate: e })} />
								</>
							}
						>
							<Descriptions bordered size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
								<Descriptions.Item label={`${item.buyQuantity && item.tradeQuantity !== item.buyQuantity ? '매도' : '매매'} 수량`} labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#cf304a' }} span={item.buyQuantity && item.tradeQuantity !== item.buyQuantity ? 1 : 2}>
									{`${format(`,.${item.quantityPrecision}f`)(item.tradeQuantity)}`}
								</Descriptions.Item>
								{item.buyQuantity && item.tradeQuantity !== item.buyQuantity && (
									<Descriptions.Item label="매수 수량" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#03a66d' }}>
										{`${format(`,.${item.quantityPrecision}f`)(item.buyQuantity)}`}
									</Descriptions.Item>
								)}
								<Descriptions.Item label="증가폭" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#cf304a' }}>
									{`${format(`$,.${item.pricePrecision}f`)(item.sellStep)}`}
								</Descriptions.Item>
								<Descriptions.Item label="감소폭" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#03a66d' }}>
									{`${format(`$,.${item.pricePrecision}f`)(item.buyStep)}`}
								</Descriptions.Item>
								{(item.type === 'margin' || item.type === 'one way') && (item.stopBuy || item.stopSell) && (
									<>
										<Descriptions.Item label="매수 상한선" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#03a66d' }}>
											{`${format(`$,.${item.pricePrecision}f`)(item.stopBuy || 0)}`}
										</Descriptions.Item>
										<Descriptions.Item label="매도 하한선" labelStyle={{ padding: '2px 16px' }} contentStyle={{ padding: '2px 16px', textAlign: 'right', color: '#cf304a' }}>
											{`${format(`$,.${item.pricePrecision}f`)(item.stopSell || 0)}`}
										</Descriptions.Item>
									</>
								)}
							</Descriptions>
						</Card>
					))}
			</Space>
		</>
	);
};
