import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import authReducer, { authSaga } from './auth';
import coinReducer, { coinSaga } from './coin';

const rootReducer = combineReducers({
	authReducer,
	coinReducer
});

export function* rootSaga() {
	yield all([authSaga(), coinSaga()]);
}

export default rootReducer;
