import { call, put } from 'redux-saga/effects';
import { compare, compareUnsorted } from 'js-deep-equals';

export const createRequestActionType = (type) => {
	return [type, `${type}_SUCCESS`, `${type}_FAILURE`];
};

export const createSaga = (type) => {
	const [SUCCESS, FAILURE] = [`${type}_SUCCESS`, `${type}_FAILURE`];
	return function* (action) {
		try {
			yield put({ type: SUCCESS, payload: action.payload, meta: action.meta });
		} catch (err) {
			yield put({ type: FAILURE, error: true, payload: err, meta: action.meta });
		}
	};
};

export const createPromiseSaga = (type, promiseCreator) => {
	const [SUCCESS, FAILURE] = [`${type}_SUCCESS`, `${type}_FAILURE`];
	return function* (action) {
		try {
			const payload = yield call(promiseCreator, action.payload);
			yield put({ type: SUCCESS, payload, meta: action.meta });
		} catch (err) {
			yield put({ type: FAILURE, error: true, payload: err, meta: action.meta });
		}
	};
};

// export const createPromiseSagaById = (type, promiseCreator) => {
// 	const [SUCCESS, FAILURE] = [`${type}_SUCCESS`, `${type}_FAILURE`];
// 	return function* (action) {
// 		const id = action.meta;
// 		try {
// 			const payload = yield call(promiseCreator, action.payload);
// 			yield put({ type: SUCCESS, payload, meta: id });
// 		} catch (err) {
// 			yield put({ type: FAILURE, error: err, meta: id });
// 		}
// 	};
// };

export const reducerUtils = {
	initial: (initialData = null) => ({
		loading: false,
		data: initialData,
		error: null
	}),
	loading: (prevState = null) => ({
		loading: true,
		data: prevState,
		error: null
	}),
	success: (payload) => ({
		loading: false,
		data: payload,
		error: null
	}),
	error: (error) => ({
		loading: false,
		data: null,
		error: error
	})
};

export const handleDefaultActions = (type, key, keepData = false) => {
	const [SUCCESS, FAILURE] = [`${type}_SUCCESS`, `${type}_FAILURE`];
	return (state, action) => {
		switch (action.type) {
			case type:
				return {
					...state,
					[key]: reducerUtils.loading(keepData ? state[key].data : null)
				};
			case SUCCESS:
				return {
					...state,
					[key]: reducerUtils.success(action.payload)
				};
			case FAILURE:
				return {
					...state,
					[key]: reducerUtils.error(action.payload)
				};
			default:
				return state;
		}
	};
};

export const handleActionsWhenUpdateOnly = (type, key) => {
	const [SUCCESS, FAILURE] = [`${type}_SUCCESS`, `${type}_FAILURE`];
	return (state, action) => {
		switch (action.type) {
			case SUCCESS:
				if (compareUnsorted(state[key].data, action.payload)) {
					return state;
				} else {
					return {
						...state,
						[key]: reducerUtils.success(action.payload)
					};
				}
			case FAILURE:
				return {
					...state,
					[key]: reducerUtils.error(action.payload)
				};
			default:
				return state;
		}
	};
};

export const handleActionsWhenUpdateOnlyBySymbol = (type, key, keepData = false) => {
	const [SUCCESS, FAILURE] = [`${type}_SUCCESS`, `${type}_FAILURE`];
	return (state, action) => {
		switch (action.type) {
			case SUCCESS:
				if (state[key].data) {
					if (compareUnsorted(state[key].data[action.meta], action.payload)) {
						return state;
					} else {
						return {
							...state,
							[key]: {
								...state[key],
								data: {
									...state[key].data,
									[action.meta]: action.payload
								}
							}
						};
					}
				} else {
					return {
						...state,
						[key]: {
							...state[key],
							data: {
								[action.meta]: action.payload
							}
						}
					};
				}
			case FAILURE:
				return {
					...state,
					[key]: {
						...state[key],
						data: {
							...state[key].data,
							[action.meta]: null
						}
					}
				};
			default:
				return state;
		}
	};
};
